import React, { useEffect, useRef, useState, useReducer } from "react";
import { ChannelService, ChannelSubscriptionService } from "@getvia/sdk";

const viaConfig = {
  channelServiceOptions: {
    apiToken: "test",
    baseUrl: process.env.REACT_APP_VIA_CORE_API,
    wsUrl: process.env.REACT_APP_VIA_NOTIFICATION_HUB
  },
};
const defaultGasBoxConfiguration = [
    {
      object: "MFC",
      name: "MFC1",
      readChannelName: "MAIN.MFC1.In.Flow_Reading",
      writeChannelName: "MAIN.MFC1.Out.Flow_SP",
      feedBackChannel: "MAIN.MFC1.In.Flow_Reading",
      currentValue: 0,
      setValue:0,
    },
    {
      object: "MFC",
      name: "MFC1out",
      feedBackChannel: "MAIN.MFC1.Out.Flow_SP",
      currentValue: 0,
    },
    {
      object: "MFC",
      name: "MFC2",
      readChannelName: "MAIN.MFC2.In.Flow_Reading",
      writeChannelName: "MAIN.MFC2.Out.Flow_SP",
      feedBackChannel: "MAIN.MFC2.In.Flow_Reading",
      currentValue: 0,
      setValue:0,
    },
    {
      object: "MFC",
      name: "MFC2out",
      feedBackChannel: "MAIN.MFC2.Out.Flow_SP",
      currentValue: 0,
    },
    {
      object: "MFC",
      name: "MFC3",
      readChannelName: "MAIN.MFC3.In.Flow_Reading",
      writeChannelName: "MAIN.MFC3.Out.Flow_SP",
      feedBackChannel: "MAIN.MFC3.In.Flow_Reading",
      currentValue: 0,
      setValue:0,
    },
    {
      object: "MFC",
      name: "MFC3out",
      feedBackChannel: "MAIN.MFC3.Out.Flow_SP",
      currentValue: 0,
    },
    {
      object: "MFC",
      name: "MFC4",
      readChannelName: "MAIN.MFC4.In.Flow_Reading",
      writeChannelName: "MAIN.MFC4.Out.Flow_SP",
      feedBackChannel: "MAIN.MFC4.In.Flow_Reading",
      currentValue: 0,
      setValue:0,
    },
    {
      object: "MFC",
      name: "MFC4out",
      feedBackChannel: "MAIN.MFC4.Out.Flow_SP",
      currentValue: 0,
    },
    {
      object: "MFC",
      name: "MFC5",
      readChannelName: "MAIN.MFC5.In.Flow_Reading",
      writeChannelName: "MAIN.MFC5.Out.Flow_SP",
      feedBackChannel: "MAIN.MFC5.In.Flow_Reading",
      currentValue: 0,
      setValue:0,
    },
    {
      object: "MFC",
      name: "MFC5out",
      feedBackChannel: "MAIN.MFC5.Out.Flow_SP",
      currentValue: 0,
    },
    {
      object: "MFC",
      name: "MFC6",
      readChannelName: "MAIN.MFC6.In.Flow_Reading",
      writeChannelName: "MAIN.MFC6.Out.Flow_SP",
      feedBackChannel: "MAIN.MFC6.In.Flow_Reading",
      currentValue: 0,
      setValue:0,
    },
    {
      object: "MFC",
      name: "MFC6out",
      feedBackChannel: "MAIN.MFC6.Out.Flow_SP",
      currentValue: 0,
    },
    {
      object: "DownValve",
      name: "DV1",
      readChannelName: "MAIN.MFC1_Downstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC1_Downstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC1_Downstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "DownValve",
      name: "DV2",
      readChannelName: "MAIN.MFC2_Downstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC2_Downstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC2_Downstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "DownValve",
      name: "DV3",
      readChannelName: "MAIN.MFC3_Downstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC3_Downstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC3_Downstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "DownValve",
      name: "DV4",
      readChannelName: "MAIN.MFC4_Downstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC4_Downstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC4_Downstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "DownValve",
      name: "DV5",
      readChannelName: "MAIN.MFC5_Downstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC5_Downstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC5_Downstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "DownValve",
      name: "DV6",
      readChannelName: "MAIN.MFC6_Downstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC6_Downstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC6_Downstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "UpValve",
      name: "UV1",
      readChannelName: "MAIN.MFC1_Upstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC1_Upstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC1_Upstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "UpValve",
      name: "UV2",
      readChannelName: "MAIN.MFC2_Upstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC2_Upstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC2_Upstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "UpValve",
      name: "UV3",
      readChannelName: "MAIN.MFC3_Upstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC3_Upstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC3_Upstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "UpValve",
      name: "UV4",
      readChannelName: "MAIN.MFC4_Upstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC4_Upstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC4_Upstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "UpValve",
      name: "UV5",
      readChannelName: "MAIN.MFC5_Upstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC5_Upstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC5_Upstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "UpValve",
      name: "UV6",
      readChannelName: "MAIN.MFC6_Upstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC6_Upstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC6_Upstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "Alarm",
      name: "Alarm1",
      feedBackChannel: "MAIN.Foreline_Pmp.In.Alarm_fbk",
      currentValue: false,
    },
    {
      object: "Alarm",
      name: "Alarm2",
      feedBackChannel: "MFC1_SP_Greaterthan_FullScale",
      currentValue: false,
    },
    {
      object: "Alarm",
      name: "Alarm3",
      feedBackChannel: "MFC2_SP_Greaterthan_FullScale",
      currentValue: false,
    },
    {
      object: "Alarm",
      name: "Alarm4",
      feedBackChannel: "MFC3_SP_Greaterthan_FullScale",
      currentValue: false,
    },
    {
      object: "Alarm",
      name: "Alarm5",
      feedBackChannel: "MFC4_SP_Greaterthan_FullScale",
      currentValue: false,
    },
    {
      object: "Alarm",
      name: "Alarm",
      feedBackChannel: "Global",
      currentValue: false,
    },
    {
      object: "MFC_SEQ_Cmd",
      name: "MFC_SEQ_Cmd",
      feedBackChannel: "MFC_SEQ_Cmd",
      currentValue: null,
    },
    {
      object: "MFC_SEQ_Name",
      name: "MFC_SEQ_Name",
      feedBackChannel: "MFC_SEQ_Name",
      currentValue: null,
    }
  ];
  const channelService = new ChannelService(viaConfig.channelServiceOptions);
  const channelSubscriptionService = new ChannelSubscriptionService(viaConfig.channelServiceOptions);
  const AlarmReducer = (state, action) => {
    switch (action.type) {
      case "ON":
        return {
          ...state,
          value: true,
          channels: action.channels
        };
      case "OFF":
        return {
          ...state,
          value: false
        };
      default:
        return state;
    }
  };
  
export default function IsotopicGasbox() {
    const gasBoxConfiguration = useRef(defaultGasBoxConfiguration);
    const channels = useRef();
    const subscriptionRef = useRef();
    const [gasBoxState, setGasBoxState] = useState();
    const [alarm, dispatch] = useReducer(AlarmReducer, { value: false, AlarmChannels: [] });

    const [selectedValve, setselectedValve] = useState(null);
    const [Valvetitle, setValvetitle] = useState(null);
    const [selectedMfc, setselectedMfc] = useState(null);

    const [flag,setFlag] =useState(0);
    const [mfc1,setMfc1] =useState(null);
    const [mfc2,setMfc2] =useState(null);
    const [mfc3,setMfc3] =useState(null);
    const [mfc4,setMfc4] =useState(null);
    const [mfc5,setMfc5] =useState(null);
    const [mfc6,setMfc6] =useState(null);


    const [mfcvalue, setmfcvalue] = useState(null);
    const fetchChannelsStates = async () => {
        const response = await channelService.ChannelStates();
        console.log("ChannelStates: ", { response });
        if (response) {
            channels.current = response;
            updateGasBoxState(channels.current);
        }
        };
    const updateGasBoxState = (updatedChannels) => {
        let index = -1
        let gasBoxConfigurationState = gasBoxConfiguration.current;
        updatedChannels.forEach((item) => {
            const config = gasBoxConfigurationState.find((obj, objIndex) => {
            index = objIndex;
            return obj.feedBackChannel === item.channelName
            })
            if (config) {
            config.currentValue = item.data.value;
            gasBoxConfigurationState[index] = config;
            }
        })
        // setAlarm();
        setGasBoxState(() => gasBoxConfigurationState);
        if(flag==0){
          setMfc1(gasBoxConfigurationState?.find(o => o.name === "MFC1out").currentValue)
          setMfc2(gasBoxConfigurationState?.find(o => o.name === "MFC2out").currentValue)
          setMfc3(gasBoxConfigurationState?.find(o => o.name === "MFC3out").currentValue)
          setMfc4(gasBoxConfigurationState?.find(o => o.name === "MFC4out").currentValue)
          setMfc5(gasBoxConfigurationState?.find(o => o.name === "MFC5out").currentValue)
          setMfc6(gasBoxConfigurationState?.find(o => o.name === "MFC6out").currentValue)
        }
        setFlag(1)
    }
    const subscribeToNotification = () => {

        const onNewMessage = (message) => {
            const channel = JSON.parse(message);
            updateGasBoxState([channel])
        };

        let channelSubscriptions = [];

        gasBoxConfiguration.current.forEach(config => {
            channelSubscriptions.push(config.feedBackChannel);
        })
        const subscription = {
            channels: channelSubscriptions,
            onNewMessage: onNewMessage,
        };

        subscriptionRef.current = subscription;
        channelSubscriptionService.subscribe(subscription);
    };
    const unSubscribe = () => {
        channelSubscriptionService.unSubscribe(subscriptionRef.current);
    };
    useEffect(() => {
        const fetChannelData = async () => {
            await fetchChannelsStates();
            subscribeToNotification();
        };
        fetChannelData();
        return () => {
            unSubscribe();
        };
    }, []);

    const IOWrite = (channelName, value) => {
        console.log("Channels: ", { channels });
        console.log("IOWrite: ", { channelName, value });
        if (channelName) {
          const channel = channels.current?.find(obj => {
            return obj.channelName === channelName
          })
          if (channel) {
            channel.data.value = value;
            channelService.Write(channel).then(() => {
            });
          }
        }
      }
    const WriteToMFC = (config, value) => {
        console.log("WriteToMFC: ", { config, value });
        const writeChannelName = config.writeChannelName;
        IOWrite(writeChannelName, value);
      }
    
      const WriteToValve = (config, value) => {
        console.log("WriteToMFC: ", { config, value });
        const writeChannelName = config.writeChannelName;
        IOWrite(writeChannelName, value);
    }

    const handleMfcvalue = () => {
      const config = gasBoxConfiguration.current.find((obj) => {
        return obj.name === selectedMfc
      })
      if(selectedMfc=="MFC1") { setMfc1(mfcvalue); 
        if(mfcvalue>0) { IOWrite('MAIN.MFC1_Downstream.Out.On_Off_cmd', true); IOWrite('MAIN.MFC1_Upstream.Out.On_Off_cmd', true);
        } else { IOWrite('MAIN.MFC1_Downstream.Out.On_Off_cmd', false); IOWrite('MAIN.MFC1_Upstream.Out.On_Off_cmd', false);}
      } else
      if(selectedMfc=="MFC2") { setMfc2(mfcvalue) ; 
        if(mfcvalue>0) { IOWrite('MAIN.MFC2_Downstream.Out.On_Off_cmd', true); IOWrite('MAIN.MFC2_Upstream.Out.On_Off_cmd', true);          
        } else {IOWrite('MAIN.MFC2_Downstream.Out.On_Off_cmd', false); IOWrite('MAIN.MFC2_Upstream.Out.On_Off_cmd', false);  }
      } else
      if(selectedMfc=="MFC3") { setMfc3(mfcvalue); 
        if(mfcvalue>0) { IOWrite('MAIN.MFC3_Downstream.Out.On_Off_cmd', true); IOWrite('MAIN.MFC3_Upstream.Out.On_Off_cmd', true);          
        } else {IOWrite('MAIN.MFC3_Downstream.Out.On_Off_cmd', false); IOWrite('MAIN.MFC3_Upstream.Out.On_Off_cmd', false);  }
      } else
      if(selectedMfc=="MFC4") { setMfc4(mfcvalue) ; 
        if(mfcvalue>0) { IOWrite('MAIN.MFC4_Downstream.Out.On_Off_cmd', true); IOWrite('MAIN.MFC4_Upstream.Out.On_Off_cmd', true);          
        } else { IOWrite('MAIN.MFC4_Downstream.Out.On_Off_cmd', false); IOWrite('MAIN.MFC4_Upstream.Out.On_Off_cmd', false);}
      } else
      if(selectedMfc=="MFC5") { setMfc5(mfcvalue); 
        if(mfcvalue>0) { IOWrite('MAIN.MFC5_Downstream.Out.On_Off_cmd', true); IOWrite('MAIN.MFC5_Upstream.Out.On_Off_cmd', true);          
        } else { IOWrite('MAIN.MFC5_Downstream.Out.On_Off_cmd', false); IOWrite('MAIN.MFC5_Upstream.Out.On_Off_cmd', false);}
      } else
      if(selectedMfc=="MFC6") { setMfc6(mfcvalue) ; 
        if(mfcvalue>0) { IOWrite('MAIN.MFC6_Downstream.Out.On_Off_cmd', true); IOWrite('MAIN.MFC6_Upstream.Out.On_Off_cmd', true);          
        } else { IOWrite('MAIN.MFC6_Downstream.Out.On_Off_cmd', false); IOWrite('MAIN.MFC6_Upstream.Out.On_Off_cmd', false);}
      } 
      WriteToMFC(config, mfcvalue);
      // updateMfcWrittenState();
      setselectedMfc(null);
      setmfcvalue(null)
    }

    const handleClick = (value) => {
        debugger;
        const config = gasBoxConfiguration.current.find((obj) => {
          return obj.name === selectedValve
        });
        WriteToValve(config, value)
        setselectedValve(null)
      };
  return (<>
    
      <div className="relative">
        <div className="relative rounded-t-xl border-b-0 border-2 border-gray-400 mt-1">
          <div className="-mt-3 ml-2 mb-3 bg-gray-100 w-40 rounded-md border-gray-400 border text-center font-bold text-sm text-gray-600 py-1">GAS Controller</div>
          <div className="flex flex-col gap-2 mt-2 -ml-0.5">
            <div className="flex">
                <div className="rounded-r-lg border-2 border-gray-400 w-16 text-center font-semibold bg-gray-100 h-7 mt-0.5 text-sm">SF6</div>
                <div className="shadow-inner h-2 w-3 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("DV1"); setValvetitle('SF6 Down Valve 1') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${gasBoxState?.find(o => o.name === "DV1").currentValue ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input type="" class="h-6 w-12 text-center py-4 px-1 pr-0 rounded dysing-input-yellow cursor-pointer" value={mfc1} onClick={() => { setselectedMfc("MFC1"); setmfcvalue(gasBoxState?.find(o => o.name === "MFC1out").currentValue) }}/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input class="h-6 w-12 text-center py-4 dysing-input   rounded" value={gasBoxState?.find(o => o.name === "MFC1").currentValue} readonly="true"/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer"
                    onClick={() => { setselectedValve("UV1"); setValvetitle('SF6 Up Valve 1') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${gasBoxState?.find(o => o.name === "UV1").currentValue ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
            </div>
            
            <div className="flex">
                <div className="rounded-r-lg border-2 border-gray-400 w-16 text-center font-semibold bg-gray-100 h-7 mt-0.5 text-sm">CF4</div>
                <div className="shadow-inner h-2 w-3 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("DV2"); setValvetitle('CF4 Down Valve 2') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${gasBoxState?.find(o => o.name === "DV2").currentValue ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input type="" class="h-6 w-12 text-center py-4 px-1 pr-0  dysing-input-yellow rounded cursor-pointer" value={mfc2} onClick={() => { setselectedMfc("MFC2"); setmfcvalue(gasBoxState?.find(o => o.name === "MFC2out").currentValue) }}/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input class="h-6 w-12 text-center py-4 dysing-input rounded" value={gasBoxState?.find(o => o.name === "MFC2").currentValue} readonly="true"/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("UV2"); setValvetitle('CF4 Up Valve 2') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${gasBoxState?.find(o => o.name === "UV2").currentValue ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
            </div>
            
            <div className="flex">
                <div className="rounded-r-lg border-2 border-gray-400 w-16 text-center font-semibold bg-gray-100 h-7 mt-0.5 text-sm">O2</div>
                <div className="shadow-inner h-2 w-3 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("DV3"); setValvetitle('O2 Down Valve 3') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${gasBoxState?.find(o => o.name === "DV3").currentValue ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input type="" class="h-6 w-12 text-center py-4 px-1 pr-0 rounded dysing-input-yellow cursor-pointer" value={mfc3} onClick={() => { setselectedMfc("MFC3"); setmfcvalue(gasBoxState?.find(o => o.name === "MFC3out").currentValue) }}/>
                 <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                 <input class="h-6 w-12 text-center py-4  dysing-input rounded" value={gasBoxState?.find(o => o.name === "MFC3").currentValue} readonly="true"/>
                 <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("UV3"); setValvetitle('O2 Up Valve 3') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${gasBoxState?.find(o => o.name === "UV3").currentValue ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
            </div>
            <div className="flex">
                <div className="rounded-r-lg border-2 border-gray-400 w-16 text-center font-semibold bg-gray-100 h-7 mt-0.5 text-sm">Ar/H2 </div>
                <div className="shadow-inner h-2 w-3 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("DV4"); setValvetitle('Ar/H2 Down Valve 4') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${gasBoxState?.find(o => o.name === "DV4").currentValue ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input type="" class="h-6 w-12 text-center py-4 px-1 pr-0 rounded dysing-input-yellow cursor-pointer" value={mfc4} onClick={() => { setselectedMfc("MFC4"); setmfcvalue(gasBoxState?.find(o => o.name === "MFC4out").currentValue) }}/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input class="h-6 w-12 text-center py-4  dysing-input rounded" value={gasBoxState?.find(o => o.name === "MFC4").currentValue} readonly="true"/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("UV4"); setValvetitle('Ar/H2 Up Valve 4') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${gasBoxState?.find(o => o.name === "UV4").currentValue ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
            </div>
            <div className="flex">
                <div className="rounded-r-lg border-2 border-gray-400 w-16 text-center font-semibold bg-gray-100 h-7 mt-0.5 text-sm">Ar</div>
                <div className="shadow-inner h-2 w-3 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("DV5"); setValvetitle('Ar Down Valve 5') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${gasBoxState?.find(o => o.name === "DV5").currentValue ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input type="" class="h-6 w-12 text-center py-4 px-1 pr-0 rounded dysing-input-yellow cursor-pointer" value={mfc5} onClick={() => { setselectedMfc("MFC5"); setmfcvalue(gasBoxState?.find(o => o.name === "MFC5out").currentValue) }}/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input class="h-6 w-12 text-center py-4  dysing-input rounded" value={gasBoxState?.find(o => o.name === "MFC5").currentValue} readonly="true"/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("UV5"); setValvetitle('Ar Up Valve 5') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${gasBoxState?.find(o => o.name === "UV5").currentValue ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
            </div>
            <div className="flex">
                <div className="rounded-r-lg border-2 border-gray-400 px-0.5 text-center font-semibold bg-gray-100 h-7 mt-0.5 text-sm w-16">He</div>
                <div className="shadow-inner h-2 w-3 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("DV6"); setValvetitle('HE Down Valve 6') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${gasBoxState?.find(o => o.name === "DV6").currentValue ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input type="" class="h-6 w-12 text-center py-4 px-1 pr-0  dysing-input-yellow rounded cursor-pointer" value={mfc6} onClick={() => { setselectedMfc("MFC6"); setmfcvalue(gasBoxState?.find(o => o.name === "MFC6out").currentValue) }}/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input class="h-6 w-12 text-center py-4 dysing-input rounded" value={gasBoxState?.find(o => o.name === "MFC6").currentValue} readonly="true"/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("UV6"); setValvetitle('HE Up Valve 6') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${gasBoxState?.find(o => o.name === "UV6").currentValue ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
            </div>
          </div>
          
        </div>
        {selectedMfc != null &&
          <div class="absolute w-full h-full bg-black bg-opacity-25 top-0 left-0">
            <div className='mx-auto w-2/3 bg-white  mt-36 relative shadow-2xl rounded-xl'>
              <button className='bg-red-500 px-2 text-white absolute -right-3 -top-3 rounded-full' onClick={() => { setselectedMfc(null); setmfcvalue(null) }}>x</button>
              {/* <div className='text-base font-bold'>Change Value of {selectedMfc}</div> */}

              {/* <input type='number' value={mfcvalue ? mfcvalue : ''} onChange={(e) => setmfcvalue(e.target.value)} className='p-2 text-lg border bg-gray-100 w-full mt-5' placeholder='change value' /> */}
              <div className="flex mt-2 rounded-xl overflow-hidden">
                <input type='number' value={mfcvalue ? mfcvalue : ''} onChange={(e) => setmfcvalue(e.target.value)} className='p-2 text-sm border bg-gray-100 w-full font-bold flex-1' placeholder='change value' />
                <button className='bg-gray-600 rounded hover:bg-gray-400 text-white px-3 py-1 text-xs font-bold' onClick={() => handleMfcvalue()}>Update</button>
              </div>
              
              
            </div>
          </div>
        }
            {selectedValve != null &&
          <div class="absolute w-full h-full bg-black bg-opacity-25 top-0 left-0">
            <div className='mx-5 bg-white p-4 mt-16 relative shadow-2xl rounded-lg'>
              <div className='text-base font-medium'>{gasBoxState?.find(o => o.name === selectedValve).currentValue === true ? 'CLOSE ' : 'OPEN '} <span className="font-bold">{Valvetitle}</span> ?</div>
              <div className='flex justify-between gap-3'>
                {gasBoxState?.find(o => o.name === selectedValve).currentValue === true ?
                  <button className='bg-white-500 shadow-md hover:bg-gray-300 border border-b text-black px-3 py-1 mt-3 rounded font-bold flex-1' onClick={() => { handleClick(false); }}>Yes</button>
                  :
                  <button className='bg-white-500 shadow-md hover:bg-gray-300 border border-b text-black px-3 py-1 mt-3 rounded  font-bold flex-1' onClick={() => handleClick(true)}>Yes</button>
                }
                <button className='bg-gray-600 text-white px-3 py-2 mt-3 rounded flex-1' onClick={() => setselectedValve(null)}>No</button>
              </div>


              {/* <input type='number' value={mfcvalue? mfcvalue:''} onChange={(e)=>setmfcvalue(e.target.value)} className='p-2 text-lg border bg-gray-100 w-full mt-5' placeholder='change value' /> */}
              {/* <button className='bg-orange-500 text-white px-3 py-2 mt-3 rounded'  onClick={()=> handleMfcvalue()}>Update</button> */}
            </div>
          </div>
        }
    </div>


  </>);
}