import React, { useEffect, useRef, useState, useReducer } from "react";
import { ChannelService, ChannelSubscriptionService } from "@getvia/sdk";

const viaConfig = {
  channelServiceOptions: {
    apiToken: "test",
    baseUrl: process.env.REACT_APP_VIA_CORE_API,
    wsUrl: process.env.REACT_APP_VIA_NOTIFICATION_HUB
  },
};

const defaultMotorConfiguration = [
  {
    object: 'Chuck_Motor',
    name: 'Chuck_Motor',
    readChannelName: 'MAIN.Chuck_Motor.Out.Control_word',
    writeChannelName: 'MAIN.Chuck_Motor.Out.Control_word',
    feedBackChannel: 'MAIN.Chuck_Motor.Out.Control_word',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Modes_of_operation',
    readChannelName: 'MAIN.Chuck_Motor.Out.Modes_of_operation',
    writeChannelName: 'MAIN.Chuck_Motor.Out.Modes_of_operation',
    feedBackChannel: 'MAIN.Chuck_Motor.Out.Modes_of_operation',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Chuck_Motor',
    readChannelName: 'MAIN.Chuck_Motor.In.Profile_target_velocity',
    writeChannelName: 'MAIN.Chuck_Motor.Out.Profile_target_velocity',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Profile_target_velocity',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Profile_target_position',
    readChannelName: 'MAIN.Chuck_Motor.Out.Profile_target_position',
    writeChannelName: 'MAIN.Chuck_Motor.Out.Profile_target_position',
    feedBackChannel: 'MAIN.Chuck_Motor.Out.Profile_target_position',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Chuck_Motor',
    readChannelName: 'MAIN.Chuck_Motor.In.Profile_target_acceleration',
    writeChannelName: 'MAIN.Chuck_Motor.Out.Profile_target_acceleration',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Profile_target_acceleration',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Chuck_Motor',
    readChannelName: 'MAIN.Chuck_Motor.In.Profile_target_deceleration',
    writeChannelName: 'MAIN.Chuck_Motor.Out.Profile_target_deceleration',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Profile_target_deceleration',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Tracking_warning_timeout',
    readChannelName: 'MAIN.Chuck_Motor.Out.Tracking_warning_timeout',
    writeChannelName: 'MAIN.Chuck_Motor.Out.Tracking_warning_timeout',
    feedBackChannel: 'MAIN.Chuck_Motor.Out.Tracking_warning_timeout',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Chuck_Motor',
    readChannelName: 'MAIN.Chuck_Motor.In.Homing_method',
    writeChannelName: 'MAIN.Chuck_Motor.Out.Homing_method',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Homing_method',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Status_word',
    readChannelName: 'MAIN.Chuck_Motor.In.Status_word',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Status_word',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Actual_motor_position',
    readChannelName: 'MAIN.Chuck_Motor.In.Actual_motor_position',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Actual_motor_position',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Modes_of_operation_display',
    readChannelName: 'MAIN.Chuck_Motor.In.Modes_of_operation_display',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Modes_of_operation_display',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Commanded_position',
    readChannelName: 'MAIN.Chuck_Motor.In.Commanded_position',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Commanded_position',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Commanded_velocity',
    readChannelName: 'MAIN.Chuck_Motor.In.Commanded_velocity',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Commanded_velocity',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Torque_demand_value',
    readChannelName: 'MAIN.Chuck_Motor.In.Torque_demand_value',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Torque_demand_value',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Digital_input_line_status',
    readChannelName: 'MAIN.Chuck_Motor.In.Digital_input_line_status',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Digital_input_line_status',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Shutdown_option_code',
    readChannelName: 'MAIN.Chuck_Motor.In.Shutdown_option_code',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Shutdown_option_code',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Disable_option_code',
    readChannelName: 'MAIN.Chuck_Motor.In.Disable_option_code',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Disable_option_code',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Halt_option_code',
    readChannelName: 'MAIN.Chuck_Motor.In.Halt_option_code',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Halt_option_code',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Last_error_code',
    readChannelName: 'MAIN.Chuck_Motor.In.Last_error_code',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Last_error_code',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Peak_current_limit',
    readChannelName: 'MAIN.Chuck_Motor.In.Peak_current_limit',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Peak_current_limit',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Continuous_current_limit',
    readChannelName: 'MAIN.Chuck_Motor.In.Continuous_current_limit',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Continuous_current_limit',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Torque_actual_value',
    readChannelName: 'MAIN.Chuck_Motor.In.Torque_actual_value',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Torque_actual_value',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Torque_actual_value',
    readChannelName: 'MAIN.Chuck_Motor.In.Torque_actual_value',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Torque_actual_value',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'RMS_current',
    readChannelName: 'MAIN.Chuck_Motor.In.RMS_current',
    feedBackChannel: 'MAIN.Chuck_Motor.In.RMS_current',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Latching_faults',
    readChannelName: 'MAIN.Chuck_Motor.In.Latching_faults',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Latching_faults',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Actual_motor_velocity',
    readChannelName: 'MAIN.Chuck_Motor.In.Actual_motor_velocity',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Actual_motor_velocity',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Actual_motor_current',
    readChannelName: 'MAIN.Chuck_Motor.In.Actual_motor_current',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Actual_motor_current',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Quick_stop_option_code',
    readChannelName: 'MAIN.Chuck_Motor.In.Quick_stop_option_code',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Quick_stop_option_code',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Motor_OPState',
    readChannelName: 'MAIN.Chuck_Motor.In.Motor_OPState',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Motor_OPState',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Status_ISOChuckMotorServoOnSeq',
    readChannelName: 'Status_ISOChuckMotorServoOnSeq',
    writeChannelName: 'Status_ISOChuckMotorServoOnSeq',
    feedBackChannel: 'Status_ISOChuckMotorServoOnSeq',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Status_ISOChuckMotorHomeSeq',
    readChannelName: 'Status_ISOChuckMotorHomeSeq',
    feedBackChannel: 'Status_ISOChuckMotorHomeSeq',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Status_ISOChuckMotorProcessPosSeq',
    readChannelName: 'Status_ISOChuckMotorProcessPosSeq',
    feedBackChannel: 'Status_ISOChuckMotorProcessPosSeq',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Status_ISOChuckMotorTransferPosSeq',
    readChannelName: 'Status_ISOChuckMotorTransferPosSeq',
    feedBackChannel: 'Status_ISOChuckMotorTransferPosSeq',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Chuck_Motor',
    readChannelName: 'MAIN.Chuck_Motor.In.Home_Approach_Speed',
    writeChannelName: 'MAIN.Chuck_Motor.Out.Home_Approach_Speed',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Home_Approach_Speed',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Chuck_Motor',
    readChannelName: 'MAIN.Chuck_Motor.In.Home_Sync_Speed',
    writeChannelName: 'MAIN.Chuck_Motor.Out.Home_Sync_Speed',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Home_Sync_Speed',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Chuck_Motor',
    readChannelName: 'MAIN.Chuck_Motor.In.Homing_acceleration',
    writeChannelName: 'MAIN.Chuck_Motor.Out.Homing_acceleration',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Homing_acceleration',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Chuck_MotorTarget_velocity',
    readChannelName: 'MAIN.Chuck_Motor.Out.Target_velocity',
    writeChannelName: 'MAIN.Chuck_Motor.Out.Target_velocity',
    feedBackChannel: 'MAIN.Chuck_Motor.Out.Target_velocity',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'ISOChuck_Motor_Config_Process_Position',
    readChannelName: 'ISOChuck_Motor_Config_Process_Position',
    writeChannelName: 'ISOChuck_Motor_Config_Process_Position',
    feedBackChannel: 'ISOChuck_Motor_Config_Process_Position',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'ISOChuck_Motor_Config_Transfer_Position',
    readChannelName: 'ISOChuck_Motor_Config_Transfer_Position',
    writeChannelName: 'ISOChuck_Motor_Config_Transfer_Position',
    feedBackChannel: 'ISOChuck_Motor_Config_Transfer_Position',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'ISOChuckMotor_Running_InstanceId',
    readChannelName: 'ISOChuckMotor_Running_InstanceId',
    writeChannelName: 'ISOChuckMotor_Running_InstanceId',
    feedBackChannel: 'ISOChuckMotor_Running_InstanceId',
    currentValue: ""
  },
  {
    object: 'Chuck_Motor',
    name: 'Status_ISOChuckMotorMoveToPosSeq',
    readChannelName: 'Status_ISOChuckMotorMoveToPosSeq',
    writeChannelName: 'Status_ISOChuckMotorMoveToPosSeq',
    feedBackChannel: 'Status_ISOChuckMotorMoveToPosSeq',
    currentValue: null
  },
]
const channelService = new ChannelService(viaConfig.channelServiceOptions);
const channelSubscriptionService = new ChannelSubscriptionService(viaConfig.channelServiceOptions);
export default function IsotopicMoterModel({ IOWrite}) {

  const [checkedVelocity, setCheckedVelocity] = useState(false);
  const [resetBtnClicked, setResetBtnClicked] = useState(false);
  const [switchOnComplete, setSwitchOnComplete] = useState(false);
  const [switchhitchonPress,setswitchonpress] = useState(false);
  const [switchonerror, setSwitchonerror] = useState(false);
  const [homePress,sethomePress] = useState(false)
  const [processPress,setProcessPress] = useState(false)
  const [transferPress,setTransferPress] = useState(false)
  const [targetPress,setTargetPress] = useState(false)

  const [homeerror, setHomeerror] = useState(false);
  const [processerror, setProcesserror] = useState(false);
  const [transfererror, setTransfererror] = useState(false);
  const [targetError, setTargetError] = useState(false);

  const [velocityModal, setVelocityModal]  = useState(false);
  const [velocity, setVelocity] = useState(0);

  const [selectedVelocityVal, setSelectedVelocityVal] = useState();
  const motorConfiguration = useRef(defaultMotorConfiguration);
  const subscriptionRef = useRef();
  const [gasBoxState, setchannelState] = useState();
  const [targetPosModal, setTargetPosModal] = useState(false);
  const [targetPosValue, setTargetPosValue] = useState('');

  const channels = useRef();
  const fetchChannelsStates = async () => {
    const response = await channelService.ChannelStates();
    console.log("ChannelStates: ", { response });
    if (response) {
      channels.current = response;
      console.log("channels.current", channels.current);
      updateMotorsState(channels.current);
     
    }
  };
  const updateMotorsState = (updatedChannels) => {
    let index = -1
    let motorConfigurationState = motorConfiguration.current;
    updatedChannels?.forEach((item) => {
      const config = motorConfigurationState.find((obj, objIndex) => {
        index = objIndex;
        return obj.feedBackChannel === item.channelName
      })
      if (config) {
        config.currentValue = item.data.value;
        motorConfigurationState[index] = config;
      }
    })
    console.log('motorConfigurationState',motorConfigurationState)
    setchannelState(() => motorConfigurationState);
    //==============check switchon==========start================
    if(motorConfigurationState?.find(o => o.readChannelName === "MAIN.Chuck_Motor.Out.Control_word").currentValue==128) {
      setResetBtnClicked(true)
    }else{
      setResetBtnClicked(false)
    }
      let Status_ISOChuckMotorServoOnSeq = motorConfigurationState?.find(o => o.name === "Status_ISOChuckMotorServoOnSeq").currentValue;
      if(Status_ISOChuckMotorServoOnSeq=="RUNNING") {
        setswitchonpress(true);
        setSwitchOnComplete(false);
      } 
      else if(Status_ISOChuckMotorServoOnSeq=="COMPLETED") {
        setswitchonpress(false);
        setSwitchOnComplete(true);
      }
      else if(Status_ISOChuckMotorServoOnSeq=="TERMINATED") {
        setswitchonpress(false);
        setSwitchOnComplete(false);
      }
      else if(Status_ISOChuckMotorServoOnSeq=="FAILED") {
        setswitchonpress(false);
        setSwitchOnComplete(false);
        setSwitchonerror(true)
      } else {
        setswitchonpress(false);
        setSwitchOnComplete(false);
      }
    //==============check switchon==========end=================
    //==============SERVO ON==========end=================
      let Status_ISOChuckMotorHomeSeq = motorConfigurationState?.find(o => o.name === "Status_ISOChuckMotorHomeSeq").currentValue;
       if(Status_ISOChuckMotorHomeSeq=="RUNNING") {
        sethomePress(true);
      } 
      else if(Status_ISOChuckMotorHomeSeq=="COMPLETED") {
        sethomePress(false);
      }
      else if(Status_ISOChuckMotorHomeSeq=="TERMINATED") {
        sethomePress(false);
      }
      else if(Status_ISOChuckMotorHomeSeq=="FAILED") {
        sethomePress(false);
        setHomeerror(true)
      } else {
        sethomePress(false);
      }
    //==============SERVO ON==========end=================
    
    //==============SERVO ON==========end=================
    let Status_ISOChuckMotorProcessPosSeq = motorConfigurationState?.find(o => o.name === "Status_ISOChuckMotorProcessPosSeq").currentValue;
    if(Status_ISOChuckMotorProcessPosSeq=="RUNNING") {
      setProcessPress(true);
    } 
    else if(Status_ISOChuckMotorProcessPosSeq=="COMPLETED") {
      setProcessPress(false);
    }
    else if(Status_ISOChuckMotorProcessPosSeq=="TERMINATED") {
      setProcessPress(false);
    }
    else if(Status_ISOChuckMotorProcessPosSeq=="FAILED") {
      setProcessPress(false);
      setProcesserror(true)
    } else {
      setProcessPress(false);
    }
  //==============SERVO ON==========end=================
    //==============SERVO ON==========end=================
    let Status_ISOChuckMotorTransferPosSeq = motorConfigurationState?.find(o => o.name === "Status_ISOChuckMotorTransferPosSeq").currentValue;
    if(Status_ISOChuckMotorTransferPosSeq=="RUNNING") {
      setTransferPress(true);
    } 
    else if(Status_ISOChuckMotorTransferPosSeq=="COMPLETED") {
      setTransferPress(false);
    }
    else if(Status_ISOChuckMotorTransferPosSeq=="TERMINATED") {
      setTransferPress(false);
    }
    else if(Status_ISOChuckMotorTransferPosSeq=="FAILED") {
      setTransferPress(false);
      setTransfererror(true)
    } else {
      setTransferPress(false);
    }
  //==============SERVO ON==========end=================
  let Status_ISOChuckMotorTargetPosSeq = motorConfigurationState?.find(o => o.name === "Status_ISOChuckMotorMoveToPosSeq")?.currentValue;
    console.log(motorConfigurationState,Status_ISOChuckMotorTargetPosSeq,'Status_ISOChuckMotorTargetPosSeq')
  if(Status_ISOChuckMotorTargetPosSeq=="RUNNING") {
      setTargetPress(true);
    } 
    else if(Status_ISOChuckMotorTargetPosSeq=="COMPLETED") {
      setTargetPress(false);
    }
    else if(Status_ISOChuckMotorTargetPosSeq=="TERMINATED") {
      setTargetPress(false);
    }
    else if(Status_ISOChuckMotorTargetPosSeq=="FAILED") {
      setTargetPress(false);
      setTargetError(true)
    } else {
      setTargetPress(false);
    }
  }
  const subscribeToNotification = () => {
    const onNewMessage = (message) => {
      const channel = JSON.parse(message);
      
      console.log("onNewMessage", channel);
      // setchannellist(channel);
      updateMotorsState([channel])
    };

    let channelSubscriptions = [];

    motorConfiguration.current.forEach(config => {
      channelSubscriptions.push(config.feedBackChannel);
    });
    const subscription = {
      channels: channelSubscriptions,
      onNewMessage: onNewMessage,
    };
   
    subscriptionRef.current = subscription;
    channelSubscriptionService.subscribe(subscription);
  };

  const unSubscribe = () => {
    if(subscriptionRef){
          channelSubscriptionService?.unSubscribe(subscriptionRef?.current);
    }

  };
  useEffect(() => {
    const fetChannelData = async () => {
      await fetchChannelsStates();
      subscribeToNotification();
    };
    fetChannelData();
   
    return () => {
      // unSubscribe();
      
    };
  }, []);




  const handleResetClick = (e) => {
    console.log('value',e.target.getAttribute('data-value'))
    const target = e.target;
    const channelName = target.getAttribute('data-channel');
    const value = target.getAttribute('data-value');
    const btnName = target.getAttribute('data-name');

    if(channelName && value) {
      IOWrite(channelName, value)
      // IOWrite('Status_ISOChuckMotorServoOnSeq', "TERMINATED")
      IOWrite('Status_ISOChuckMotorServoOnSeq', " ")
    }
      setResetBtnClicked(true)
      setswitchonpress(false)
      setSwitchOnComplete(false)
  }  
  const handleSwitchOnBtnClick =  async (e) => { 
    setswitchonpress(true);
    setSwitchOnComplete(false);
    setSwitchonerror(false)
    var formdata = {
      token: "",
      ruleType: "Sequence",
      ruleGroups: [],
      ruleSubGroups: [],
      rule: "",
      sequence: "ISOChuckMotorServoOnSeq",
      inputData: {},
    };
    try {
       await fetch(
        process.env.REACT_APP_VIA_RULE_ORCHES_API + "/api/Rules/ExecuteRule",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formdata),
        }
      ).then(response => response.json())
      .then(data => {
          const resultValue = data[0].resultValue;    
          if(resultValue!=null){
          console.log('resultValue print',resultValue);   
          // alert(resultValue)
             IOWrite('ISOChuckMotor_Running_InstanceId', resultValue);
          } 
      });
      // if (response.ok) {
      //   console.log("Alarm stopped");
       
      // } else {
      //   console.error("Failed to post data.");
       
      //   setSwitchonerror(true)
      // }
    } catch (error) {
      console.error("Error:", error);
      
      // setSwitchonerror(true)
    }
  }
  const handleHomeButtonClick = async (e) => { 
    sethomePress(true)
    var formdata = {
      token: "",
      ruleType: "Sequence",
      ruleGroups: [],
      ruleSubGroups: [],
      rule: "",
      sequence: "ISOChuckMotorHomeSeq",
      inputData: {},
    };
    try {
       await fetch(
        process.env.REACT_APP_VIA_RULE_ORCHES_API + "/api/Rules/ExecuteRule",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formdata),
        }
      ).then(response => response.json())
      .then(data => {
          const resultValue = data[0].resultValue;    
          if(resultValue!=null){
          console.log('resultValue print',resultValue);   
          // alert(resultValue)
             IOWrite('ISOChuckMotor_Running_InstanceId', resultValue);
          } 
      })

      // if (response.ok) {
      //   console.log("Alarm stopped");
       
      // } else {
      //   console.error("Failed to post data.");
      //   sethomePress(false)
      // }
    } catch (error) {
      console.error("Error:", error);
      sethomePress(false)
    }
   }
   const handleProcessPosBtnClick = async (e) => { 
    setProcessPress(true)
    var formdata = {
      token: "",
      ruleType: "Sequence",
      ruleGroups: [],
      ruleSubGroups: [],
      rule: "",
      sequence: "ISOChuckMotorProcessPosSeq",
      inputData: {},
    };
    try {
      await fetch(
        process.env.REACT_APP_VIA_RULE_ORCHES_API + "/api/Rules/ExecuteRule",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formdata),
        }
      ).then(response => response.json())
      .then(data => {
          const resultValue = data[0].resultValue;
          console.log('resultValue',resultValue);        
          IOWrite('ISOChuckMotor_Running_InstanceId', resultValue);
          // setProcessPress(false)
      })

      // if (response.ok) {
      //   console.log("Alarm stopped");
       
      // } else {
      //   console.error("Failed to post data.");
      //   setProcessPress(false)
      // }
    } catch (error) {
      console.error("Error:", error);
      setProcessPress(false)
    }
  }
  const handleTransferPosBtnClick = async (e) => { 
    setProcessPress(true)
    var formdata = {
      token: "",
      ruleType: "Sequence",
      ruleGroups: [],
      ruleSubGroups: [],
      rule: "",
      sequence: "ISOChuckMotorTransferPosSeq",
      inputData: {},
    };
    try {
       await fetch(
        process.env.REACT_APP_VIA_RULE_ORCHES_API + "/api/Rules/ExecuteRule",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formdata),
        }
      ) .then(response => response.json())
      .then(data => {
          const resultValue = data[0].resultValue;    
          if(resultValue!=null){
          console.log('resultValue print',resultValue);   
             IOWrite('ISOChuckMotor_Running_InstanceId', resultValue);
          } 
      })
  
      
    } catch (error) {
      console.error("Error:", error);
      setProcessPress(false)
    }
  }
  const handleTargetPosBtnClick = async (e) =>{
    setTargetPosModal(true)
  }
  const handleTargetPosVal = async (e) => {
    if(targetPosValue != ''){
      setTargetPress(false)
      IOWrite('MAIN.Chuck_Motor.Out.Profile_target_position', targetPosValue);
      setTargetPosModal(false)
      setTargetPosValue('')

      var formdata = {
        token: "",
        ruleType: "Sequence",
        ruleGroups: [],
        ruleSubGroups: [],
        rule: "",
        sequence: "ISOChuckMotorMoveToPosSeq",
        inputData: {},
      };

      setTimeout(() => {
        try {
          fetch(
            process.env.REACT_APP_VIA_RULE_ORCHES_API + "/api/Rules/ExecuteRule",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(formdata),
            }
          ).then(response => response.json())
          .then(data => {
              const resultValue = data[0].resultValue;    
              if(resultValue!=null){
              console.log('resultValue print',resultValue);   
                //  IOWrite('ISOChuckMotor_Running_InstanceId', resultValue);
              } 
          })
      
          
        } catch (error) {
          console.error("Error:", error);
          setTargetPress(false)
        }
      }, 100);
    
    }
  }
  const handleButtonClick = (e) => {
    console.log('value',e.target.getAttribute('data-value'))
    const target = e.target;
    const channelName = target.getAttribute('data-channel');
    const value = target.getAttribute('data-value');
    const btnName = target.getAttribute('data-name');

    if(channelName && value) {
      IOWrite(channelName, value)
    }

    if(btnName && btnName == 'reset-btn') {
     
      setSwitchonerror(false)
    }

    // fetChannelData();
  }
  const handlevelocityvalue =(velocity)=>{
    IOWrite('MAIN.Chuck_Motor.Out.Target_velocity', velocity)
  }
  const handleVelocityCheckbox = () => {
    let newVal = !checkedVelocity;
    setCheckedVelocity(newVal)
    if(newVal) {
      IOWrite('MAIN.Chuck_Motor.Out.Modes_of_operation', 3)
      
    } else {
      // IOWrite('MAIN.Chuck_Motor.Out.Modes_of_operation', 6)
    }
    
  }
  const increaseVelocity = () => {
    const val = Number(velocity) + 100;
    // if (velocity < 10000) {
      setVelocity(val);
    // }
    console.log(velocity)
    handlevelocityvalue(val);
  
  };

  const decreaseVelocity = () => {
    let val;
    if (velocity > 110) {
      val = Number(velocity) - 100;
      setVelocity(val);
    }
    handlevelocityvalue(val);
  };
  const addEditVelocityVal = (e) => {
    const val = e.target.value;
    setSelectedVelocityVal(val);
    setVelocityModal(true);
  }




 
  return (<>
    
    <div className="text-black">
        <div className="flex w-full">
          <div className="flex-1">Actual Position</div>
          <div className="dysing-input flex-1 p-1 px-2 rounded">{gasBoxState?.find(o => o.name === "Actual_motor_position")?.currentValue} </div>
        </div>
        <div className="flex w-full mt-3">
          <div className="flex-1">Actual Velocity</div>
          <div className="dysing-input flex-1 p-1 px-2 rounded">{gasBoxState?.find(o => o.name === "Actual_motor_velocity")?.currentValue} </div>
        </div>

        <div>
          <button 
              data-channel="MAIN.Chuck_Motor.Out.Control_word"
              data-value={128}
              data-name="reset-btn"
              disabled={checkedVelocity}
              onClick={handleResetClick}
              // disabled={motoOPState != 8} 
              className="dysing-motorbtn hover:bg-white shadow-md shadow-gray-400 !w-full text-sm  mt-5 font-bold py-3 rounded-lg  !text-gray-600">
              RESET
          </button>
          <button 
              onClick={handleSwitchOnBtnClick}
              disabled={!resetBtnClicked||checkedVelocity||switchOnComplete||switchhitchonPress}
              className={`${switchhitchonPress? 'bg-gray-200':'dysing-motorbtn hover:bg-white shadow-md shadow-gray-400'}   disabled:opacity-50  text-sm  !w-full !py-3 mt-5 font-bold leading-6 rounded-lg  !text-gray-600 ${switchonerror && 'blink-bg'}`}>
              SERVO ON
            </button>
            <button 
              data-channel="MAIN.Chuck_Motor.Out.Control_word"
              data-value="31"
              disabled={!switchOnComplete||checkedVelocity||homePress||processPress||transferPress || targetPress} 
              onClick={handleHomeButtonClick}
              className={`${homePress? 'bg-gray-200':'dysing-motorbtn hover:bg-white shadow-md shadow-gray-400'}  disabled:opacity-50  !w-full !py-2  mt-5 text-sm font-bold leading-6 rounded-lg  !text-gray-600 ${homeerror && 'blink-bg'}`}>
              HOME
            </button>
            <button
              data-channel="MAIN.Chuck_Motor.Out.Control_word"
              data-value="15"
              onClick={handleProcessPosBtnClick}
              disabled={!switchOnComplete||checkedVelocity||homePress||processPress||transferPress || targetPress} 
              className={`${processPress? 'bg-gray-200':'dysing-motorbtn hover:bg-white shadow-md shadow-gray-400'} disabled:opacity-50    !w-full !py-2 !h-12 mt-5 text-sm font-bold leading-6 rounded-lg  !text-gray-600  ${processerror && 'blink-bg'}`}>
              PROCESS POSITION
            </button>
            <button   disabled={!switchOnComplete||checkedVelocity||homePress||processPress||transferPress || targetPress} 
              onClick={handleTransferPosBtnClick}
             
              className={`${transferPress?'bg-gray-200':'dysing-motorbtn hover:bg-white dysing-btn-shadow '}  disabled:opacity-50    !w-full !py-2 !h-12 mt-5 text-sm font-bold leading-6 rounded-lg  !text-gray-600 ${transfererror && 'blink-bg'}`}>
               TRANSFER POSITION
            </button>
            <div className="relative">
              <button   disabled={!switchOnComplete||checkedVelocity||homePress||processPress||transferPress || targetPress}
                onClick={handleTargetPosBtnClick}
                className={`${targetPress?'bg-gray-200':'dysing-motorbtn hover:bg-white dysing-btn-shadow '}  disabled:opacity-50    !w-full !py-2 !h-12 mt-5 text-sm font-bold leading-6 rounded-lg  !text-gray-600 ${targetError && 'blink-bg'}`}>
                MOVE TO POSITION
              </button>
              {targetPosModal&&
                    <div class="absolute w-full h-full bg-black bg-opacity-25 z-20 -top-12 left-0">
                      <div className='mx-auto w-4/5 bg-white relative shadow-2xl rounded-xl'>
                        <button className='bg-red-500 px-2 text-white absolute -right-3 -top-3 rounded-full' 
                        onClick={() => { setTargetPosModal(false); setTargetPosValue('') }}>x</button>
                      <div className="flex mt-2 rounded-xl overflow-hidden">
                          <input type='number' value={targetPosValue} onChange={(e) => setTargetPosValue(e.target.value)} 
                          className='p-2 text-sm border bg-gray-100 w-full font-bold flex-1' placeholder='set move to value' />
                          <button className='bg-gray-600 rounded hover:bg-gray-400 text-white px-3 py-1 text-xs font-bold' 
                          onClick={() => handleTargetPosVal()}>Update</button>
                        </div>
                      </div>
                    </div>
                } 
            </div>
            <button 
              data-channel="MAIN.Chuck_Motor.Out.Control_word"
              data-value={128}
              data-name="reset-btn"
              onClick={handleButtonClick}
              disabled={!switchOnComplete||checkedVelocity} 
              className="dysing-motorbtn disabled:bg-gray-200 disabled:opacity-50   !w-full !py-2 !h-12 mt-5 text-sm font-bold leading-6 rounded-lg  !text-gray-600 shadow-md shadow-gray-400">
              STOP
            </button>

            <div className="flex items-center mb-2 mt-4">
              <div className="text-sm font-bold inline-block pl-1 flex-1">
                Velocity Mode
              </div>
              <button
                onClick={handleVelocityCheckbox}
                className={`${
                  checkedVelocity ? "text-black" : "text-white"
                } flex   text-sm font-bold rounded-full hover:bg-gray-400 border-2 bg-blue-200 py-0.5 shadow-inner shadow-gray-400 h-7 w-16 relative`}
              >
               <div className={`rounded-full h-5 w-5  bg-gray-300 border-2 shadow-inner shadow-gray-500 absolute top-0.5 ${checkedVelocity ? "left-1" : "right-1"}`}></div> 
               {checkedVelocity ? 
               <div className="flex-1 text-right px-2 text-gray-600">On</div>
               :
               <div  className="flex-1 text-left px-2 text-gray-600">Off</div>
               }
              </button>
            </div>
            <div className={`flex gap-4 ${!checkedVelocity&& 'opacity-20' }`}>
              <button
                disabled={!checkedVelocity}
                onClick={decreaseVelocity}
                className={`${!checkedVelocity ? 'bg-inactive text-slate-500' : 'dysing-motorbtn shadow-shadow100 hover:bg-white'} flex-1  rounded-md`}
              >
              <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none" className="mx-auto">
              <path d="M14.2893 5.70708C13.8988 5.31655 13.2657 5.31655 12.8751 5.70708L7.98768 10.5993C7.20729 11.3805 7.2076 12.6463 7.98837 13.427L12.8787 18.3174C13.2693 18.7079 13.9024 18.7079 14.293 18.3174C14.6835 17.9269 14.6835 17.2937 14.293 16.9032L10.1073 12.7175C9.71678 12.327 9.71678 11.6939 10.1073 11.3033L14.2893 7.12129C14.6799 6.73077 14.6799 6.0976 14.2893 5.70708Z" fill="#0F0F0F"/>
              </svg>
              </button>  
              <div className="relative flex-1 text-center">
                {checkedVelocity?
                <div onClick={addEditVelocityVal}  
                className="dysing-input px-3 py-1 rounded-md">
                  {velocity}
                </div>
                :
                <div 
                className="dysing-input px-3 py-1  rounded-md ">
                  {velocity}
                </div>
                }
              </div>
              <button
                disabled={!checkedVelocity}
                onClick={increaseVelocity}
                  className={`${!checkedVelocity ? 'bg-inactive text-slate-500' : 'dysing-motorbtn shadow-shadow100 hover:bg-white'} flex-1 rounded-md`}
              >
              <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none"  className="mx-auto">
              <path d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z" fill="#0F0F0F"/>
              </svg>
              </button>
            </div>   
        </div>
    </div>


  </>);
}