import React, { useEffect, useRef, useState, useReducer } from "react";
import { ChannelService, ChannelSubscriptionService } from "@getvia/sdk";

const viaConfig = {
  channelServiceOptions: {
    apiToken: "test",
    baseUrl: process.env.REACT_APP_VIA_CORE_API,
    wsUrl: process.env.REACT_APP_VIA_NOTIFICATION_HUB
  },
};
const defaultGasBoxConfiguration = [
    {
      object: "MFC",
      name: "MFC7",
      readChannelName: "MAIN.MFC7.In.Flow_Reading",
      writeChannelName: "MAIN.MFC7.Out.Flow_SP",
      feedBackChannel: "MAIN.MFC7.In.Flow_Reading",
      currentValue: 0,
      setValue:0,
    },
    {
      object: "MFC",
      name: "MFC7out",
      feedBackChannel: "MAIN.MFC7.Out.Flow_SP",
      currentValue: 0,
    },
    {
      object: "MFC",
      name: "MFC8",
      readChannelName: "MAIN.MFC8.In.Flow_Reading",
      writeChannelName: "MAIN.MFC8.Out.Flow_SP",
      feedBackChannel: "MAIN.MFC8.In.Flow_Reading",
      currentValue: 0,
      setValue:0,
    },
    {
      object: "MFC",
      name: "MFC8out",
      feedBackChannel: "MAIN.MFC8.Out.Flow_SP",
      currentValue: 0,
    },
    {
      object: "MFC",
      name: "MFC9",
      readChannelName: "MAIN.MFC9.In.Flow_Reading",
      writeChannelName: "MAIN.MFC9.Out.Flow_SP",
      feedBackChannel: "MAIN.MFC9.In.Flow_Reading",
      currentValue: 0,
      setValue:0,
    },
    {
      object: "MFC",
      name: "MFC9out",
      feedBackChannel: "MAIN.MFC9.Out.Flow_SP",
      currentValue: 0,
    },
    {
      object: "MFC",
      name: "MFC10",
      readChannelName: "MAIN.MFC10.In.Flow_Reading",
      writeChannelName: "MAIN.MFC10.Out.Flow_SP",
      feedBackChannel: "MAIN.MFC10.In.Flow_Reading",
      currentValue: 0,
      setValue:0,
    },
    {
      object: "MFC",
      name: "MFC10out",
      feedBackChannel: "MAIN.MFC10.Out.Flow_SP",
      currentValue: 0,
    },
    {
      object: "MFC",
      name: "MFC11",
      readChannelName: "MAIN.MFC11.In.Flow_Reading",
      writeChannelName: "MAIN.MFC11.Out.Flow_SP",
      feedBackChannel: "MAIN.MFC11.In.Flow_Reading",
      currentValue: 0,
      setValue:0,
    },
    {
      object: "MFC",
      name: "MFC11out",
      feedBackChannel: "MAIN.MFC11.Out.Flow_SP",
      currentValue: 0,
    },
    {
      object: "MFC",
      name: "MFC12",
      readChannelName: "MAIN.MFC12.In.Flow_Reading",
      writeChannelName: "MAIN.MFC12.Out.Flow_SP",
      feedBackChannel: "MAIN.MFC12.In.Flow_Reading",
      currentValue: 0,
      setValue:0,
    },
    {
      object: "MFC",
      name: "MFC12out",
      feedBackChannel: "MAIN.MFC12.Out.Flow_SP",
      currentValue: 0,
    },
    {
      object: "DownValve",
      name: "DV7",
      readChannelName: "MAIN.MFC7_Downstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC7_Downstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC7_Downstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "DownValve",
      name: "DV8",
      readChannelName: "MAIN.MFC8_Downstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC8_Downstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC8_Downstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "DownValve",
      name: "DV9",
      readChannelName: "MAIN.MFC9_Downstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC9_Downstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC9_Downstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "DownValve",
      name: "DV10",
      readChannelName: "MAIN.MFC10_Downstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC10_Downstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC10_Downstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "DownValve",
      name: "DV11",
      readChannelName: "MAIN.MFC11_Downstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC11_Downstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC11_Downstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "DownValve",
      name: "DV12",
      readChannelName: "MAIN.MFC12_Downstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC12_Downstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC12_Downstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "UpValve",
      name: "UV7",
      readChannelName: "MAIN.MFC7_Upstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC7_Upstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC7_Upstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "UpValve",
      name: "UV8",
      readChannelName: "MAIN.MFC8_Upstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC8_Upstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC8_Upstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "UpValve",
      name: "UV9",
      readChannelName: "MAIN.MFC9_Upstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC9_Upstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC9_Upstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "UpValve",
      name: "UV10",
      readChannelName: "MAIN.MFC10_Upstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC10_Upstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC10_Upstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "UpValve",
      name: "UV11",
      readChannelName: "MAIN.MFC11_Upstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC11_Upstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC11_Upstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "UpValve",
      name: "UV12",
      readChannelName: "MAIN.MFC12_Upstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC12_Upstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC12_Upstream.Out.On_Off_cmd",
      currentValue: false,
    },
  ];
  const channelService = new ChannelService(viaConfig.channelServiceOptions);
  const channelSubscriptionService = new ChannelSubscriptionService(viaConfig.channelServiceOptions);

  
export default function DicingGasbox() {
    const gasBoxConfiguration = useRef(defaultGasBoxConfiguration);
    const channels = useRef();
    const subscriptionRef = useRef();
    const [gasBoxState, setGasBoxState] = useState();

    const [selectedValve, setselectedValve] = useState(null);
    const [Valvetitle, setValvetitle] = useState(null);
    const [selectedMfc, setselectedMfc] = useState(null);

    const [flag,setFlag] =useState(0);
    const [mfc7,setMfc7] =useState(null);
    const [mfc8,setMfc8] =useState(null);
    const [mfc9,setMfc9] =useState(null);
    const [mfc10,setMfc10] =useState(null);
    const [mfc11,setMfc11] =useState(null);
    const [mfc12,setMfc12] =useState(null);


    const [mfcvalue, setmfcvalue] = useState(null);
    const fetchChannelsStates = async () => {
        const response = await channelService.ChannelStates();
        console.log("ChannelStates: ", { response });
        if (response) {
            channels.current = response;
            updateGasBoxState(channels.current);
        }
        };
    const updateGasBoxState = (updatedChannels) => {
        let index = -1
        let gasBoxConfigurationState = gasBoxConfiguration.current;
        updatedChannels.forEach((item) => {
            const config = gasBoxConfigurationState.find((obj, objIndex) => {
            index = objIndex;
            return obj.feedBackChannel === item.channelName
            })
            if (config) {
            config.currentValue = item.data.value;
            gasBoxConfigurationState[index] = config;
            }
        })
        setGasBoxState(() => gasBoxConfigurationState);
        if(flag==0){
          setMfc7(gasBoxConfigurationState?.find(o => o.name === "MFC7out").currentValue)
          setMfc8(gasBoxConfigurationState?.find(o => o.name === "MFC8out").currentValue)
          setMfc9(gasBoxConfigurationState?.find(o => o.name === "MFC9out").currentValue)
          setMfc10(gasBoxConfigurationState?.find(o => o.name === "MFC10out").currentValue)
          setMfc11(gasBoxConfigurationState?.find(o => o.name === "MFC11out").currentValue)
          setMfc12(gasBoxConfigurationState?.find(o => o.name === "MFC12out").currentValue)
        }
        setFlag(1)
    }
    const subscribeToNotification = () => {

        const onNewMessage = (message) => {
            const channel = JSON.parse(message);
            updateGasBoxState([channel])
        };

        let channelSubscriptions = [];

        gasBoxConfiguration.current.forEach(config => {
            channelSubscriptions.push(config.feedBackChannel);
        })
        const subscription = {
            channels: channelSubscriptions,
            onNewMessage: onNewMessage,
        };

        subscriptionRef.current = subscription;
        channelSubscriptionService.subscribe(subscription);
    };
    const unSubscribe = () => {
        channelSubscriptionService.unSubscribe(subscriptionRef.current);
    };
    useEffect(() => {
        const fetChannelData = async () => {
            await fetchChannelsStates();
            subscribeToNotification();
        };
        fetChannelData();
        return () => {
            unSubscribe();
        };
    }, []);

    const IOWrite = (channelName, value) => {
        console.log("Channels: ", { channels });
        console.log("IOWrite: ", { channelName, value });
        if (channelName) {
          const channel = channels.current?.find(obj => {
            return obj.channelName === channelName
          })
          if (channel) {
            channel.data.value = value;
            channelService.Write(channel).then(() => {
            });
          }
        }
      }
    const WriteToMFC = (config, value) => {
        console.log("WriteToMFC: ", { config, value });
        const writeChannelName = config.writeChannelName;
        IOWrite(writeChannelName, value);
      }
    
      const WriteToValve = (config, value) => {
        console.log("WriteToMFC: ", { config, value });
        const writeChannelName = config.writeChannelName;
        IOWrite(writeChannelName, value);
    }

    const handleMfcvalue = () => {
      const config = gasBoxConfiguration.current.find((obj) => {
        return obj.name === selectedMfc
      })
      if(selectedMfc=="MFC7") { setMfc7(mfcvalue); 
        if(mfcvalue>0) { IOWrite('MAIN.MFC7_Downstream.Out.On_Off_cmd', true); IOWrite('MAIN.MFC7_Upstream.Out.On_Off_cmd', true);
        } else { IOWrite('MAIN.MFC7_Downstream.Out.On_Off_cmd', false); IOWrite('MAIN.MFC7_Upstream.Out.On_Off_cmd', false);}
      } else
      if(selectedMfc=="MFC8") { setMfc8(mfcvalue) ; 
        if(mfcvalue>0) { IOWrite('MAIN.MFC8_Downstream.Out.On_Off_cmd', true); IOWrite('MAIN.MFC8_Upstream.Out.On_Off_cmd', true);          
        } else {IOWrite('MAIN.MFC8_Downstream.Out.On_Off_cmd', false); IOWrite('MAIN.MFC8_Upstream.Out.On_Off_cmd', false);  }
      } else
      if(selectedMfc=="MFC9") { setMfc9(mfcvalue); 
        if(mfcvalue>0) { IOWrite('MAIN.MFC9_Downstream.Out.On_Off_cmd', true); IOWrite('MAIN.MFC9_Upstream.Out.On_Off_cmd', true);          
        } else {IOWrite('MAIN.MFC9_Downstream.Out.On_Off_cmd', false); IOWrite('MAIN.MFC9_Upstream.Out.On_Off_cmd', false);  }
      } else
      if(selectedMfc=="MFC10") { setMfc10(mfcvalue) ; 
        if(mfcvalue>0) { IOWrite('MAIN.MFC10_Downstream.Out.On_Off_cmd', true); IOWrite('MAIN.MFC10_Upstream.Out.On_Off_cmd', true);          
        } else { IOWrite('MAIN.MFC10_Downstream.Out.On_Off_cmd', false); IOWrite('MAIN.MFC10_Upstream.Out.On_Off_cmd', false);}
      } else
      if(selectedMfc=="MFC11") { setMfc11(mfcvalue); 
        if(mfcvalue>0) { IOWrite('MAIN.MFC11_Downstream.Out.On_Off_cmd', true); IOWrite('MAIN.MFC11_Upstream.Out.On_Off_cmd', true);          
        } else { IOWrite('MAIN.MFC11_Downstream.Out.On_Off_cmd', false); IOWrite('MAIN.MFC11_Upstream.Out.On_Off_cmd', false);}
      } else
      if(selectedMfc=="MFC12") { setMfc12(mfcvalue) ; 
        if(mfcvalue>0) {  IOWrite('MAIN.MFC12_Downstream.Out.On_Off_cmd', true);  IOWrite('MAIN.MFC12_Upstream.Out.On_Off_cmd', true);          
        } else { IOWrite('MAIN.MFC12_Downstream.Out.On_Off_cmd', false); IOWrite('MAIN.MFC12_Upstream.Out.On_Off_cmd', false); }
      } 
      WriteToMFC(config, mfcvalue);
      // updateMfcWrittenState();
      setselectedMfc(null);
      setmfcvalue(null)
    }

    const handleClick = (value) => {
        debugger;
        const config = gasBoxConfiguration.current.find((obj) => {
          return obj.name === selectedValve
        });
        WriteToValve(config, value)
        setselectedValve(null)
      };
  return (<>
    
      <div className="relative">
        <div className="relative rounded-t-xl border-b-0 border-2 border-gray-400 mt-1">
          <div className="-mt-3 ml-2 mb-3 bg-gray-100 w-40 rounded-md border-gray-400 border text-center font-bold text-sm text-gray-600 py-1">GAS Controller </div>
          <div className="flex flex-col gap-2 mt-2 -ml-0.5">
            <div className="flex">
                <div className="rounded-r-lg border-2 border-gray-400 w-16 text-center font-semibold bg-gray-100 h-7 mt-0.5 text-sm">He</div>
                <div className="shadow-inner h-2 w-3 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("DV7"); setValvetitle('CF4 Down Valve 1') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${gasBoxState?.find(o => o.name === "DV7").currentValue ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input type="" class="h-6 w-12 text-center py-4 px-1 pr-0 rounded dysing-input-yellow cursor-pointer" value={mfc7} onClick={() => { setselectedMfc("MFC7"); setmfcvalue(gasBoxState?.find(o => o.name === "MFC7out").currentValue) }}/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input class="h-6 w-12 text-center py-4 dysing-input   rounded" value={gasBoxState?.find(o => o.name === "MFC7").currentValue} readonly="true"/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer"
                    onClick={() => { setselectedValve("UV7"); setValvetitle('CF4 Up Valve 1') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${gasBoxState?.find(o => o.name === "UV7").currentValue ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
            </div>
            
            <div className="flex">
                <div className="rounded-r-lg border-2 border-gray-400 w-16 text-center font-semibold bg-gray-100 h-7 mt-0.5 text-sm">C4F8</div>
                <div className="shadow-inner h-2 w-3 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("DV8"); setValvetitle('C4F8 Down Valve 2') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${gasBoxState?.find(o => o.name === "DV8").currentValue ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input type="" class="h-6 w-12 text-center py-4 px-1 pr-0  dysing-input-yellow rounded cursor-pointer" value={mfc8} onClick={() => { setselectedMfc("MFC8"); setmfcvalue(gasBoxState?.find(o => o.name === "MFC8out").currentValue) }}/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input class="h-6 w-12 text-center py-4 dysing-input rounded" value={gasBoxState?.find(o => o.name === "MFC8").currentValue} readonly="true"/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("UV8"); setValvetitle('C4F8 Up Valve 2') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${gasBoxState?.find(o => o.name === "UV8").currentValue ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
            </div>
            
            <div className="flex">
                <div className="rounded-r-lg border-2 border-gray-400 w-16 text-center font-semibold bg-gray-100 h-7 mt-0.5 text-sm">CH4</div>
                <div className="shadow-inner h-2 w-3 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("DV9"); setValvetitle('CH4 Down Valve 3') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${gasBoxState?.find(o => o.name === "DV9").currentValue ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input type="" class="h-6 w-12 text-center py-4 px-1 pr-0 rounded dysing-input-yellow cursor-pointer" value={mfc9} onClick={() => { setselectedMfc("MFC9"); setmfcvalue(gasBoxState?.find(o => o.name === "MFC9out").currentValue) }}/>
                 <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                 <input class="h-6 w-12 text-center py-4  dysing-input rounded" value={gasBoxState?.find(o => o.name === "MFC9").currentValue} readonly="true"/>
                 <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("UV9"); setValvetitle('CH4 Up Valve 3') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${gasBoxState?.find(o => o.name === "UV9").currentValue ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
            </div>
            <div className="flex">
                <div className="rounded-r-lg border-2 border-gray-400 w-16 text-center font-semibold bg-gray-100 h-7 mt-0.5 text-sm">O2</div>
                <div className="shadow-inner h-2 w-3 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("DV10"); setValvetitle('O2 Down Valve 4') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${gasBoxState?.find(o => o.name === "DV10").currentValue ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input type="" class="h-6 w-12 text-center py-4 px-1 pr-0 rounded dysing-input-yellow cursor-pointer" value={mfc10} onClick={() => { setselectedMfc("MFC10"); setmfcvalue(gasBoxState?.find(o => o.name === "MFC10out").currentValue) }}/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input class="h-6 w-12 text-center py-4  dysing-input rounded" value={gasBoxState?.find(o => o.name === "MFC10").currentValue} readonly="true"/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("UV10"); setValvetitle('O2 Up Valve 4') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${gasBoxState?.find(o => o.name === "UV10").currentValue ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
            </div>
            <div className="flex">
                <div className="rounded-r-lg border-2 border-gray-400 w-16 text-center font-semibold bg-gray-100 h-7 mt-0.5 text-sm">Ar</div>
                <div className="shadow-inner h-2 w-3 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("DV11"); setValvetitle('AR Down Valve 5') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${gasBoxState?.find(o => o.name === "DV11").currentValue ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input type="" class="h-6 w-12 text-center py-4 px-1 pr-0 rounded dysing-input-yellow cursor-pointer" value={mfc11} onClick={() => { setselectedMfc("MFC11"); setmfcvalue(gasBoxState?.find(o => o.name === "MFC11out").currentValue) }}/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input class="h-6 w-12 text-center py-4  dysing-input rounded" value={gasBoxState?.find(o => o.name === "MFC11").currentValue} readonly="true"/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("UV11"); setValvetitle('AR Up Valve 5') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${gasBoxState?.find(o => o.name === "UV11").currentValue ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
            </div>
            <div className="flex">
                <div className="rounded-r-lg border-2 border-gray-400 px-0.5 text-center font-semibold bg-gray-100 h-7 mt-0.5 text-xs pt-1">Backside He</div>
                <div className="shadow-inner h-2 w-1 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("DV12"); setValvetitle('HE Down Valve 6') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${gasBoxState?.find(o => o.name === "DV12").currentValue ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input type="" class="h-6 w-12 text-center py-4 px-1 pr-0  dysing-input-yellow rounded cursor-pointer" value={mfc12} onClick={() => { setselectedMfc("MFC12"); setmfcvalue(gasBoxState?.find(o => o.name === "MFC12out").currentValue) }}/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input class="h-6 w-12 text-center py-4 dysing-input rounded" value={gasBoxState?.find(o => o.name === "MFC12").currentValue} readonly="true"/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("UV12"); setValvetitle('HE Up Valve 6') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${gasBoxState?.find(o => o.name === "UV12").currentValue ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
            </div>
          </div>
          
        </div>
        {selectedMfc != null &&
          <div class="absolute w-full h-full bg-black bg-opacity-25 top-0 left-0">
            <div className='mx-auto w-2/3 bg-white  mt-36 relative shadow-2xl rounded-xl'>
              <button className='bg-red-500 px-2 text-white absolute -right-3 -top-3 rounded-full' onClick={() => { setselectedMfc(null); setmfcvalue(null) }}>x</button>
              {/* <div className='text-base font-bold'>Change Value of {selectedMfc}</div> */}

              {/* <input type='number' value={mfcvalue ? mfcvalue : ''} onChange={(e) => setmfcvalue(e.target.value)} className='p-2 text-lg border bg-gray-100 w-full mt-5' placeholder='change value' /> */}
              <div className="flex mt-2 rounded-xl overflow-hidden">
                <input type='number' value={mfcvalue ? mfcvalue : ''} onChange={(e) => setmfcvalue(e.target.value)} className='p-2 text-sm border bg-gray-100 w-full font-bold flex-1' placeholder='change value' />
                <button className='bg-gray-600 rounded hover:bg-gray-400 text-white px-3 py-1 text-xs font-bold' onClick={() => handleMfcvalue()}>Update</button>
              </div>
              
              
            </div>
          </div>
        }
            {selectedValve != null &&
          <div class="absolute w-full h-full bg-black bg-opacity-25 top-0 left-0">
            <div className='mx-5 bg-white p-4 mt-16 relative shadow-2xl rounded-lg'>
              <div className='text-base font-medium'>{gasBoxState?.find(o => o.name === selectedValve).currentValue === true ? 'CLOSE ' : 'OPEN '} <span className="font-bold">{Valvetitle}</span> ?</div>
              <div className='flex justify-between gap-3'>
                {gasBoxState?.find(o => o.name === selectedValve).currentValue === true ?
                  <button className='bg-white-500 shadow-md hover:bg-gray-300 border border-b text-black px-3 py-1 mt-3 rounded font-bold flex-1' onClick={() => { handleClick(false); }}>Yes</button>
                  :
                  <button className='bg-white-500 shadow-md hover:bg-gray-300 border border-b text-black px-3 py-1 mt-3 rounded  font-bold flex-1' onClick={() => handleClick(true)}>Yes</button>
                }
                <button className='bg-gray-600 text-white px-3 py-2 mt-3 rounded flex-1' onClick={() => setselectedValve(null)}>No</button>
              </div>


              {/* <input type='number' value={mfcvalue? mfcvalue:''} onChange={(e)=>setmfcvalue(e.target.value)} className='p-2 text-lg border bg-gray-100 w-full mt-5' placeholder='change value' /> */}
              {/* <button className='bg-orange-500 text-white px-3 py-2 mt-3 rounded'  onClick={()=> handleMfcvalue()}>Update</button> */}
            </div>
          </div>
        }
    </div>


  </>);
}