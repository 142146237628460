import * as React from 'react';
import ReactDOM from 'react-dom';
import { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';

import Canvas from './components/canvas/Canvas';
import Motor from './components/diagrams/Motor';
import Motor2 from './components/diagrams/Motor2';
import DicingMotor from './components/diagrams/DicingMotor';
export default function App() {
  
  // let navigate = useNavigate();
  useEffect(()=>{
    
   
  }, [ ]);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Canvas />} />
        <Route path="/gasboxV2" element={<Canvas  diagram='gasboxv2'/>} />
        <Route path="/gasBoxdicingrie" element={<Canvas  diagram='GasBox_DicingRIE'/>} />
        <Route path="/iec" element={<Canvas  diagram='iec'/>} />
        <Route path="/iecChamber" element={<Canvas  diagram='iecChamber'/>} />
        <Route path="/iecChamberDtdl" element={<Canvas  diagram='iecChamberDtdl'/>} />
        <Route path="/DicingRIEChamber" element={<Canvas  diagram='DicingRIEChamber'/>} />
        <Route path="/DicingRIEChamberDTDL" element={<Canvas  diagram='DicingRIEChamberDTDL'/>} />
        <Route path="/DicingRIEChamberold" element={<Canvas  diagram='DicingRIEChamberold'/>} />
        <Route path="/motor" element={<Motor  diagram='motor'/>} />
        <Route path="/motor2" element={<Motor2  diagram='Motor2'/>} />
        <Route path="/dicingMotor" element={<DicingMotor  diagram='DicingMotor'/>} />
      </Routes>
    </BrowserRouter>
  );
}
