export async function getAllSequences() {
    const url =
    process.env.REACT_APP_VIA_RULE_ENGINE_API +"/api/Sequence";
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const json = await res.json();
      if (json.errors) {
        console.error(json.errors);
        throw new Error("Failed to fetch API");
      }
      return json;
    } catch (error) {
      // throw new Error("Failed to fetch API");
      return error;
    }
  }


export async function getAllRecipes() {
    const url =
    process.env.REACT_APP_VIA_RULE_ENGINE_API +"/api/Recipes";
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const json = await res.json();
      if (json.errors) {
        console.error(json.errors);
        throw new Error("Failed to fetch API");
      }
      return json;
    } catch (error) {
      // throw new Error("Failed to fetch API");
      return error;
    }
  }

  export async function getAllRecipesByChamber(chamberId) {
    const url =
    process.env.REACT_APP_VIA_RULE_ENGINE_API +"/api/Recipes/ByChamber/"+chamberId;
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const json = await res.json();
      if (json.errors) {
        console.error(json.errors);
        throw new Error("Failed to fetch API");
      }
      return json;
    } catch (error) {
      // throw new Error("Failed to fetch API");
      return error;
    }
  }
  
  export async function getAllChambers() {
    const url =
    process.env.REACT_APP_VIA_RULE_ENGINE_API +"/api/Chamber";
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const json = await res.json();
      if (json.errors) {
        console.error(json.errors);
        throw new Error("Failed to fetch API");
      }
      return json;
    } catch (error) {
      // throw new Error("Failed to fetch API");
      return error;
    }
  }

