import React, { useEffect, useRef, useState, useReducer } from "react";
import { ChannelService, ChannelSubscriptionService } from "@getvia/sdk";

import { getAllChambers, getAllRecipesByChamber, getAllSequences } from "../../api";

import DicingBiasPoverModel from "./dicing/dicingBiasPoverModel";
import IsotopicGasbox from "./iec/isotopicGasbox";
import IsotopicETCHChamber from "./iec/isotopicETCHChamber";
import IsotopicMoterModel from "./iec/isotopicMoterModel";
import IsotopicPressureMode from "./iec/isotopicPressureMode";

const viaConfig = {
  channelServiceOptions: {
    apiToken: "test",
    baseUrl: process.env.REACT_APP_VIA_CORE_API,
    wsUrl: process.env.REACT_APP_VIA_NOTIFICATION_HUB
  },
};
const defaultGasBoxConfiguration = [
 
  {
    object: "ISOChmbrPrGauge",
    name: "ISOChmbrPrGauge",
    feedBackChannel: "MAIN.ISOChmbrPrGauge.In.Sensor_Value_Module_1",
    currentValue: false,
  },
  {
    object: "ISOChmbrBr10TorrGauge",
    name: "ISOChmbrBr10TorrGauge",
    feedBackChannel: "MAIN.ISOChmbrBr10TorrGauge.In.Sensor_Value_Module_1",
    currentValue: false,
  },
  {
    object: "ISOPiraniGuage",
    name: "ISOPiraniGuage",
    feedBackChannel: "MAIN.ISOPiraniGuage.In.Sensor_Value",
    currentValue: false,
  }, 
  {
    object: "ISOChmbrBrPiraniGauge",
    name: "ISOChmbrBrPiraniGauge",
    feedBackChannel: "MAIN.ISOChmbrBrPiraniGauge.In.Sensor_Value_Module_1",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm1",
    feedBackChannel: "MAIN.Foreline_Pmp.In.Alarm_fbk",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm2",
    feedBackChannel: "MFC1_SP_Greaterthan_FullScale",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm3",
    feedBackChannel: "MFC2_SP_Greaterthan_FullScale",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm4",
    feedBackChannel: "MFC3_SP_Greaterthan_FullScale",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm5",
    feedBackChannel: "MFC4_SP_Greaterthan_FullScale",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm",
    feedBackChannel: "Global",
    currentValue: false,
  },
  {
    object: "MFC_SEQ_Cmd",
    name: "MFC_SEQ_Cmd",
    feedBackChannel: "MFC_SEQ_Cmd",
    currentValue: null,
  },
  {
    object: "MFC_SEQ_Name",
    name: "MFC_SEQ_Name",
    feedBackChannel: "MFC_SEQ_Name",
    currentValue: null,
  },
  {
    object: "PM1",
    name: "PM1.WaferID",
    feedBackChannel: "PM1.WaferID",
    currentValue: "",
  },
  {
    object: "Sequence",
    name: "ISOEtchRecipeSequence_StepNum",
    feedBackChannel: "ISOEtchRecipeSequence_StepNum",
    currentValue: null,
  },
  {
    object: "Sequence",
    name: "ISOEtchRecipeSequence_StepTime",
    feedBackChannel: "ISOEtchRecipeSequence_StepTime",
    currentValue: null,
  },
  {
    object: "RPSPowerSupply",
    name: "RPSPowerSupply.In.Actual_Forward_Power",
    feedBackChannel: "MAIN.DicingChamberSource_Power.In.Actual_Forward_Power", //NOT CORRECT
    currentValue: null,
  },
  {
    object: "RPSPowerSupply",
    name: "RPSPowerSupply.In.Actual_Reflected_Power",
    feedBackChannel: "MAIN.DicingChamberSource_Power.In.Actual_Reflected_Power",//NOT CORRECT
    currentValue: null,
  },
  {
    object: "ISOBias_Power",
    name: "Bias_Power.In.Actual_Forward_Power",
    feedBackChannel: "MAIN.ISOBias_Power.In.Actual_Forward_Power",
    currentValue: null,
  },
  {
    object: "ISOBias_Power",
    name: "Bias_Power.In.Actual_Reflected_Power",
    feedBackChannel: "MAIN.ISOBias_Power.In.Actual_Reflected_Power",
    currentValue: null,
  },
  {
    object: "RPSPowerSupply",
    name: "RPSPowerSupply",
    feedBackChannel: "MAIN.RPSPowerSupply.In.Actual_Setpoint",
    writeChannelName: "MAIN.RPSPowerSupply.Out.Setpoint",
    currentValue: 1,
  },
  {
    object: 'ISOThrottleValve',
    name: 'ISOThrottleValveControlMode',
    readChannelName: 'MAIN.ISOThrottleValve.In.Control_Mode', //to be changed to in channel
    writeChannelName: 'MAIN.ISOThrottleValve.Out.Control_ModeThrottleValve.Out.Control_Mode',
    feedBackChannel: 'MAIN.ISOThrottleValve.In.Control_Mode', //to be changed to in channel
    currentValue: null
  },
  {
    object: 'ISOThrottleValve',
    name: 'ISOThrottleValve',
    readChannelName: 'MAIN.ISOThrottleValve.In.Actual_Pressure', //to be changed to in channel
    writeChannelName: 'MAIN.ISOThrottleValve.Out.Target_Pressure',
    feedBackChannel: 'MAIN.ISOThrottleValve.In.Actual_Pressure', //to be changed to in channel
    currentValue: null
  },
  {
    object: 'ISOThrottleValve',
    name: 'ISOThrottleValveActual_Position',
    readChannelName: 'MAIN.ISOThrottleValve.In.Actual_Position', //to be changed to in channel
    writeChannelName: 'MAIN.ISOThrottleValve.Out.Target_Position',
    feedBackChannel: 'MAIN.ISOThrottleValve.In.Actual_Position', //to be changed to in channel
    currentValue: null
  },
  {
    object: 'RPSPowerSupply',
    name: 'RPSPowerSupply',
    readChannelName: 'MAIN.RPSPowerSupply.In.RF_ON_Indication', 
    writeChannelName: 'MAIN.RPSPowerSupply.Out.RF_ON_Request',
    feedBackChannel: 'MAIN.RPSPowerSupply.In.RF_ON_Indication',
    currentValue: false
  },
  {
    object: 'RPSPowerSupply',
    name: 'RPSPowerSupplySetpoint',
    readChannelName: 'MAIN.RPSPowerSupply.Out.Setpoint', 
    writeChannelName: 'MAIN.RPSPowerSupply.Out.Setpoint',
    feedBackChannel: 'MAIN.RPSPowerSupply.Out.Setpoint', 
    currentValue: null
  },
  {
    object: 'ISOBias_Power',
    name: 'ISOBias_Power',
    readChannelName: 'MAIN.ISOBias_Power.In.RF_ON_Indication',
    writeChannelName: 'MAIN.ISOBias_Power.Out.RF_ON_Request',
    feedBackChannel: 'MAIN.ISOBias_Power.In.RF_ON_Indication', 
    currentValue: null
  },
  {
    object: 'ISOBias_Power',
    name: 'ISOBias_PowerSetpoint',
    readChannelName: 'MAIN.ISOBias_Power.Out.Setpoint', 
    // readChannelName: 'MAIN.DicingChamberBias_Power.In.Actual_Setpoint', 
    writeChannelName: 'MAIN.ISOBias_Power.Out.Setpoint',
    // feedBackChannel: 'MAIN.DicingChamberBias_Power.In.Actual_Setpoint', 
    feedBackChannel: 'MAIN.ISOBias_Power.Out.Setpoint', 
    currentValue: null
  },
  {
    object: 'Sequence',
    name: 'Sequence',
    readChannelName: 'Status_ISOEtchRecipeSequence', //to be changed to in channel
    writeChannelName: 'Status_ISOEtchRecipeSequence',
    feedBackChannel: 'Status_ISOEtchRecipeSequence', //to be changed to in channel
    currentValue: null
  },
  {
    object: 'ISOSlit_Valve',
    name: 'ISOSlit_ValveClose',
    readChannelName: 'MAIN.ISOSlit_Valve.Out.Slit_Close', //to be changed to in channel
    writeChannelName: 'MAIN.ISOSlit_Valve.Out.Slit_Close',
    feedBackChannel: 'MAIN.ISOSlit_Valve.Out.Slit_Close', //to be changed to in channel
    currentValue: null
  },
  {
    object: 'ISOSlit_Valve',
    name: 'ISOSlit_ValveOpen',
    readChannelName: 'MAIN.ISOSlit_Valve.Out.Slit_Open', //to be changed to in channel
    writeChannelName: 'MAIN.ISOSlit_Valve.Out.Slit_Open',
    feedBackChannel: 'MAIN.ISOSlit_Valve.Out.Slit_Open', //to be changed to in channel
    currentValue: null
  },
  // {
  //   object: 'DicingChamberManoIsolation_Valve',
  //   name: 'DicingChamberManoIsolation_Valve',
  //   readChannelName: 'MAIN.DicingChamberManoIsolation_Valve.Out.Open_cmd', //to be changed to in channel
  //   writeChannelName: 'MAIN.DicingChamberManoIsolation_Valve.Out.Open_cmd',
  //   feedBackChannel: 'MAIN.DicingChamberManoIsolation_Valve.Out.Open_cmd', //to be changed to in channel
  //   currentValue: null
  // },
  {
    object: 'ISON2VentValve',
    name: 'ISON2VentValve',
    readChannelName: 'MAIN.ISON2VentValve.In.Open_fbk', //to be changed to in channel
    writeChannelName: 'MAIN.ISON2VentValve.Out.Open_cmd',
    feedBackChannel: 'MAIN.ISON2VentValve.In.Open_fbk', //to be changed to in channel
    currentValue: null
  },
];
const channelService = new ChannelService(viaConfig.channelServiceOptions);
const channelSubscriptionService = new ChannelSubscriptionService(viaConfig.channelServiceOptions);


export default function IsotopicEtchChamberNew() {
  const gasBoxConfiguration = useRef(defaultGasBoxConfiguration);
  const channels = useRef();
  const subscriptionRef = useRef();
  const [gasBoxState, setGasBoxState] = useState();

 const [motorscreen,setMotorscreen] = useState(false)
 const [modescreen,setModescreen] = useState(false)
 const [sourcescreen,setSourcescreen] = useState(false)
 const [biasscreen,setBiasscreen] = useState(false)

  const [time, settime] = useState(null);
  const [recipes, setRecipes] = useState([]);
  const [selectedRecipe, setSelectedRecipe] = useState({});
  const [sequences, setSequences] = useState([]);
  const [loopTestSequences, setLoopTestSequences] = useState([]);
  const [selectedSequence, setSelectedSequence] = useState({});
  const [instanceId, setInstanceId] = useState("");
  const [runningStatus, setRunningStatus] = useState("");
  const [currLoop, setCurrLoop] = useState(0);

  
  const [forwardModel, setForwardModel] = useState(null);
  const [forwardvalue, setForwardvalue] = useState(null);

  let execIntervalId = 0;
  const fetchChannelsStates = async () => {
    const response = await channelService.ChannelStates();
    console.log("ChannelStates: ", { response });
    if (response) {
        channels.current = response;
        updateGasBoxState(channels.current);
    }
    };
const updateGasBoxState = (updatedChannels) => {
    let index = -1
    let gasBoxConfigurationState = gasBoxConfiguration.current;
    updatedChannels.forEach((item) => {
        const config = gasBoxConfigurationState.find((obj, objIndex) => {
        index = objIndex;
        return obj.feedBackChannel === item.channelName
        })
        if (config) {
        config.currentValue = item.data.value;
        gasBoxConfigurationState[index] = config;
        }
    })
    // setAlarm();
    setGasBoxState(() => gasBoxConfigurationState);
}
const subscribeToNotification = () => {

    const onNewMessage = (message) => {
        const channel = JSON.parse(message);
        updateGasBoxState([channel])
    };

    let channelSubscriptions = [];

    gasBoxConfiguration.current.forEach(config => {
        channelSubscriptions.push(config.feedBackChannel);
    })
    const subscription = {
        channels: channelSubscriptions,
        onNewMessage: onNewMessage,
    };

    subscriptionRef.current = subscription;
    channelSubscriptionService.subscribe(subscription);
};
const unSubscribe = () => {
    channelSubscriptionService.unSubscribe(subscriptionRef.current);
};
const IOWrite = (channelName, value) => {
  console.log("Channels: ", { channels });
  console.log("IOWrite: ", { channelName, value });
  if (channelName) {
    const channel = channels.current?.find(obj => {
      return obj.channelName === channelName
    })
    if (channel) {
      channel.data.value = value;
      channelService.Write(channel).then(() => {
      });
    }
  }
}
const getRunningStatusTestExec = async (currInstanceId) => {
  await fetch(
    process.env.REACT_APP_VIA_RULE_ORCHES_API +
      "/api/Sequence/" +
      currInstanceId,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers as needed
      },
    }
  )
    .then((response) => {
      // Check if the request was successful
      if (!response.ok) {
        throw new Error("Could not get execution status.");
      }
      // Parse the response as JSON
      return response.json();
    })
    .then((data) => {
      // Handle the JSON data
      let runStatus = data.runtimeStatus;

      setRunningStatus(() => runStatus);
      if (
        runStatus == "TERMINATED" ||
        runStatus == "COMPLETED" ||
        runStatus == "FAILED"
      ) {
        clearInterval(execIntervalId);
        execIntervalId = 0;
        setInstanceId(()=>'');
        localStorage.removeItem("isoEtchChbrLoopInstId")
      }
    })
    .catch((error) => {
      // Handle any errors that occurred during the fetch
      console.error("Fetch error get running status:", error);
    });
};


useEffect(() => {
    const fetChannelData = async () => {
        await fetchChannelsStates();
        subscribeToNotification();
    };
    fetChannelData();
    return () => {
        unSubscribe();
    };
}, []);


  useEffect(() => {
    const interval = setInterval(() => {
      settime(new Date())
    }, 1000);
  
    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const getRecipes = async () => {
    const chambers = await getAllChambers()
    if(chambers){
      const chamber = chambers?.filter((x)=> x.chamberName == 'Isotropic Etch Chamber')[0]
      const recipes = await getAllRecipesByChamber(chamber.id);
      if (recipes) {
        setRecipes(recipes.DATA);
      }
    }
  };
  const getSequencelist = async () => {
    const sequences = await getAllSequences();
    console.log('iso sequence',sequences);
    if(sequences && sequences.length>0){
      const filteredByRecipe = sequences.filter(sequence => sequence.sequenceType === "RecipeSequence"); 
      setSequences(filteredByRecipe)
      const selected = sequences.filter((sequence) => sequence.sequenceName === "ISOEtchRecipeSequence");
        setSelectedSequence(selected[0]);
      const filteredByLoopTest = sequences.filter(sequence => sequence.sequenceType === "LoopSequence"); 
      setLoopTestSequences(filteredByLoopTest)
    }
  }
  const fetchRunningStatus = ()=>{
    const currInstanceId = localStorage.getItem('isoEtchChbrLoopInstId');
    const selStoredDicingRecipe = JSON.parse(localStorage.getItem('selStoredRecipe'));
    if(selStoredDicingRecipe && selStoredDicingRecipe.id)
      setSelectedRecipe(()=> selStoredDicingRecipe)
    if(currInstanceId && currInstanceId!=""){
      setInstanceId(()=> currInstanceId)      
        // execIntervalId = setInterval(()=>{
        //   getRunningStatusTestExec(currInstanceId);
        // },1000);
      }
  }
  const executeRecipe = async () => {debugger
    if(!selectedRecipe || !selectedRecipe.id){
      return;
    }
    var formdata = {
      token: "",
      ruleType: "Sequence",
      ruleGroups: [],
      ruleSubGroups: [],
      rule: "",
      sequence: selectedSequence?.sequenceName,
      inputData: {
        RecipeId: selectedRecipe.id,
      },
    };
    await fetch(
      process.env.REACT_APP_VIA_RULE_ORCHES_API + "/api/Rules/ExecuteRule",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers as needed
        },
        body: JSON.stringify(formdata),
      }
    )
      .then((response) => {
        // Check if the request was successful
        if (!response.ok) {
          throw new Error("Could not start execution.");
        }
        // Parse the response as JSON
        return response.json();
      })
      .then((data) => {
        // Handle the JSON data
        let currInstanceId = data[0].resultValue;
        setInstanceId(() => currInstanceId);
        localStorage.setItem('isoEtchChbrLoopInstId',currInstanceId);
        localStorage.setItem('selStoredRecipe',JSON.stringify(selectedRecipe));
        // execIntervalId = setInterval(() => {
        //   getRunningStatusTestExec(currInstanceId);
        // }, 1000);
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        console.error("Fetch error start execution:", error);
      });
  };
  const stopTestExec = async (e) => {
    await fetch(
      process.env.REACT_APP_VIA_RULE_ORCHES_API +
        "/api/Sequence/terminate?instanceId=" +
        instanceId,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers as needed
        },
      }
  ).then(response => {
    // Check if the request was successful
    if (!response.ok) {
      throw new Error('Could not stop execution.');
    }
    // Parse the response as JSON
    return response.json();
  })
  .then(data => {
    // Handle the JSON data
    if(data.statusCode==200){
      closingRecipe();
      localStorage.removeItem('isoEtchChbrLoopInstId')
      clearInterval(execIntervalId)
    } else{
    }
    
  })
  .catch(error => {
    // Handle any errors that occurred during the fetch
    console.error('Fetch error in stop execution:', error);
  });
    };
    const closingRecipe = async (e) => {
      var formdata = {
        token: "",
        ruleType: "Sequence",
        ruleGroups: [],
        ruleSubGroups: [],
        rule: "",
        sequence: "ISOEtchClosingSequence",
        inputData: {},
      };
      // Convert data to JSON string
      try {
        const response = await fetch(
          process.env.REACT_APP_VIA_RULE_ORCHES_API + "/api/Rules/ExecuteRule",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // Add any other headers as needed
            },
            body: JSON.stringify(formdata),
          }
        );
  
        if (response.ok) {
          // Handle success
          console.log("Closing Recipe Ran!");
          IOWrite('Status_ISOEtchRecipeSequence', 'TERMINATED');
        } else {
          // Handle error
          console.error("Failed to post data in closing recipe.");
        }
      } catch (error) {
        console.error("Error in closing recipe:", error);
      }
    };

    const handleRecipe = (e) => {
      const val = e.target.value;debugger
      if (val) {
        const selected = recipes.filter((recipe) => recipe.id == e.target.value);
        if(selected[0] && selected[0].id){
          setSelectedRecipe(selected[0]);
          localStorage.setItem('selStoredRecipe',JSON.stringify(selected[0]));
        }else{
          setSelectedRecipe({});
        }
      } else {
        setSelectedRecipe({});
      }
    };

const handleforward = () => {
  if(forwardModel=='source') {
    IOWrite('MAIN.RPSPowerSupply.Out.Setpoint', forwardvalue);    
  } else if(forwardModel=='bias') {
    IOWrite('MAIN.ISOBias_Power.Out.Setpoint', forwardvalue);   
  }
  setForwardModel(null)
}
const SourcemodeFunction = async (e) => {
  let val = !e;
  IOWrite('MAIN.RPSPowerSupply.Out.RF_ON_Request', val);
  // setMode(val)
  console.log(val)
 }
const BiasmodeFunction = async (e) => {
  let val = !e;
  IOWrite('MAIN.ISOBias_Power.Out.RF_ON_Request', val);
  // setMode(val)
  console.log(val)
 }
  useEffect(() => {
    getSequencelist();
    getRecipes();
    fetchRunningStatus();
  }, []);
  return (<>
    <div className="flex gap-2 w-full h-full p-1 dysingcontainer-bg">
      <div className="flex flex-1 bg-blue-100 p-3 rounded-md">{time?.toString}
        
        <div className="w-80">
          <div className="relative bg-gray-100 rounded-md border border-gray-300 px-4 mt-4">
            <div className="-mt-3  mb-3 bg-gray-100 w-40 rounded-md border-gray-400 border text-center font-bold  text-sm text-gray-600 py-1">Process Monitor</div>
            <div className="relative w-full h-6 -mt-4">
              <div className="float-right w-10 bg-green-500 h-3 absolute right-1 rounded-full"></div>
            </div>
            <div className="grid grid-cols-3 gap-2 text-xs  font-semibold">
              <div class="bg-blue-200 p-2 flex-1 rounded- dysing-input">{gasBoxState?.find(o => o.name === "PM1.WaferID").currentValue }</div>
              <div class="bg-blue-200 p-2 flex-1 rounded-sm  dysing-input ">Step : {gasBoxState?.find(o => o.name === "ISOEtchRecipeSequence_StepNum").currentValue }</div>
              <div class="bg-blue-200 p-2 flex-1 rounded-sm  dysing-input ">{gasBoxState?.find(o => o.name === "ISOEtchRecipeSequence_StepTime").currentValue } Sec</div>
            </div>
            <div className="mb-4">
              <div class=" flex gap-2 mt-4 text-sm">Process Step</div>
              <div className="flex gap-3 mt-2">
                <select className="text-sm flex-1 bg-gray-200 px-2 py-1  dysing-bg-gray rounded font-semibold text-gray-600"
                 onChange={handleRecipe} >
                  <option>Select Recipe</option>
                    {recipes && recipes.length>0 && 
                      recipes.map((recipe, i) => 
                      <option key={recipe.id+i+"recipe-list"} value={recipe.id}>{recipe.recipeName}</option>
                    )}
                </select>
                {/* {!instanceId ? (   */}
                  
                {gasBoxState?.find(o => o.name === "Sequence").currentValue!='RUNNING' ? (  
                  <button className=" dysing-btn-bg py-1 shadow-md rounded hover:shadow-none w-20"  
                    onClick={() => executeRecipe()}>
                    RUN
                  </button>
                ) : 
                <button
                  onClick={() => stopTestExec()}
                  className="dysing-btn-bg py-1 shadow-md rounded hover:shadow-none w-20"
                >
                  Abort
                </button>
                
                }
              </div>
              {/* <div className="mt-3">
               {gasBoxState?.find(o => o.name === "Sequence").currentValue!='RUNNING' ?  
                <span>Completed recipe: {selectedRecipe.recipeName}</span> 
              :
                <span>Executing recipe: {selectedRecipe.recipeName}</span>
              }
              </div> */}
            </div>

          </div>
          
          {/* <button className=" dysing-btn-bg py-1 shadow-md rounded hover:shadow-none w-20 mt-3" onClick={() => setMotorscreen(!motorscreen)}>Motor</button> */}
        </div>
        <div className="flex-1 text-center px-1">
          
          <IsotopicETCHChamber gasBoxState={gasBoxState} IOWrite={IOWrite} setMotorscreen={setMotorscreen} setModescreen={setModescreen}/>
        </div>
        <div className="">
          <IsotopicGasbox />

          <div className="relative bg-gray-100 rounded-md border border-gray-300 px-4 mt-5">
            <div className="-mt-3  mb-3 bg-gray-100 w-40 rounded-md border-gray-400 border text-center font-bold  text-sm text-gray-600 py-1" 
            onClick={() => setSourcescreen(!sourcescreen)}
            >RPS Power</div>
            {/* RPS POWER SUPLY */}
            <div className="justify-end flex -mt-10 mb-5">
              <div className="flex p-1 bg-gray-100 border-gray-400 border rounded-md  hover:scale-105 active:scale-90 transition-all active:bg-white hover:bg-gray-200 cursor-pointer shadow-md"
              onClick={()=>SourcemodeFunction(gasBoxState?.find(o => o.writeChannelName === "MAIN.RPSPowerSupply.Out.RF_ON_Request").currentValue)}>  
                <div className="px-2 text-sm font-semibold">{gasBoxState?.find(o => o.writeChannelName === "MAIN.RPSPowerSupply.Out.RF_ON_Request").currentValue==true ? "ON" : "OFF"}</div>
                <div className={`h-5 w-5 rounded-full ${gasBoxState?.find(o => o.writeChannelName === "MAIN.RPSPowerSupply.Out.RF_ON_Request").currentValue==true ? "bg-flip-label-active" : "bg-gray-500"}`}></div>
              </div>
             </div>
            <div className="grid grid-cols-2 mb-3 gap-2  font-bold text-gray-600 relative">
              <div className="flex col-span-2">                
                <div class="gap-2 mt-1  w-20">Set FWD</div>
                <div class="bg-blue-200 p-1 px-2 flex-1 rounded-md dysing-input-yellow">
                  <div class="relative z-20 cursor-pointer" onClick={()=>{setForwardModel('source');setForwardvalue(gasBoxState?.find(o => o.name === "RPSPowerSupplySetpoint").currentValue )}}>	
                    {gasBoxState?.find(o => o.name === "RPSPowerSupplySetpoint").currentValue }
                  </div>
                </div>
              </div>
              
              <div className="flex col-span-2">                
                <div class="gap-2 mt-1 w-20">RFL</div>
                <div class="bg-blue-200 p-1 px-2 flex-1 rounded-md dysing-input ">
                  <div class="relative z-20">{gasBoxState?.find(o => o.name === "RPSPowerSupply.In.Actual_Reflected_Power").currentValue}</div>
                </div>
              </div>
              <div className="flex">                
                <div class="gap-2 mt-1 w-20">C1</div>
                <div class="bg-blue-200 p-1 px-2 flex-1 rounded-md dysing-input ">
                  <div class="relative z-20">0</div>
                </div>
              </div>

              <div className="flex">                
                <div class="gap-2 mt-1 w-20 text-center">C2</div>
                <div class="bg-blue-200 p-1 px-2 flex-1 rounded-md dysing-input ">
                  <div class="relative z-20 ">0</div>
                </div>
              </div>
              {forwardModel == 'source' &&
                  <div class="absolute w-full h-full bg-black bg-opacity-25 z-20 top-0 left-0">
                    <div className='mx-auto w-2/3 bg-white relative shadow-2xl rounded-xl'>
                      <button className='bg-red-500 px-2 text-white absolute -right-3 -top-3 rounded-full' 
                      onClick={() => { setForwardModel(null); setForwardvalue(null) }}>x</button>
                     <div className="flex mt-2 rounded-xl overflow-hidden">
                        <input type='number' value={forwardvalue} onChange={(e) => setForwardvalue(e.target.value)} 
                        className='p-2 text-sm border bg-gray-100 w-full font-bold flex-1' placeholder='change value' />
                        <button className='bg-gray-600 rounded hover:bg-gray-400 text-white px-3 py-1 text-xs font-bold' 
                        onClick={() => handleforward()}>Update</button>
                      </div>
                    </div>
                  </div>
              }
            </div>
          </div>
          <div className="relative bg-gray-100 rounded-md border border-gray-300 px-4 mt-5">
            <div className="-mt-3  mb-3 bg-gray-100 w-40 rounded-md border-gray-400 border text-center font-bold  text-sm text-gray-600 py-1"  
            // onClick={() => setBiasscreen(!biasscreen)}
            >Bias Power</div>
            <div className="justify-end flex -mt-10 mb-5">
              <div className="flex p-1 bg-gray-100 border-gray-400 border rounded-md  hover:scale-105 active:scale-90 transition-all active:bg-white hover:bg-gray-200 cursor-pointer shadow-md"
              onClick={()=>BiasmodeFunction(gasBoxState?.find(o => o.writeChannelName === "MAIN.ISOBias_Power.Out.RF_ON_Request").currentValue)}>  
                <div className="px-2 text-sm font-semibold">{gasBoxState?.find(o => o.writeChannelName === "MAIN.ISOBias_Power.Out.RF_ON_Request").currentValue==true ? "ON" : "OFF"}</div>
                <div className={`h-5 w-5 rounded-full ${gasBoxState?.find(o => o.writeChannelName === "MAIN.ISOBias_Power.Out.RF_ON_Request").currentValue==true ? "bg-flip-label-active" : "bg-gray-500"}`}></div>
              </div>
             </div>
            <div className="grid grid-cols-2 mb-3 gap-2 font-bold text-gray-600 relative">
              <div className="flex col-span-2">                
                <div class="gap-2 mt-1 w-20">Set FWD</div>
                <div class="bg-blue-200 p-1 px-2 flex-1 rounded-md dysing-input-yellow ">
                  <div class="relative z-20"  onClick={()=>{setForwardModel('bias');setForwardvalue(gasBoxState?.find(o => o.name === "ISOBias_PowerSetpoint").currentValue )}}>
                    {gasBoxState?.find(o => o.name === "ISOBias_PowerSetpoint").currentValue }</div>
                </div>
              </div>
              
              <div className="flex col-span-2">                
                <div class="gap-2 mt-1 w-20">RFL</div>
                <div class="bg-blue-200 p-1 px-2 flex-1 rounded-md dysing-input ">
                  <div class="relative z-20">{gasBoxState?.find(o => o.name === "Bias_Power.In.Actual_Reflected_Power").currentValue}</div>
                </div>
              </div>
              <div className="flex">                
                <div class="gap-2 mt-1 w-20">C1</div>
                <div class="bg-blue-200 p-1 px-2 flex-1 rounded-md dysing-input ">
                  <div class="relative z-20 ">0</div>
                </div>
              </div>
              <div className="flex">                
                <div class="gap-2 mt-1 w-20 text-center">C2</div>
                <div class="bg-blue-200 p-1 px-2 flex-1 rounded-md dysing-input ">
                  <div class="relative z-20 ">0</div>
                </div>
              </div>
              {forwardModel == 'bias' &&
                  <div class="absolute w-full h-full bg-black bg-opacity-25 z-20 top-0 left-0">
                    <div className='mx-auto w-2/3 bg-white relative shadow-2xl rounded-xl'>
                      <button className='bg-red-500 px-2 text-white absolute -right-3 -top-3 rounded-full' 
                      onClick={() => { setForwardModel(null); setForwardvalue(null) }}>x</button>
                     <div className="flex mt-2 rounded-xl overflow-hidden">
                        <input type='number' value={forwardvalue} onChange={(e) => setForwardvalue(e.target.value)} 
                        className='p-2 text-sm border bg-gray-100 w-full font-bold flex-1' placeholder='change value' />
                        <button className='bg-gray-600 rounded hover:bg-gray-400 text-white px-3 py-1 text-xs font-bold' 
                        onClick={() => handleforward()}>Update</button>
                      </div>
                    </div>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="dysing-bg pt-3 px-2 rounded-md w-28"> 
        <button className="border-4 border-gray-100 dysing-input-green px-2 py-6 text-xs font-bold w-full rounded-lg">ONLINE</button>
        <div className="mt-6 text-xs font-bold text-center">Commands</div>
        <div className=" ">
          <button className=" diceembossed-btn  py-3  !w-full mt-5 text-xs font-bold leading-8 rounded-lg !text-gray-600">PURGE</button>
          <button className="diceembossed-btn  py-3  !w-full mt-5 text-sm font-bold leading-8 rounded-lg !text-gray-600">WATER</button>
          <button className="diceembossed-btn  py-3  !w-full mt-5 text-xs font-bold leading-8 rounded-lg !text-gray-600">LEAK RATE</button>
          <button className="diceembossed-btn  py-3  !w-full mt-5 text-xs font-bold leading-8 rounded-lg !text-gray-600">PUMPDOWN</button>
          <button className="diceembossed-btn  py-3 px-2 !w-full !h-16 !pt-3 mt-5 text-sm font-bold leading-5 rounded-lg !text-gray-600">CYCLE PURGE</button>
          <button className="diceembossed-btn  py-3  !w-full mt-5 text-xs font-bold leading-8 rounded-lg !text-gray-600">VENT</button>
          <button className="diceembossed-btn  py-3  !w-full mt-5 text-xs font-bold leading-8 rounded-lg !text-gray-600" >STOP</button>          
        </div>

      </div>
    </div>
   {motorscreen&& 
    <div className="bg-black bg-opacity-10 absolute top-0 left-0 w-full h-full overflow-hidden z-30" >
      <div className="absolute h-full w-full top-1 left-1 z-0 " onClick={() => setMotorscreen(!motorscreen)}></div>
      <div className="mx-auto w-80 dysing-motormodel p-5 mt-4 relative z-2">
        <div className="-mt-7 mb-3 flex">
          <div className="flex-1">
            <div className="  bg-gray-100 w-40 rounded-md border-gray-400 border text-center font-bold  text-sm text-gray-600 py-1">Motor</div>
          </div>
          <div>
            <button className="bg-gray-100 rounded-md border-gray-400 border px-1.5 text-gray-500 hover:bg-red-500 transition-all hover:text-white" onClick={() => setMotorscreen(!motorscreen)}>✖</button>
          </div>
        </div>     
        <IsotopicMoterModel gasBoxState={gasBoxState} IOWrite={IOWrite}/>        
      </div>
    </div>}
    {modescreen &&
    <div className={`bg-black bg-opacity-10 absolute top-0 left-0 w-full h-full overflow-hidden  z-30 `}>
      <div className="absolute h-full w-full top-1 left-1 z-0 " onClick={() => setModescreen(!modescreen)}></div>
      <div className="mx-auto w-80 dysing-motormodel p-5 mt-36 relative z-2">
        <div className="-mt-7 mb-3 flex">
          <div className="flex-1">
            <div className="  bg-gray-100 w-20 rounded-md border-gray-400 border text-center font-bold  text-sm text-gray-600 py-1">Mode</div>
          </div>
          <div>
            <button className="bg-gray-100 rounded-md border-gray-400 border px-1.5 text-gray-500 hover:bg-red-500 transition-all hover:text-white" onClick={() => setModescreen(!modescreen)}>✖</button>
          </div>
        </div>     
        <IsotopicPressureMode gasBoxState={gasBoxState} IOWrite={IOWrite} setModescreen={setModescreen} modescreen={modescreen}/>        
      </div>
    </div>
    }
    {biasscreen &&
    <div className={`bg-black bg-opacity-10 absolute top-0 left-0 w-full h-full overflow-hidden  z-30 `}>
      <div className="absolute h-full w-full top-1 left-1 z-0 " onClick={() => setBiasscreen(!biasscreen)}></div>
      <div className="mx-auto w-80 dysing-motormodel p-5 mt-36 relative z-2">
        <div className="-mt-7 mb-3 flex">
          <div className="flex-1">
            <div className="  bg-gray-100 w-28 rounded-md border-gray-400 border text-center font-bold  text-sm text-gray-600 py-1">Bias Power</div>
          </div>
          <div>
            <button className="bg-gray-100 rounded-md border-gray-400 border px-1.5 text-gray-500 hover:bg-red-500 transition-all hover:text-white" onClick={() => setBiasscreen(!biasscreen)}>✖</button>
          </div>
        </div>     
        <DicingBiasPoverModel gasBoxState={gasBoxState} IOWrite={IOWrite} setBiasscreen={setBiasscreen} biasscreen={biasscreen}/>        
      </div>
    </div>
    } 

</>  );
}