import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ChannelService, ChannelSubscriptionService } from "@getvia/sdk";
const viaConfig = {
  channelServiceOptions: {
      apiToken: "test",
      baseUrl: process.env.REACT_APP_VIA_CORE_API,
      wsUrl: process.env.REACT_APP_VIA_NOTIFICATION_HUB
    },
  };
  const channelService = new ChannelService(viaConfig.channelServiceOptions);
const channelSubscriptionService = new ChannelSubscriptionService(viaConfig.channelServiceOptions);
const defaultGasBoxConfiguration = [
    {
      object: "MFC",
      name: "MFC1",
      readChannelName: "MAIN.MFC1.In.MeasuredFlowRate",
      writeChannelName: "MAIN.MFC1.Out.SetpointFlowRate",
      feedBackChannel: "MAIN.MFC1.In.MeasuredFlowRate",
      currentValue: 0,
    }
];
export default function Motor(props) {

    const gasBoxConfiguration = useRef(defaultGasBoxConfiguration);
    const channels = useRef();
    const [channellist, setchannellist] = useState(null);
    const [checked, setChecked] = useState(true);
    const subscriptionRef = useRef();
    const fetchChannelsStates = async () => {
        const response = await channelService.ChannelStates();
        console.log("ChannelStates: ", { response });
        if (response) {
          channels.current = response;
          console.log('channels.current',channels.current)
          setchannellist(channels.current);
        }
      };
      const subscribeToNotification = () => {

        const onNewMessage = (message) => {
          const channel = JSON.parse(message);
          console.log(channel)
          setchannellist(channel);
        };
    
        let channelSubscriptions = [];
    
        gasBoxConfiguration.current.forEach(config => {
          channelSubscriptions.push(config.feedBackChannel);
        })
        const subscription = {
          channels: channelSubscriptions,
          onNewMessage: onNewMessage,
        };
    
        subscriptionRef.current = subscription;
        channelSubscriptionService.subscribe(subscription);
      };
      const unSubscribe = () => {
        channelSubscriptionService.unSubscribe(subscriptionRef.current);
      };
      useEffect(() => {
        const fetChannelData = async () => {
          await fetchChannelsStates();
          subscribeToNotification();
        };
        fetChannelData();
        return () => {
          unSubscribe();
        };
      }, []);
  return (
    <div className='flex m-2 px-4 py-6'>

        <div className='flex-1 grid grid-cols-2 p-3'>
            <div className='w-full h-full overflow-y-scroll'>
                <div className='text-xl font-bold mb-4'>Control parameters</div>
                <table className='border w-full'>
                    <thead>
                        <tr>
                            <th className='border p-1'>Parameter</th>
                            <th className='border p-1'>Type</th>
                            <th className='border p-1'>Value</th>
                        </tr>                
                    </thead>
                    <tbody>
                        {channellist?.map((i,j)=>(
                           i.direction=="IN"&&i.ioObject=="Chuck_Motor"&&
                            <tr className='border'>
                                <td className='border p-1'>{i.channelName?.split('.').pop()}</td>
                                <td className='border p-1'>{i.type}</td>
                                <td className='border p-1'>{i.data.value}</td>
                            </tr>                            
                        ))}
                    </tbody>
                    
                </table>

                <div className='text-xl font-bold my-4'>Status parameters</div>
                <table className='border w-full'>
                    <thead>
                        <tr>
                            <th className='border p-1'>Parameter</th>
                            <th className='border p-1'>Type</th>
                            <th className='border p-1'>Value</th>
                        </tr>                
                    </thead>
                    <tbody>
                    {channellist?.map((i,j)=>(
                           i.direction=="OUT"&&i.ioObject=="Chuck_Motor"&&
                            <tr className='border p-1'>
                                <td className='border p-1'>{i.channelName?.split('.').pop()}</td>
                                <td className='border p-1'>{i.type}</td>
                                <td className='border p-1'>{i.data.value}</td>
                            </tr>                            
                        ))}
                    </tbody>
                    
                </table>
            </div>
        </div>
      <div className='w-60'>
        <div id="recipe_select_list_holder" className="border border-black p-3 rounded-lg mb-5">
          <span className="-mt-6 block bg-white px-2 border border-black ml-0 rounded-xl mb-2 w-36 text-center">Commands</span>
          <select className="recipe_select_list_holder w-full mb-2 bg-gray-100 border-2 border-black font-bold text-xl px-2 py-1 rounded-lg transition-all hover:bg-black hover:text-white ">
            {/* <option>Select Option</option>  */}
            <option>Reset</option> 
            <option>Switch On</option> 
            <option>Home</option> 
            <option>Stop</option> 
            <option>Process Position</option> 
            <option>Transfer Position</option> 
          </select>
          <button className=" bg-gray-100 border-2 border-black font-bold text-xl px-2 mt-1 w-full py-1 rounded-lg transition-all hover:bg-black hover:text-white ">
            Execute
        </button> 
        </div>
        <div id="recipe_select_list_holder" className="border border-black p-3 rounded-lg mb-5">
          {/* <span className="-mt-6 block bg-white px-2 border border-black ml-0 rounded-xl mb-2 w-36  text-center">Position</span> */}

          <button onClick={()=>setChecked(!checked)}
          className={`${checked? 'text-black': 'text-white'} font-extrabold rounded-md hover:bg-gray-400  border-2 border-black h-7 w-7`}>✓</button>
          <div className='font-bold pb-3 inline-block pl-4 h-7'>Velocity Mode</div>
          
          <hr className='my-3 border border-2'/>
          
          <button className=" w-24 block mx-auto recipe_select_list_holder  mb-2 bg-gray-100 border-2 border-black font-bold text-xl px-2 py-1 rounded-lg transition-all hover:bg-black hover:text-white ">
            ▲
          </button>
          <input type="text"  className='mx-auto mb-2 bg-gray-100 border-2 border-black font-bold text-xl px-2 py-1 rounded-lg w-24 block'/>
          <button className="w-24 block mx-auto recipe_select_list_holder mb-2 bg-gray-100 border-2 border-black font-bold text-xl px-2 py-1 rounded-lg transition-all hover:bg-black hover:text-white ">
            ▼
          </button>
          
        </div>
      </div>
    </div>
  );
}