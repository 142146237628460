import React, { useEffect, useRef, useState, useReducer } from "react";
import { ChannelService, ChannelSubscriptionService } from "@getvia/sdk";


export default function IsotopicPressureMode({ gasBoxState,IOWrite,setModescreen,modescreen}) {

  // const [mode,setMode] = useState(gasBoxState?.find(o => o.name === "ISOThrottleValve").currentValue)

  const [time, settime] = useState(null);
  const [throtilvalve, setthrotilvalve] = useState(null);
  const [pressure,setPressure] = useState()
  const [position,setPosition] = useState("")
  const [actualPressure,setActualPressure] = useState()
  const [actualPosition,setActualPosition] = useState("")
  const modeFunction = async (e) => {
    let val = e;
    if(e==2) {
      val = 5;
    } else {
      val=2;
    }
    IOWrite('MAIN.ISOThrottleValve.Out.Control_Mode', val);
    // setMode(val)
    console.log(val)
   }
 const setpressureValue = async () =>{
    IOWrite('MAIN.ISOThrottleValve.Out.Target_Pressure', pressure);
    setModescreen(!modescreen)
 }
 const setPositionValue = async () =>{
    IOWrite('MAIN.ISOThrottleValve.Out.Target_Position', position);
    setModescreen(!modescreen)
 }
 const OpenISOThrottleValve=async()=>{
  // IOWrite('MAIN.ISOThrottleValve.Out.Control_Mode', 4);setthrotilvalve(null)
  var formdata = {
    token: "",
    ruleType: "Sequence",
    ruleGroups: [],
    ruleSubGroups: [],
    rule: "",
    sequence: 'CheckAndOpenISOThrottleValve',
    inputData: {
    },
  };
  await fetch(
    process.env.REACT_APP_VIA_RULE_ORCHES_API + "/api/Rules/ExecuteRule",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers as needed
      },
      body: JSON.stringify(formdata),
    }
  )
    .then((response) => {
      // Check if the request was successful
      if (!response.ok) {
        throw new Error("Could not start execution of CheckAndOpenISOThrottleValve.");
      }
      // Parse the response as JSON
      return response.json();
    })
    .then((data) => {
      // Handle the JSON data
      let currInstanceId = data[0].resultValue;
      console.log('CheckAndOpenISOThrottleValve succeeded with instance id: '+currInstanceId)
      setthrotilvalve(null)
    })
    .catch((error) => {
      // Handle any errors that occurred during the fetch
      console.error("Fetch error start execution of CheckAndOpenISOThrottleValve :", error);
      setthrotilvalve(null)
    });

 }
 const ChangePositionModeISOThrottleValve=async()=>{
  // IOWrite('MAIN.ISOThrottleValve.Out.Control_Mode', 4);setthrotilvalve(null)
  var formdata = {
    token: "",
    ruleType: "Sequence",
    ruleGroups: [],
    ruleSubGroups: [],
    rule: "",
    sequence: 'CheckAndChangePositionModeISOThrottle',
    inputData: {
    },
  };
  await fetch(
    process.env.REACT_APP_VIA_RULE_ORCHES_API + "/api/Rules/ExecuteRule",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers as needed
      },
      body: JSON.stringify(formdata),
    }
  )
    .then((response) => {
      // Check if the request was successful
      if (!response.ok) {
        throw new Error("Could not start execution of CheckAndChangePositionModeISOThrottle.");
      }
      // Parse the response as JSON
      return response.json();
    })
    .then((data) => {
      // Handle the JSON data
      let currInstanceId = data[0].resultValue;
      console.log('CheckAndChangePositionModeISOThrottle succeeded with instance id: '+currInstanceId)
      setthrotilvalve(null)
    })
    .catch((error) => {
      // Handle any errors that occurred during the fetch
      console.error("Fetch error start execution of CheckAndChangePositionModeISOThrottle :", error);
      setthrotilvalve(null)
    });

 }
 const ChangePressureModeISOThrottleValve=async()=>{
  // IOWrite('MAIN.ISOThrottleValve.Out.Control_Mode', 4);setthrotilvalve(null)
  var formdata = {
    token: "",
    ruleType: "Sequence",
    ruleGroups: [],
    ruleSubGroups: [],
    rule: "",
    sequence: 'CheckAndChangePressureModeISOThrottle',
    inputData: {
    },
  };
  await fetch(
    process.env.REACT_APP_VIA_RULE_ORCHES_API + "/api/Rules/ExecuteRule",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers as needed
      },
      body: JSON.stringify(formdata),
    }
  )
    .then((response) => {
      // Check if the request was successful
      if (!response.ok) {
        throw new Error("Could not start execution of CheckAndChangePressureModeISOThrottle.");
      }
      // Parse the response as JSON
      return response.json();
    })
    .then((data) => {
      // Handle the JSON data
      let currInstanceId = data[0].resultValue;
      console.log('CheckAndChangePressureModeISOThrottle succeeded with instance id: '+currInstanceId)
      setthrotilvalve(null)
    })
    .catch((error) => {
      // Handle any errors that occurred during the fetch
      console.error("Fetch error start execution of CheckAndChangePressureModeISOThrottle :", error);
      setthrotilvalve(null)
    });

 }
 useEffect(() => {
  // const interval = setInterval(() => {
  //   settime(new Date())
  //   console.log(new Date())
  // }, 1000);

  // Cleanup the interval on component unmount
  // return () => clearInterval(interval);
}, []);


 useEffect(() => {
  setPressure(gasBoxState?.find(o => o.writeChannelName === "MAIN.ISOThrottleValve.Out.Target_Pressure").currentValue)
  setPosition(gasBoxState?.find(o => o.writeChannelName === "MAIN.ISOThrottleValve.Out.Target_Position").currentValue)
  setActualPosition(gasBoxState?.find(o => o.readChannelName === "MAIN.ISOThrottleValve.In.Actual_Position").currentValue)
  setActualPressure(gasBoxState?.find(o => o.readChannelName === "MAIN.ISOThrottleValve.In.Actual_Pressure").currentValue)
  // setPositionstat(gasBoxState?.find(o => o.writeChannelName === "MAIN.ISOThrottleValve.Out.Control_Mode").currentValue)
}, [gasBoxState]);
  return (<>
    
    <div className="text-black">
    <div className="grid grid-cols-2 gap-3 mt-5 bg-gray-200 rounded-full py-3 relative">
          <div className={`flex justify-center ${gasBoxState?.find(o => o.name === "ISOThrottleValveControlMode").currentValue==4&& 'font-bold'}`}>
            <div 
            // onClick={()=>{IOWrite('MAIN.ISOThrottleValve.Out.Control_Mode', 4)}}
            onClick={()=>setthrotilvalve('Open')}
            className={`rounded-full h-5 w-5 hover:scale-125 active:scale-95 ${gasBoxState?.find(o => o.name === "ISOThrottleValveControlMode").currentValue==4? 'bg-cyan-400':'bg-gray-300'}  border-2 shadow-inner shadow-gray-500  top-1 transition-all mt-1 mr-2`}></div> 
            Open
          </div>
          <div className={`flex justify-center ${gasBoxState?.find(o => o.name === "ISOThrottleValveControlMode").currentValue==3&& 'font-bold'}`}>
            <div 
            // onClick={()=>{IOWrite('MAIN.ISOThrottleValve.Out.Control_Mode', 3)}}
            onClick={()=>setthrotilvalve('Close')}
            className={`rounded-full h-5 w-5  hover:scale-125 active:scale-95   ${gasBoxState?.find(o => o.name === "ISOThrottleValveControlMode").currentValue==3? 'bg-cyan-400':'bg-gray-300'}  border-2 shadow-inner shadow-gray-500  top-1 transition-all mt-1 mr-2`}></div> 
            Close
          </div>
        </div>
        {/* sub confirmation window start */}
        {throtilvalve!=null&&
        <div className=" absolute z-20 h-full top-0 w-full left-0">
            <div className="bg-white w-4/5 mx-auto rounded-2xl shadow-lg border-gray-400 border mt-20">
              <div className="text-center font-bold text-sm py-3">{throtilvalve} Throttle Valve?</div>
              <div className="grid grid-cols-2 gap-1 px-2 pb-2">
                {throtilvalve=='Open'?
                // <button className="shadow bg-white  px-3 py-1 rounded-lg border border-gray-400 hover:bg-gray-300" onClick={()=>{IOWrite('MAIN.ISOThrottleValve.Out.Control_Mode', 4);setthrotilvalve(null)}}>Yes</button>
                <button className="shadow bg-white  px-3 py-1 rounded-lg border border-gray-400 hover:bg-gray-300" onClick={()=>{OpenISOThrottleValve();}}>Yes</button>
                :
                <button className="shadow bg-white  px-3 py-1 rounded-lg border border-gray-400 hover:bg-gray-300" onClick={()=>{IOWrite('MAIN.ISOThrottleValve.Out.Control_Mode', 3);setthrotilvalve(null)}}>Yes</button>
                }
                <button className="shadow bg-gray-700 text-white px-3 py-1 rounded-lg border border-gray-400 hover:bg-gray-900" onClick={()=>setthrotilvalve(null)}>No</button>
              </div>
            </div>     
        </div>
        }
        {/* sub confirmation window start */}

      <div className="rounded-full flex p-2 text-center bg-flip-blue mt-5"> 
        {/* <div onClick={()=>{IOWrite('MAIN.ISOThrottleValve.Out.Control_Mode', 5)}} */}
        <div onClick={()=>{ChangePressureModeISOThrottleValve()}}
        className={`flex-1 rounded-l-full p-1 bg-flip-label  cursor-pointer ${gasBoxState?.find(o => o.name === "ISOThrottleValveControlMode").currentValue==5 && 'bg-flip-label-active'} -mr-3  text-sm transition-all`}>Pressure</div>
        <div className="relative rounded-full  flex-1 bg-flip-btn cursor-pointer hover:scale-110 transition-all active:scale-100" 
        onClick={()=>modeFunction(gasBoxState?.find(o => o.name === "ISOThrottleValveControlMode").currentValue)}>
          <div className={`rounded-full h-5 w-5  bg-cyan-400 border-2 shadow-inner shadow-gray-500  top-1 transition-all 
            ${gasBoxState?.find(o => o.name === "ISOThrottleValveControlMode").currentValue==5 ? "left-1 absolute" :
              gasBoxState?.find(o => o.name === "ISOThrottleValveControlMode").currentValue==2? "right-1 absolute":'mx-auto mt-1 opacity-50 bg-gray-300'}`}
            ></div> 
        </div>
        {/* <div  onClick={()=>{IOWrite('MAIN.ISOThrottleValve.Out.Control_Mode', 2)}} */}
        <div  onClick={()=>{ChangePositionModeISOThrottleValve()}}
        className={`flex-1 rounded-r-full p-1 bg-flip-label cursor-pointer ${gasBoxState?.find(o => o.name === "ISOThrottleValveControlMode").currentValue==2 && 'bg-flip-label-active  '} -ml-3 text-sm transition-all`}>Position</div>
      </div>
      
      {gasBoxState?.find(o => o.name === "ISOThrottleValveControlMode").currentValue==5?<>
        <div className="flex w-full mt-5 font-bold">
          <div className="flex-1 font-bold text-gray-600">Value </div>
          <div className="dysing-input rounded relative flex">
            <input type="text" value={pressure} onChange={(e)=>setPressure(e.target.value)} className="bg-transparent flex-1 w-40  p-1 px-2"/>
            <div className="text-right p-1 px-2 w-12">Torr</div>
          </div>
        </div>
        <div className="flex w-full mt-5 font-bold">
          <div className="flex-1 font-bold text-gray-600">Actual Pressure : <span>{actualPressure}</span> <span>Torr</span> </div>
        </div>
        <div className="grid grid-cols-2 gap-3 mt-5">
          <button className="dysing-motorbtn hover:bg-white shadow-md shadow-gray-400 !w-full text-sm  mt-5 font-bold py-3 rounded-lg  !text-gray-600" onClick={()=>setModescreen(!modescreen)}>CANCEL</button>
          <button className="dysing-motorbtn hover:bg-white shadow-md shadow-gray-400 !w-full text-sm  mt-5 font-bold py-3 rounded-lg  !text-gray-600"
          onClick={()=>setpressureValue()}>SET</button>
        </div>
      </>:
      gasBoxState?.find(o => o.name === "ISOThrottleValveControlMode").currentValue==2?
      <>

        <div className="flex w-full mt-5 font-bold">
          <div className="flex-1 font-bold text-gray-600">Value  </div>
          <div className="dysing-input rounded relative flex">
            <input type="text" value={position} onChange={(e)=>setPosition(e.target.value)} className="bg-transparent flex-1 w-40  p-1 px-2"/><div className="text-right p-1 px-2 w-12">%</div>
          </div>
        </div>
        <div className="flex w-full mt-5 font-bold">
          <div className="flex-1 w-full font-bold text-gray-600">Actual Position : <span>{actualPosition} </span><span>%</span> </div>
        </div>
        <div className="grid grid-cols-2 gap-3 mt-5">
          <button className="dysing-motorbtn hover:bg-white shadow-md shadow-gray-400 !w-full text-sm  mt-5 font-bold py-3 rounded-lg  !text-gray-600" onClick={()=>setModescreen(!modescreen)}>CANCEL</button>
          <button className="dysing-motorbtn hover:bg-white shadow-md shadow-gray-400 !w-full text-sm  mt-5 font-bold py-3 rounded-lg  !text-gray-600"
            onClick={()=>setPositionValue()}>SET</button>
        </div>
      
        </>:
         <div className="grid grid-cols-2 gap-3 mt-5">
         <button className="dysing-motorbtn hover:bg-white shadow-md shadow-gray-400 !w-full text-sm  mt-5 font-bold py-3 rounded-lg  !text-gray-600" onClick={()=>setModescreen(!modescreen)}>CANCEL</button>
         
       </div>
        }
    </div>

  </>);
}