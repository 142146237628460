import React from 'react';
import './EmbossedBtn.css';

function EmbossedBtn(props) {
    const { btnText, active, action } = props
    return (
        <button className={`embossed-btn ${active ? 'active' : ''}`} onClick={() => action(active ? '' : btnText)}>{btnText}</button>
    );
}

export default EmbossedBtn;