import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import GasBox from "../diagrams/GasBox";
import GasBoxV2 from '../diagrams/GasBoxV2';
import IsotropicEtchChamber from '../diagrams/IsotropicEtchChamber';
import GasBox_DicingRIE from '../diagrams/GasBox_DicingRIE';
import DicingRIEChamber from '../diagrams/DicingRIEChamber';
import DicingRIEChamberold from '../diagrams/DicingRIEChamberold';
import IsotopicEtchChamberNew from '../diagrams/IsotopicEtchChamberNew';
import DicingRIEChamberDTDL from '../diagrams/DicingRIEChamberDTDL';
import IsotopicEtchChamberDTDL from '../diagrams/IsotopicEtchChamberDTDL';

export default function Canvas({ diagram = 'gasbox' }) {

    let diagramComponent;

    switch (diagram) {
        case 'gasboxv2':
            diagramComponent = <GasBoxV2 />;
            break;
        case 'iec':
            diagramComponent = <IsotropicEtchChamber />;
            break;
        case 'iecChamber':
            diagramComponent = <IsotopicEtchChamberNew />;
            break;
        case 'iecChamberDtdl':
            diagramComponent = <IsotopicEtchChamberDTDL />;
            break;
        case 'GasBox_DicingRIE':
            diagramComponent = <GasBox_DicingRIE />;
            break;
        case 'DicingRIEChamber':
            diagramComponent = <DicingRIEChamber />;
            break;
        case 'DicingRIEChamberDTDL':
            diagramComponent = <DicingRIEChamberDTDL />;
            break;
        case 'DicingRIEChamberold':
            diagramComponent = <DicingRIEChamberold />;
            break;
        default:
            diagramComponent = <GasBox />; // or any default component you want to render
            break;
    }

    return (
        <div className='w-full  flex flex-col '>
            {diagramComponent}
        </div>
    );
}