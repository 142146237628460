import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ChannelService, ChannelSubscriptionService } from "@getvia/sdk";
import MotorControls from "./MotorControls";
import Cookies from 'js-cookie';
const viaConfig = {
  channelServiceOptions: {
    apiToken: "test",
    baseUrl: process.env.REACT_APP_VIA_CORE_API,
    wsUrl: process.env.REACT_APP_VIA_NOTIFICATION_HUB,
  },
};
const channelService = new ChannelService(viaConfig.channelServiceOptions);
const channelSubscriptionService = new ChannelSubscriptionService(viaConfig.channelServiceOptions);
const defaultGasBoxConfiguration = [
  {
    object: "MFC",
    name: "MFC1",
    readChannelName: "MAIN.MFC1.In.MeasuredFlowRate",
    writeChannelName: "MAIN.MFC1.Out.SetpointFlowRate",
    feedBackChannel: "MAIN.MFC1.In.MeasuredFlowRate",
    currentValue: 0,
  },
];

const defaultMotorConfiguration = [
  {
    object: 'Chuck_Motor',
    name: 'Chuck_Motor',
    readChannelName: 'MAIN.Chuck_Motor.Out.Control_word',
    writeChannelName: 'MAIN.Chuck_Motor.Out.Control_word',
    feedBackChannel: 'MAIN.Chuck_Motor.Out.Control_word',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Modes_of_operation',
    readChannelName: 'MAIN.Chuck_Motor.Out.Modes_of_operation',
    writeChannelName: 'MAIN.Chuck_Motor.Out.Modes_of_operation',
    feedBackChannel: 'MAIN.Chuck_Motor.Out.Modes_of_operation',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Chuck_Motor',
    readChannelName: 'MAIN.Chuck_Motor.In.Profile_target_velocity',
    writeChannelName: 'MAIN.Chuck_Motor.Out.Profile_target_velocity',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Profile_target_velocity',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Profile_target_position',
    readChannelName: 'MAIN.Chuck_Motor.Out.Profile_target_position',
    writeChannelName: 'MAIN.Chuck_Motor.Out.Profile_target_position',
    feedBackChannel: 'MAIN.Chuck_Motor.Out.Profile_target_position',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Chuck_Motor',
    readChannelName: 'MAIN.Chuck_Motor.In.Profile_target_acceleration',
    writeChannelName: 'MAIN.Chuck_Motor.Out.Profile_target_acceleration',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Profile_target_acceleration',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Chuck_Motor',
    readChannelName: 'MAIN.Chuck_Motor.In.Profile_target_deceleration',
    writeChannelName: 'MAIN.Chuck_Motor.Out.Profile_target_deceleration',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Profile_target_deceleration',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Tracking_warning_timeout',
    readChannelName: 'MAIN.Chuck_Motor.Out.Tracking_warning_timeout',
    writeChannelName: 'MAIN.Chuck_Motor.Out.Tracking_warning_timeout',
    feedBackChannel: 'MAIN.Chuck_Motor.Out.Tracking_warning_timeout',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Chuck_Motor',
    readChannelName: 'MAIN.Chuck_Motor.In.Homing_method',
    writeChannelName: 'MAIN.Chuck_Motor.Out.Homing_method',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Homing_method',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Status_word',
    readChannelName: 'MAIN.Chuck_Motor.In.Status_word',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Status_word',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Actual_motor_position',
    readChannelName: 'MAIN.Chuck_Motor.In.Actual_motor_position',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Actual_motor_position',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Modes_of_operation_display',
    readChannelName: 'MAIN.Chuck_Motor.In.Modes_of_operation_display',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Modes_of_operation_display',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Commanded_position',
    readChannelName: 'MAIN.Chuck_Motor.In.Commanded_position',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Commanded_position',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Commanded_velocity',
    readChannelName: 'MAIN.Chuck_Motor.In.Commanded_velocity',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Commanded_velocity',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Torque_demand_value',
    readChannelName: 'MAIN.Chuck_Motor.In.Torque_demand_value',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Torque_demand_value',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Digital_input_line_status',
    readChannelName: 'MAIN.Chuck_Motor.In.Digital_input_line_status',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Digital_input_line_status',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Shutdown_option_code',
    readChannelName: 'MAIN.Chuck_Motor.In.Shutdown_option_code',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Shutdown_option_code',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Disable_option_code',
    readChannelName: 'MAIN.Chuck_Motor.In.Disable_option_code',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Disable_option_code',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Halt_option_code',
    readChannelName: 'MAIN.Chuck_Motor.In.Halt_option_code',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Halt_option_code',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Last_error_code',
    readChannelName: 'MAIN.Chuck_Motor.In.Last_error_code',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Last_error_code',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Peak_current_limit',
    readChannelName: 'MAIN.Chuck_Motor.In.Peak_current_limit',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Peak_current_limit',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Continuous_current_limit',
    readChannelName: 'MAIN.Chuck_Motor.In.Continuous_current_limit',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Continuous_current_limit',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Torque_actual_value',
    readChannelName: 'MAIN.Chuck_Motor.In.Torque_actual_value',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Torque_actual_value',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Torque_actual_value',
    readChannelName: 'MAIN.Chuck_Motor.In.Torque_actual_value',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Torque_actual_value',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'RMS_current',
    readChannelName: 'MAIN.Chuck_Motor.In.RMS_current',
    feedBackChannel: 'MAIN.Chuck_Motor.In.RMS_current',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Latching_faults',
    readChannelName: 'MAIN.Chuck_Motor.In.Latching_faults',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Latching_faults',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Actual_motor_velocity',
    readChannelName: 'MAIN.Chuck_Motor.In.Actual_motor_velocity',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Actual_motor_velocity',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Actual_motor_current',
    readChannelName: 'MAIN.Chuck_Motor.In.Actual_motor_current',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Actual_motor_current',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Quick_stop_option_code',
    readChannelName: 'MAIN.Chuck_Motor.In.Quick_stop_option_code',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Quick_stop_option_code',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Motor_OPState',
    readChannelName: 'MAIN.Chuck_Motor.In.Motor_OPState',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Motor_OPState',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Status_ISOChuckMotorServoOnSeq',
    readChannelName: 'Status_ISOChuckMotorServoOnSeq',
    writeChannelName: 'Status_ISOChuckMotorServoOnSeq',
    feedBackChannel: 'Status_ISOChuckMotorServoOnSeq',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Status_ISOChuckMotorHomeSeq',
    readChannelName: 'Status_ISOChuckMotorHomeSeq',
    feedBackChannel: 'Status_ISOChuckMotorHomeSeq',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Status_ISOChuckMotorProcessPosSeq',
    readChannelName: 'Status_ISOChuckMotorProcessPosSeq',
    feedBackChannel: 'Status_ISOChuckMotorProcessPosSeq',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Status_ISOChuckMotorTransferPosSeq',
    readChannelName: 'Status_ISOChuckMotorTransferPosSeq',
    feedBackChannel: 'Status_ISOChuckMotorTransferPosSeq',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Chuck_Motor',
    readChannelName: 'MAIN.Chuck_Motor.In.Home_Approach_Speed',
    writeChannelName: 'MAIN.Chuck_Motor.Out.Home_Approach_Speed',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Home_Approach_Speed',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Chuck_Motor',
    readChannelName: 'MAIN.Chuck_Motor.In.Home_Sync_Speed',
    writeChannelName: 'MAIN.Chuck_Motor.Out.Home_Sync_Speed',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Home_Sync_Speed',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Chuck_Motor',
    readChannelName: 'MAIN.Chuck_Motor.In.Homing_acceleration',
    writeChannelName: 'MAIN.Chuck_Motor.Out.Homing_acceleration',
    feedBackChannel: 'MAIN.Chuck_Motor.In.Homing_acceleration',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'Chuck_MotorTarget_velocity',
    readChannelName: 'MAIN.Chuck_Motor.Out.Target_velocity',
    writeChannelName: 'MAIN.Chuck_Motor.Out.Target_velocity',
    feedBackChannel: 'MAIN.Chuck_Motor.Out.Target_velocity',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'ISOChuck_Motor_Config_Process_Position',
    readChannelName: 'ISOChuck_Motor_Config_Process_Position',
    writeChannelName: 'ISOChuck_Motor_Config_Process_Position',
    feedBackChannel: 'ISOChuck_Motor_Config_Process_Position',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'ISOChuck_Motor_Config_Transfer_Position',
    readChannelName: 'ISOChuck_Motor_Config_Transfer_Position',
    writeChannelName: 'ISOChuck_Motor_Config_Transfer_Position',
    feedBackChannel: 'ISOChuck_Motor_Config_Transfer_Position',
    currentValue: null
  },
  {
    object: 'Chuck_Motor',
    name: 'ISOChuckMotor_Running_InstanceId',
    readChannelName: 'ISOChuckMotor_Running_InstanceId',
    writeChannelName: 'ISOChuckMotor_Running_InstanceId',
    feedBackChannel: 'ISOChuckMotor_Running_InstanceId',
    currentValue: ""
  },
]

export default function Motor(props) {
  const gasBoxConfiguration = useRef(defaultGasBoxConfiguration);
  const motorConfiguration = useRef(defaultMotorConfiguration);
  const channels = useRef();
  const [channellist, setchannellist] = useState();
  const [channelState, setchannelState] = useState();
  const [checkedVelocity, setCheckedVelocity] = useState(false);
  const [controlParaValModal, setControlParaValModal] = useState(false);
  const [velocityModal, setVelocityModal]  = useState(false);
  const [velocity, setVelocity] = useState(0);
  const [ProcessPosition, setProcessPosition] = useState(100000);
  const [transferPosition, setTransferPosition] = useState(100);
  const [selectedControlParaVal, setSelectedControlParaVal] = useState();
  const [updatedControlParaVal, setUpdatedControlParaVal] = useState();
  const [selectedVelocityVal, setSelectedVelocityVal] = useState();
  const [updatedVelocityVal, setUpdatedVelocityVal]  = useState();
  const subscriptionRef = useRef();
  const selectedControlParaValFieldRef = useRef(null);
  const [motoOPState, setMotorOPState] = useState(0);
  const [statusWordVal, setStatusWordVal] = useState(0);
  const [resetBtnClicked, setResetBtnClicked] = useState(false);
  const [switchOnComplete, setSwitchOnComplete] = useState(false);
  const [switchonerror, setSwitchonerror] = useState(false);
  const [homeerror, setHomeerror] = useState(false);
  const [processerror, setProcesserror] = useState(false);
  const [transfererror, setTransfererror] = useState(false);
  const [time, settime] = useState(null);
// button pressed states start
const [switchhitchonPress,setswitchonpress] = useState(false)
const [homePress,sethomePress] = useState(false)
const [processPress,setProcessPress] = useState(false)
const [transferPress,setTransferPress] = useState(false)
// button pressed states end
  const fetchChannelsStates = async () => {
    const response = await channelService.ChannelStates();
    console.log("ChannelStates: ", { response });
    if (response) {
      channels.current = response;
      console.log("channels.current", channels.current);
      setchannellist(channels.current);
      updateMotorsState(channels.current);
      channels?.current.forEach((item, index) => {
        if(item.channelName === 'MAIN.Chuck_Motor.In.Motor_OPState') {
          setMotorOPState(item.value) 
          
        }
        if(item.channelName ==="MAIN.Chuck_Motor.In.Status_word") {
          console.log('exists')
          // checkswitchon(item)
        }
      })
    }
  };

  useEffect(() => {
    if(channellist && channellist.length > 0) {
      channellist.forEach((item, index) => {
        if(item.channelName === 'MAIN.Chuck_Motor.In.Motor_OPState') {
          setMotorOPState(item.value)
        }
        if(item.channelName === 'MAIN.Chuck_Motor.In.Status_word') {
          setStatusWordVal(Number(item.value))
        }
      })
    }
    
  }, [channellist])


  const subscribeToNotification = () => {
    const onNewMessage = (message) => {
      const channel = JSON.parse(message);
      
      console.log("onNewMessage", channel);
      // setchannellist(channel);
      updateMotorsState([channel])
    };

    let channelSubscriptions = [];

    motorConfiguration.current.forEach(config => {
      channelSubscriptions.push(config.feedBackChannel);
    });
    const subscription = {
      channels: channelSubscriptions,
      onNewMessage: onNewMessage,
    };
   
    subscriptionRef.current = subscription;
    channelSubscriptionService.subscribe(subscription);
  };

  const unSubscribe = () => {
    channelSubscriptionService.unSubscribe(subscriptionRef.current);
  };

  const updateMotorsState = (updatedChannels) => {

    let index = -1
    let motorConfigurationState = motorConfiguration.current;
    updatedChannels?.forEach((item) => {
      const config = motorConfigurationState.find((obj, objIndex) => {
        index = objIndex;
        return obj.feedBackChannel === item.channelName
      })
      if (config) {
        config.currentValue = item.data.value;
        motorConfigurationState[index] = config;
      }
    })
    // console.log('motorConfigurationState',motorConfigurationState)
    setchannelState(() => motorConfigurationState);
    // setVelocity(motorConfigurationState?.find(o => o.name === "Chuck_MotorTarget_velocity").currentValue);
    
    // checkswitchon(motorConfigurationState?.find(o => o.name === "Status_word"));
    // if(resetbtn=='pressed') { setResetBtnClicked(true)} else {setResetBtnClicked(false)}
    // if(ServoOn=='pressed') { setSwitchOnComplete(true)} else {setSwitchOnComplete(false)}
    //==============check switchon==========start================
    if(motorConfigurationState?.find(o => o.readChannelName === "MAIN.Chuck_Motor.Out.Control_word").currentValue==128) {
      setResetBtnClicked(true)
    }else{
      setResetBtnClicked(false)
    }
      let Status_ISOChuckMotorServoOnSeq = motorConfigurationState?.find(o => o.name === "Status_ISOChuckMotorServoOnSeq").currentValue;
      if(Status_ISOChuckMotorServoOnSeq=="RUNNING") {
        setswitchonpress(true);
        setSwitchOnComplete(false);
      } 
      else if(Status_ISOChuckMotorServoOnSeq=="COMPLETED") {
        setswitchonpress(false);
        setSwitchOnComplete(true);
      }
      else if(Status_ISOChuckMotorServoOnSeq=="TERMINATED") {
        setswitchonpress(false);
        setSwitchOnComplete(false);
      }
      else if(Status_ISOChuckMotorServoOnSeq=="FAILED") {
        setswitchonpress(false);
        setSwitchOnComplete(false);
        setSwitchonerror(true)
      } else {
        setswitchonpress(false);
        setSwitchOnComplete(false);
      }
    //==============check switchon==========end=================
    //==============SERVO ON==========end=================
      let Status_ISOChuckMotorHomeSeq = motorConfigurationState?.find(o => o.name === "Status_ISOChuckMotorHomeSeq").currentValue;
       if(Status_ISOChuckMotorHomeSeq=="RUNNING") {
        sethomePress(true);
      } 
      else if(Status_ISOChuckMotorHomeSeq=="COMPLETED") {
        sethomePress(false);
      }
      else if(Status_ISOChuckMotorHomeSeq=="TERMINATED") {
        sethomePress(false);
      }
      else if(Status_ISOChuckMotorHomeSeq=="FAILED") {
        sethomePress(false);
        setHomeerror(true)
      } else {
        sethomePress(false);
      }
    //==============SERVO ON==========end=================
    
    //==============SERVO ON==========end=================
    let Status_ISOChuckMotorProcessPosSeq = motorConfigurationState?.find(o => o.name === "Status_ISOChuckMotorProcessPosSeq").currentValue;
    if(Status_ISOChuckMotorProcessPosSeq=="RUNNING") {
      setProcessPress(true);
    } 
    else if(Status_ISOChuckMotorProcessPosSeq=="COMPLETED") {
      setProcessPress(false);
    }
    else if(Status_ISOChuckMotorProcessPosSeq=="TERMINATED") {
      setProcessPress(false);
    }
    else if(Status_ISOChuckMotorProcessPosSeq=="FAILED") {
      setProcessPress(false);
      setProcesserror(true)
    } else {
      setProcessPress(false);
    }
  //==============SERVO ON==========end=================
    //==============SERVO ON==========end=================
    let Status_ISOChuckMotorTransferPosSeq = motorConfigurationState?.find(o => o.name === "Status_ISOChuckMotorTransferPosSeq").currentValue;
    if(Status_ISOChuckMotorTransferPosSeq=="RUNNING") {
      setTransferPress(true);
    } 
    else if(Status_ISOChuckMotorTransferPosSeq=="COMPLETED") {
      setTransferPress(false);
    }
    else if(Status_ISOChuckMotorTransferPosSeq=="TERMINATED") {
      setTransferPress(false);
    }
    else if(Status_ISOChuckMotorTransferPosSeq=="FAILED") {
      setTransferPress(false);
      setTransfererror(true)
    } else {
      setTransferPress(false);
    }
  //==============SERVO ON==========end=================
  }

  const IOWrite = (channelName, value) => {
    console.log("channels.current", channels.current);
    if (channelName && channels.current.length > 0) {
      const channel = channels.current.find(obj => {
        console.log('obj===> find', obj)
        console.log("channelName 1====>", channelName)
        console.log("channelName 2", obj.channelName)
        console.log("FOUND CHANNEL NAME====>", channelName == obj.channelName)
        return obj.channelName === channelName
      })
      console.log("if channel====>", channel)
      if (channel) {
        // channel.data.value = Number(value);
        channel.data.value = value;
        channelService.Write(channel).then(() => {
        });
      }
      
    }
    
  }

  const increaseVelocity = () => {
    const val = Number(velocity) + 100;
    // if (velocity < 10000) {
      setVelocity(val);
    // }
    console.log(velocity)
    handlevelocityvalue(val);
  
  };

  const decreaseVelocity = () => {
    let val;
    if (velocity > 110) {
      val = Number(velocity) - 100;
      setVelocity(val);
    }
    handlevelocityvalue(val);
  };

  const addEditControlParaVal = (e) => {
    const val = e.target.value;
    selectedControlParaValFieldRef.current = e.target
    setSelectedControlParaVal(val);
    setControlParaValModal(true);
  }

  const updateControlParaVal = () => {
    const channelName = selectedControlParaValFieldRef.current.getAttribute('data-channel');
    console.log("UPDATE", updatedControlParaVal)
    selectedControlParaValFieldRef.current.value = updatedControlParaVal;
    IOWrite(channelName, updatedControlParaVal)
    setControlParaValModal(false);
  }

  const addEditVelocityVal = (e) => {
    const val = e.target.value;
    setSelectedVelocityVal(val);
    setVelocityModal(true);
  }

  const updateVelocityVal = () => {
    setVelocity(updatedVelocityVal);
    setVelocityModal(false);
    handlevelocityvalue(updatedVelocityVal);
  }

  const handleCheckedVelocity = (val) => {
    setCheckedVelocity(val)
  }

  const handleButtonClick = (e) => {
    console.log('value',e.target.getAttribute('data-value'))
    const target = e.target;
    const channelName = target.getAttribute('data-channel');
    const value = target.getAttribute('data-value');
    const btnName = target.getAttribute('data-name');

    if(channelName && value) {
      IOWrite(channelName, value)
    }

    if(btnName && btnName == 'reset-btn') {
     
      setSwitchonerror(false)
    }

    // fetChannelData();
  }
  const handleResetClick = (e) => {
    console.log('value',e.target.getAttribute('data-value'))
    const target = e.target;
    const channelName = target.getAttribute('data-channel');
    const value = target.getAttribute('data-value');
    const btnName = target.getAttribute('data-name');

    if(channelName && value) {
      IOWrite(channelName, value)
      IOWrite('Status_ISOChuckMotorServoOnSeq', "TERMINATED")
    }

    
      setResetBtnClicked(true)
      setswitchonpress(false)
      setSwitchOnComplete(false)
  }
  
  const handleSwitchOnBtnClick =  async (e) => { 
    setswitchonpress(true);
    setSwitchOnComplete(false);
    setSwitchonerror(false)
    var formdata = {
      token: "",
      ruleType: "Sequence",
      ruleGroups: [],
      ruleSubGroups: [],
      rule: "",
      sequence: "ISOChuckMotorServoOnSeq",
      inputData: {},
    };
    try {
      await fetch(
        process.env.REACT_APP_VIA_RULE_ORCHES_API + "/api/Rules/ExecuteRule",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formdata),
        }
      ).then(response => response.json())
      .then(data => {
          const resultValue = data[0].resultValue;    
          if(resultValue!=null){
          console.log('resultValue print',resultValue);   
          // alert(resultValue)
             IOWrite('ISOChuckMotor_Running_InstanceId', resultValue);
          } 
      })
      // if (response.ok) {
      //   console.log("Alarm stopped");
       
      // } else {
      //   console.error("Failed to post data.");
       
      //   setSwitchonerror(true)
      // }
    } catch (error) {
      console.error("Error:", error);
      
      setSwitchonerror(true)
    }
  }
  const handleHomeButtonClick = async (e) => { 
    sethomePress(true)
    var formdata = {
      token: "",
      ruleType: "Sequence",
      ruleGroups: [],
      ruleSubGroups: [],
      rule: "",
      sequence: "ISOChuckMotorHomeSeq",
      inputData: {},
    };
    try {
      await fetch(
        process.env.REACT_APP_VIA_RULE_ORCHES_API + "/api/Rules/ExecuteRule",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formdata),
        }
      ).then(response => response.json())
      .then(data => {
          const resultValue = data[0].resultValue;    
          if(resultValue!=null){
          console.log('resultValue print',resultValue);   
          // alert(resultValue)
             IOWrite('ISOChuckMotor_Running_InstanceId', resultValue);
          } 
      })

      // if (response.ok) {
      //   console.log("Alarm stopped");
       
      // } else {
      //   console.error("Failed to post data.");
      //   sethomePress(false)
      // }
    } catch (error) {
      console.error("Error:", error);
      sethomePress(false)
    }
   }

  const handleProcessPosBtnClick = async (e) => { 
    setProcessPress(true)
    var formdata = {
      token: "",
      ruleType: "Sequence",
      ruleGroups: [],
      ruleSubGroups: [],
      rule: "",
      sequence: "ISOChuckMotorProcessPosSeq",
      inputData: {},
    };
    try {
      await fetch(
        process.env.REACT_APP_VIA_RULE_ORCHES_API + "/api/Rules/ExecuteRule",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formdata),
        }
      ).then(response => response.json())
      .then(data => {
          const resultValue = data[0].resultValue;
          console.log('resultValue',resultValue);        
          IOWrite('ISOChuckMotor_Running_InstanceId', resultValue);
          // setProcessPress(false)
      })

     
    } catch (error) {
      console.error("Error:", error);
      setProcessPress(false)
    }
  }
const handleTransferPosBtnClick = async (e) => { 
  setProcessPress(true)
  var formdata = {
    token: "",
    ruleType: "Sequence",
    ruleGroups: [],
    ruleSubGroups: [],
    rule: "",
    sequence: "ISOChuckMotorTransferPosSeq",
    inputData: {},
  };
  try {
     await fetch(
      process.env.REACT_APP_VIA_RULE_ORCHES_API + "/api/Rules/ExecuteRule",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formdata),
      }
    ) .then(response => response.json())
    .then(data => {
        const resultValue = data[0].resultValue;    
        if(resultValue!=null){
        console.log('resultValue print',resultValue);   
           IOWrite('ISOChuckMotor_Running_InstanceId', resultValue);
        } 
    })

    
  } catch (error) {
    console.error("Error:", error);
    setProcessPress(false)
  }
}

  const handleVelocityCheckbox = () => {
    let newVal = !checkedVelocity;
    setCheckedVelocity(newVal)
    if(newVal) {
      IOWrite('MAIN.Chuck_Motor.Out.Modes_of_operation', 3)
      
    } else {
      // IOWrite('MAIN.Chuck_Motor.Out.Modes_of_operation', 6)
    }
    
  }
  const handlevelocityvalue =(velocity)=>{
    IOWrite('MAIN.Chuck_Motor.Out.Target_velocity', velocity)
  }
  useEffect(() => {
    const fetChannelData = async () => {
      await fetchChannelsStates();
      subscribeToNotification();
    };
    fetChannelData();
   
    return () => {
      unSubscribe();
      
    };
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      settime(new Date())
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);
  return (
    <div className="flex px-4 py-6 gap-5">
      <div className="lg:w-5/12">
        <div className="w-full">
          <div className="text-xl font-bold mb-4">Control parameters {time?.toString}</div>

          <table className="border w-full">
            <thead className="bg-gray-800 text-white text-sm font-medium">
              <tr>
                <th className="border p-1 pl-2 text-left rounded-tl-xl">Parameter</th>
                <th className="border p-1 pl-2 text-left">Type</th>
                <th className="border p-1 pl-2 text-left w-28 rounded-tr-xl">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b-2 border-gray-300 tablebox hover:bg-gray-100 bg-gray-200">
                <td className="border p-1 pl-2 text-left">Process Position</td>
                <td className="border p-1 pl-2 text-left"></td>
                <td className="border p-1 pl-2 text-left  w-28">
                    <div label="ISOChuck_Motor_Config_Process_Position" 
                    className="px-1 w-full text-left bg-white h-7"
                    onClick={addEditControlParaVal}
                    data-channel="ISOChuck_Motor_Config_Process_Position"
                  > {channelState?.find(o => o.writeChannelName === "ISOChuck_Motor_Config_Process_Position")?.currentValue} 
                    </div>
                </td>
              </tr>
              <tr className="border-b-2 border-gray-300 tablebox hover:bg-gray-100 bg-gray-200">
                <td className="border p-1 pl-2 text-left">Transfer Position</td>
                <td className="border p-1 pl-2 text-left"></td>
                <td className="border p-1 pl-2 text-left  w-28">
                  {/* <div label="MAIN.Chuck_Motor.Out.Profile_target_position"
                      className="px-1 w-full text-left bg-white h-7"
                      // onClick={addEditControlParaVal}
                      data-channel="MAIN.Chuck_Motor.Out.Profile_target_position"
                    > 
                      <input type="text" value={transferPosition} onChange={(e)=>setTransferPosition(e.target.value)} 
            Place="Process Position"disabled={!switchOnComplete||checkedVelocity}
            className={` w-20 `}></input>
                    {channelState?.find(o => o.writeChannelName === "MAIN.Chuck_Motor.Out.Profile_target_position")?.currentValue } 
                    
                      </div> */}
                  <div label="ISOChuck_Motor_Config_Transfer_Position" 
                    className="px-1 w-full text-left bg-white h-7"
                    onClick={addEditControlParaVal}
                    data-channel="ISOChuck_Motor_Config_Transfer_Position"
                  > {channelState?.find(o => o.writeChannelName === "ISOChuck_Motor_Config_Transfer_Position")?.currentValue} 
                  </div>
                </td>
              </tr>
              {channellist && channellist.length > 0 && channellist?.map(
                (i, j) =>
                  i.direction == "OUT" &&
                  i.ioObject == "Chuck_Motor" && (
                    <tr key={i.channelName + j} className="border-b-2 border-gray-300 tablebox hover:bg-gray-100 bg-gray-200">
                      <td className="border p-1 pl-2 text-left" title={i.channelName}>
                        {i.channelName?.split(".").pop()}
                      </td>
                      <td className="border p-1 pl-2 text-left">{i.type}</td>
                      <td className="border p-1 pl-2 text-left w-28" >
                       
                        <div label={i.channelName} 
                          className="px-1 w-full text-left bg-white h-7"
                          onClick={addEditControlParaVal}
                          // defaultValue={i.data.value}
                          
                          data-channel={i.channelName}
                        > {channelState?.find(o => o.writeChannelName === i.channelName)?.currentValue || i.data.value} 
                        {/* {i.channelName} */}
                          </div>
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="px-2 lg:w-1/6 pt-14">
        {/* <MotorControls 
          checkedVelocity={checkedVelocity}
          handleCheckedVelocity={handleCheckedVelocity}
          increaseVelocity={increaseVelocity}
          addEditVelocityVal={addEditVelocityVal}
          velocity={velocity}
          decreaseVelocity={decreaseVelocity}
        /> */}
        <div
          id="recipe_select_list_holder"
          className="border border-black p-3 rounded-lg mb-5 w-full"
        >
          <span className="-mt-6 block bg-white px-2 border border-black ml-0 rounded-xl mb-2 w-36 text-center">
            Commands
          </span>
          <div className="grid grid-cols-1 gap-4">
            {/* <button 
              // disabled={motoOPState != 8} 
              className="bg-btnSecondary shadow-shadow100 py-2 rounded-lg hover:bg-white  font-bold transition-all disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-default">
              Ready
            </button> */}
            <button 
              data-channel="MAIN.Chuck_Motor.Out.Control_word"
              data-value={128}
              data-name="reset-btn"
              disabled={checkedVelocity}
              onClick={handleResetClick}
              // disabled={motoOPState != 8} 
              className="bg-btnSecondary shadow-shadow100 py-2 rounded-lg hover:bg-white  font-bold transition-all disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-default">
              Reset
            </button>
            <button 
              onClick={handleSwitchOnBtnClick}
              disabled={!resetBtnClicked||checkedVelocity||switchOnComplete}
              className={`${switchhitchonPress? 'bg-gray-200':'bg-btnSecondary hover:bg-white shadow-shadow100'}  py-2 rounded-lg  font-bold transition-all disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-default ${switchonerror && 'blink-bg'}`}>
              Servo On
            </button>
            <button 
              data-channel="MAIN.Chuck_Motor.Out.Control_word"
              data-value="31"
              disabled={!switchOnComplete||checkedVelocity||homePress||processPress||transferPress} 
              onClick={handleHomeButtonClick}
              className={`${homePress? 'bg-gray-200':'bg-btnSecondary hover:bg-white shadow-shadow100'}  py-2 rounded-lg  font-bold transition-all disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-default ${homeerror && 'blink-bg'}`}>
              Home
            </button>
            <button
              data-channel="MAIN.Chuck_Motor.Out.Control_word"
              data-value="15"
              onClick={handleProcessPosBtnClick}
              disabled={!switchOnComplete||checkedVelocity||homePress||processPress||transferPress} 
              className={`${processPress? 'bg-gray-200':'bg-btnSecondary hover:bg-white shadow-shadow100'}  py-2 rounded-lg  font-bold transition-all disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-default ${processerror && 'blink-bg'}`}>
              Process Position
            </button>
            <button   disabled={!switchOnComplete||checkedVelocity||homePress||processPress||transferPress} 
              onClick={handleTransferPosBtnClick}
             
              className={`${transferPress? 'bg-gray-200':'bg-btnSecondary hover:bg-white shadow-shadow100'}  py-2 rounded-lg  font-bold transition-all disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-default ${transfererror && 'blink-bg'}`}>
               Transfer Position
            </button> 
            <button 
              data-channel="MAIN.Chuck_Motor.Out.Control_word"
              data-value={128}
              data-name="reset-btn"
              onClick={handleButtonClick}
              disabled={!switchOnComplete||checkedVelocity} 
              className="bg-btnSecondary shadow-shadow100 py-2 rounded-lg hover:bg-white  font-bold transition-all disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-default">
              Stop
            </button>
          </div>
        </div>
        <div className="w-full">
          <div
            id="recipe_select_list_holder"
            className="border border-black p-3 rounded-lg mb-5"
          >

            <div className="flex items-center mb-2">
              <button
                onClick={handleVelocityCheckbox}
                className={`${
                  checkedVelocity ? "text-black" : "text-white"
                } flex justify-center items-center text-sm font-bold rounded-md hover:bg-gray-400 border-2 border-black h-5 w-5`}
              >
                ✓
              </button>
              <div className="text-sm font-bold inline-block pl-1">
                Velocity Mode
              </div>
            </div>

            <hr className="mb-3 border-2" />

            <button
              disabled={!checkedVelocity}
              onClick={increaseVelocity}
                className={`${!checkedVelocity ? 'bg-inactive text-slate-500' : 'bg-btnSecondary shadow-shadow100 hover:bg-white'} w-24 block mx-auto recipe_select_list_holder mb-2 font-bold text-xl px-2 py-1 rounded-lg transition-all`}
            >
              ▲
            </button>
            <div className="relative">
              {checkedVelocity?
              <div onClick={addEditVelocityVal}  
              className="bg-inactive  text-black border-2 border-black shadow-shadow100 mx-auto mb-2 text-center  font-bold text-xl px-2 py-1 rounded-lg w-24 block">
                {velocity}
              </div>
              :
              <div 
              className="bg-inactive border-transparent text-slate-500  hover:bg-white border-black mx-auto mb-2 text-center border-2 font-bold text-xl px-2 py-1 rounded-lg w-24 block">
                {velocity}
              </div>
              }
            </div>
            {/* <textarea rows={1}
              disabled={!checkedVelocity}
              onFocus={addEditVelocityVal}
              type="text"
              defaultValue={velocity}
              className={`${!checkedVelocity ? 'opacity-100 bg-inactive border-transparent text-slate-500' : 'shadow-shadow100 hover:bg-white border-black'} mx-auto mb-2 text-center border-2 font-bold text-xl px-2 py-1 rounded-lg w-24 block`}
            ></textarea> */}
            {/* {channelState?.find(o => o.name === "Chuck_MotorTarget_velocity").currentValue} */}
            
            
            <button
              disabled={!checkedVelocity}
              onClick={decreaseVelocity}
              className={`${!checkedVelocity ? 'bg-inactive text-slate-500' : 'bg-btnSecondary shadow-shadow100 hover:bg-white'} w-24 block mx-auto recipe_select_list_holder mb-2 font-bold text-xl px-2 py-1 rounded-lg transition-all`}
            >
              ▼
            </button>
          </div>
        </div>
      </div>

      <div className="w-5/12">
        <div className="text-xl font-bold mb-4">Status parameters</div>
        <table className="border w-full">
          <thead className="bg-gray-800 text-white text-sm font-medium">
            <tr>
              <th className="border p-1 pl-2 text-left rounded-tl-xl">Parameter</th>
              <th className="border p-1 pl-2 text-left">Type</th>
              <th className="border p-1 pl-2 text-left rounded-tr-xl">Value</th>
            </tr>
          </thead>
          <tbody>
            {channellist && channellist.length > 0 && channellist?.map(
              (i, j) =>
                i.direction == "IN" &&
                i.ioObject == "Chuck_Motor" && (
                  <tr key={j+i.channelName} className="border-b-2 border-gray-300 tablebox hover:bg-gray-100 bg-gray-200">
                    <td className="border p-1 pl-2 text-left">
                      {i.channelName?.split(".").pop()}
                    </td>
                    <td className="border p-1 pl-2 text-left">{i.type}</td>
                    <td className="border p-1 pl-2 text-left">
                      {/* {i.data.value}  */}
                      {/* {channelState?.find(o => o.readChannelName === i.channelName)?.currentValue || i.data.value}  */}
                      {channelState?.find(o => o.readChannelName === i.channelName)?.currentValue  || i.data.value} 
                    </td>
                  </tr>
                )
            )}
          </tbody>
        </table>
      </div>

      {controlParaValModal ? (<>
        <div className="fixed left-0 top-0 bottom-0 h-full w-full" style={{backgroundColor: 'rgba(255, 255, 255, 0.5)'}} ></div>
        <div className="fixed inline-block align-bottom rounded-2xl overflow-hidden border bg-white text-left shadow-2xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" style={{left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>
          <div className="px-4  py-2 bg-gray-800">
            <div className="relative">
              <h3
                className="text-base leading-6 font-medium text-white "
                id="headlessui-dialog-title-12"
              >
                Change parameter value
              </h3>
              <div className="absolute top-0 right-2">
                <button
                  className=" hover:text-themecolor-500 text-white"
                  tabIndex="0"
                  onClick={() => setControlParaValModal(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-7 w-7"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <div className="px-4">
            <div className="pt-4 px-3">
              <input
                type="text"
                name="name"
                className="py-2 px-3 text-sm mt-0 transition duration-150 ease-in-out  border-gray-100 bg-gray-200  rounded  w-full"
                placeholder="Value"
                required=""
                onChange={(e) => setUpdatedControlParaVal(e.target.value)}
                defaultValue={selectedControlParaVal}
              />
            </div>
          </div>
          <div className=" px-4 py-3 sm:px-6 flex justify-center mt-4">
            <button
              type="submit"
              onClick={updateControlParaVal}
              className="relative group text-sm font-semibold px-2 py-2 rounded-md shadow hover:shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 bg-themecolor-500 bg-themecolor-500 hover:bg-opacity-60 focus:ring-themecolor-100 text-appcolor-light1 hover:text-appcolor-light2 w-full bg-red-500 text-white"
            >
              Write
            </button>
          </div>
        </div>
        </>
      ) : (
        ""
      )}

{velocityModal ? (<>
        <div className="fixed left-0 top-0 bottom-0 h-full w-full" style={{backgroundColor: 'rgba(255, 255, 255, 0.5)'}} ></div>
        <div className="fixed inline-block align-bottom rounded-2xl overflow-hidden border bg-white text-left shadow-2xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" style={{left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>
          <div className="px-4  py-2 bg-gray-800">
            <div className="relative">
              <h3
                className="text-base leading-6 font-medium text-white "
                id="headlessui-dialog-title-12"
              >
                Change velocity value
              </h3>
              <div className="absolute top-0 right-2">
                <button
                  className=" hover:text-themecolor-500 text-white"
                  tabIndex="0"
                  onClick={() => setVelocityModal(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-7 w-7"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <div className="px-4">
            <div className="pt-4 px-3">
              <input
                type="text"
                name="name"
                className="py-2 px-3 text-sm mt-0 transition duration-150 ease-in-out  border-gray-100 bg-gray-200  rounded  w-full"
                placeholder="Value"
                required=""
                onChange={(e) => setUpdatedVelocityVal(e.target.value)}
                defaultValue={selectedVelocityVal}
              />
            </div>
          </div>
          <div className=" px-4 py-3 sm:px-6 flex justify-center mt-4">
            <button
              type="submit"
              onClick={updateVelocityVal}
              className="relative group text-sm font-semibold px-2 py-2 rounded-md shadow hover:shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 bg-themecolor-500 bg-themecolor-500 hover:bg-opacity-60 focus:ring-themecolor-100 text-appcolor-light1 hover:text-appcolor-light2 w-full bg-red-500 text-white"
            >
              Write
            </button>
          </div>
        </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}
