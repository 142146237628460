import React, { useEffect, useRef, useState, useReducer } from "react";
import { ChannelService, ChannelSubscriptionService } from "@getvia/sdk";
import Alarm from '../alarm/Alarm';
import Pumpdown from "./iec/pumpdown";
import Motor from "./Motor2";
import MotorControls from "./MotorControls";
import EmbossedBtn from "./EmbossedBtn";

const viaConfig = {
  channelServiceOptions: {
    apiToken: "test",
    baseUrl: process.env.REACT_APP_VIA_CORE_API,
    wsUrl: process.env.REACT_APP_VIA_NOTIFICATION_HUB
  },
};


const defaultGasBoxConfiguration = [
  
  {
    object: "ISOChmbrPrValve",
    name: "ISOChmbrPrValve",
    readChannelName: "MAIN.ISOChmbrPrValve.In.Open_fbk",
    writeChannelName: "MAIN.ISOChmbrPrValve.Out.Open_cmd",
    feedBackChannel: "MAIN.ISOChmbrPrValve.In.Open_fbk",
    currentValue: false,
  },
  {
    object: "ISON2VentValve",
    name: "ISON2VentValve",
    readChannelName: "MAIN.ISON2VentValve.In.Open_fbk",
    writeChannelName: "MAIN.ISON2VentValve.Out.Open_cmd",
    feedBackChannel: "MAIN.ISON2VentValve.In.Open_fbk",
    currentValue: false,
  },
  {
    object: "ISOChmbrBrValve",
    name: "ISOChmbrBrValve",
    readChannelName: "MAIN.ISOChmbrBrValve.In.Open_fbk",
    writeChannelName: "MAIN.ISOChmbrBrValve.Out.Open_cmd",
    feedBackChannel: "MAIN.ISOChmbrBrValve.In.Open_fbk",
    currentValue: false,
  },
  {
    object: "ISORoughLineValve",
    name: "ISORoughLineValve",
    readChannelName: "MAIN.ISORoughLineValve.In.Open_fbk",
    writeChannelName: "MAIN.ISORoughLineValve.Out.Open_cmd",
    feedBackChannel: "MAIN.ISORoughLineValve.In.Open_fbk",
    currentValue: false,
  },
  {
    object: "ISOThrottleValve",
    name: "ISOThrottleValve",
    readChannelName: "MAIN.ISOThrottleValve.In.Actual_Position",
    writeChannelName: "MAIN.ISOThrottleValve.Out.Target_Position",
    feedBackChannel: "MAIN.ISOThrottleValve.In.Actual_Position",
    currentValue: 0,
  },
  {
    object: "ISOThrottleValve",
    name: "ISOThrottleValveMode",
    readChannelName: "MAIN.ISOThrottleValve.In.Control_Mode",
    writeChannelName: "MAIN.ISOThrottleValve.Out.Control_Mode",
    feedBackChannel: "MAIN.ISOThrottleValve.In.Control_Mode",
    currentValue: 0,
  },
  {
    object: "ISORoughPump",
    name: "ISORoughPump",
    readChannelName: "MAIN.ISORoughPump.In.Running_fbk",
    writeChannelName: "MAIN.ISORoughPump.Out.On_cmd",
    feedBackChannel: "MAIN.ISORoughPump.In.Running_fbk",
    currentValue: false,
  },
  {
    object: "FinalValve",
    name: "FinalValve",
    readChannelName: "MAIN.FinalValve.In.Open_fbk",
    writeChannelName: "MAIN.FinalValve.Out.Open_cmd",
    feedBackChannel: "MAIN.FinalValve.In.Open_fbk",
    currentValue: false,
  },
  // {
  //   object: "RFPowerSupply",
  //   name: "RFPowerSupply", 
  //   readChannelName: "MAIN.RFPowerSupply",
  //   writeChannelName: "MAIN.RFPowerSupply",
  //   feedBackChannel: "MAIN.RFPowerSupply",
  //   currentValue: false,
  // },
  {
    object: "ISOBias_Power",
    name: "ISOBias_Power",
    feedBackChannel: "MAIN.ISOBias_Power.In.Actual_Setpoint",
    writeChannelName: "MAIN.ISOBias_Power.Out.Setpoint",
    currentValue: 1,
  },
  {
    object: "ISOBias_Power",
    name: "ISOBias_PowerOn",
    feedBackChannel: "MAIN.ISOBias_Power.In.ON_Requested_Indication",
    writeChannelName: "MAIN.ISOBias_Power.Out.RF_ON_Request",
    currentValue: false,
  },
  {
    object: "ISOChmbrPrGauge",
    name: "ISOChmbrPrGauge",
    feedBackChannel: "MAIN.ISOChmbrPrGauge.In.Sensor_Value_Module_1",
    currentValue: false,
  },
  {
    object: "RFMatch",
    name: "RFMatch",
    feedBackChannel: "MAIN.RF",
    currentValue: false,
  },
  {
    object: "RPSPowerSupply",
    name: "RPSPowerSupply",
    feedBackChannel: "MAIN.RPSPowerSupply.In.Actual_Setpoint",
    writeChannelName: "MAIN.RPSPowerSupply.Out.Setpoint",
    currentValue: 1,
  },
  {
    object: "RPSPowerSupply",
    name: "RPSPowerSupplyOn",
    feedBackChannel: "MAIN.RPSPowerSupply.In.ON_Requested_Indication",
    writeChannelName: "MAIN.RPSPowerSupply.Out.RF_ON_Request",
    currentValue: false,
  },
  {
    object: "ISOChmbrBr10TorrGauge",
    name: "ISOChmbrBr10TorrGauge",
    feedBackChannel: "MAIN.ISOChmbrBr10TorrGauge.In.Sensor_Value_Module_1",
    currentValue: false,
  },
  {
    object: "ISOChmbrBrPiraniGauge",
    name: "ISOChmbrBrPiraniGauge",
    feedBackChannel: "MAIN.ISOChmbrBrPiraniGauge.In.Sensor_Value_Module_1",
    currentValue: false,
  },
  {
    object: "ISOPiraniGuage",
    name: "ISOPiraniGuage",
    feedBackChannel: "MAIN.ISOPiraniGuage.In.Sensor_Value",
    currentValue: false,
  },  
  {
    object: "Alarm",
    name: "Alarm1",
    feedBackChannel: "Chamber_Failed_To_Reach_Pressure",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm2",
    feedBackChannel: "Failed_Roughing_Pressure",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm3",
    feedBackChannel: "Pump_Down_Error",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm4",
    feedBackChannel: "RoughLine_Pressure_Error",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm5",
    feedBackChannel: "RoughPump_Failure",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm6",
    feedBackChannel: "RoughValve_Close_Error",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm6",
    feedBackChannel: "RoughValve_Open_Error",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm6",
    feedBackChannel: "ThrottleValve_Open_Error",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm6",
    feedBackChannel: "Turbo_Online_Error",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm6",
    feedBackChannel: "No_Water_Flow_To_Pump",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm6",
    feedBackChannel: "Chamber_Failed_To_Reach_Pressure",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm6",
    feedBackChannel: "VentValve_Close_Error",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm6",
    feedBackChannel: "VentValve_Open_Error",
    currentValue: false,
  },
  {
    object: "Sequence",
    name: "SequenceLoopCounter",
    feedBackChannel: "LoopCounter",
    currentValue: 0,
  },
  {
    object: "Sequence",
    name: "SequenceLoopRemaining",
    feedBackChannel: "RemainingCount",
    currentValue: 0,
  },
  {
    object: 'Sequence',
    name: 'Sequence',
    readChannelName: 'Status_ISOEtchRecipeSequence', //to be changed to in channel
    writeChannelName: 'Status_ISOEtchRecipeSequence',
    feedBackChannel: 'Status_ISOEtchRecipeSequence', //to be changed to in channel
    currentValue: null
  },
  {
    object: "Sequence",
    name: "ISOEtchRecipeSequence_StepNum",
    feedBackChannel: "ISOEtchRecipeSequence_StepNum",
    currentValue: null,
  },
  {
    object: "Sequence",
    name: "ISOEtchRecipeSequence_StepTime",
    feedBackChannel: "ISOEtchRecipeSequence_StepTime",
    currentValue: null,
  },
  
 
  
  // {
  //   object: "DownValve",
  //   name: "DV1",
  //   readChannelName: "MAIN.DownStreamValve1.In.Open_fbk",
  //   writeChannelName: "MAIN.DownStreamValve1.Out.Open_cmd",
  //   feedBackChannel: "MAIN.DownStreamValve1.In.Open_fbk",
  //   currentValue: false,
  // },
  


  
  {
    object: "MFC",
    name: "MFC_SEQ_Cmd",
    feedBackChannel: "MFC_SEQ_Cmd",
    currentValue: null,
  },
  {
    object: "MFC",
    name: "MFC_SEQ_Next_Cmd",
    feedBackChannel: "MFC_SEQ_Next_Cmd",
    currentValue: null,
  },
  {
    object: "MFC",
    name: "MFC_SEQ_Name",
    feedBackChannel: "MFC_SEQ_Name",
    currentValue: null,
  }
];

const channelService = new ChannelService(viaConfig.channelServiceOptions);
const channelSubscriptionService = new ChannelSubscriptionService(viaConfig.channelServiceOptions);

const PumpdownReducer = (state, action) => {
  switch (action.type) {
    case "ON":
      return {
        ...state,
        value: true,
        channels: action.channels
      };
    case "OFF":
      return {
        ...state,
        value: false
      };
    default:
      return state;
  }
};


export default function IsotropicEtchChamber() {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const gasBoxConfiguration = useRef(defaultGasBoxConfiguration);
  const channels = useRef();
  const subscriptionRef = useRef();

  const [allChannels, setAllChannels] = useState([]);
  const [iecState, setIECState] = useState();
  const [notification, setNotification] = useState();
  const [alarm, dispatch] = useReducer(PumpdownReducer, { value: false, channels: [] });
  const [secondaryMenu, setSecondaryMenu] = useState('Motor');

  const [subscriptionForMotor, setSubscriptionForMotor] = useState([])
  const [shutOffValveModal, setShutOffValveModal] = useState(true)
  const [shutOffValveVal, setShutOffValveVal] = useState(null)

  useEffect(() => {
    // Function to update window height state when the window is resized
    const handleResize = () => {
      setWindowHeight(window.innerHeight - 500);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  

  const fetchChannelsStates = async () => {
    const response = await channelService.ChannelStates();
    channels.current = response;
    updateGasBoxState(channels.current);
  };
  const updateGasBoxState = (updatedChannels) => {

    let index = -1
    let gasBoxConfigurationState = gasBoxConfiguration.current;
    updatedChannels.forEach((item) => {
      const config = gasBoxConfigurationState.find((obj, objIndex) => {
        index = objIndex;
        return obj.feedBackChannel === item.channelName
      })
      if (config) {
        config.currentValue = item.data.value;
        gasBoxConfigurationState[index] = config;
      }
    })
    setAlarm();
    setIECState(() => gasBoxConfigurationState); //enable to get state on top box above diagram
  }

  const setAlarm = () => {
    let gasBoxConfigurationState = gasBoxConfiguration.current;
    const configs = gasBoxConfigurationState.filter((obj) => {
      return obj.object === "Alarm" && obj.currentValue === true
    })
    if (configs && configs.length > 0) {
      const alarmChannels = configs.flatMap(a => a.feedBackChannel);
      dispatch({ type: 'ON', channels: alarmChannels })
    } else {
      dispatch({ type: 'OFF' })
    }

  }
  const subscribeToNotification = () => {

    const onNewMessage = (message) => {
      if(message) {
        const channel = JSON.parse(message);
        console.log("channel-------->>>>>", channel)
        setNotification(() => channel)
        setSubscriptionForMotor(channel);
        updateGasBoxState([channel])
      }
    };

    let channels = [];

    gasBoxConfiguration.current.forEach(config => {
      channels.push(config.feedBackChannel);
    })
    const subscription = {
      channels: channels,
      onNewMessage: onNewMessage,
    };

    subscriptionRef.current = subscription;
    
    channelSubscriptionService.subscribe(subscription);
  };
  const unSubscribe = () => {
    channelSubscriptionService.unSubscribe(subscriptionRef.current);
  };
  
  useEffect(() => {
    const fetChannelData = async () => {
      await fetchChannelsStates();
      subscribeToNotification();
    };
    fetChannelData();
    return () => {
      unSubscribe();
    };
  }, []);


  const IOWrite = (channelName, value) => {
    if (channelName) {
      const channel = channels.current.find(obj => {
        return obj.channelName === channelName
      })
      
      if (channel) {
        channel.data.value = value;
        channelService.Write(channel).then(() => {
        });
      }
      
    }
  }

  const WriteToMFC = (config, value) => {
    const writeChannelName = config.writeChannelName;
    IOWrite(writeChannelName, value);
  }

  const WriteToValve = (config, value) => {
    const writeChannelName = config.writeChannelName;
    
    IOWrite(writeChannelName, value);
  }

  const [selectedValve, setselectedValve] = useState(null);
  const [labelval, setLabel] = useState(null);
  const [Valvetitle, setValvetitle] = useState(null);
  const [selectedMfc, setselectedMfc] = useState(null);
  const [mfcvalue, setmfcvalue] = useState(null);

  const handleClick = (value) => {
    const config = gasBoxConfiguration.current.find((obj) => {
      return obj.name === selectedValve
    });  
    

    WriteToValve(config, Number(value))
    if(selectedValve=='ISOThrottleValve') {
      const config = gasBoxConfiguration.current.find((obj) => {
        return obj.name === 'ISOThrottleValveMode'
      }); 
      WriteToValve(config, 1)
    }
    WriteToValve(config, value)
    // setBulbs(prevBulbs => (
    //   prevBulbs.map(bulb => {
    //     if (bulb.id === selectedValve) {
    //       return { ...bulb, isOn: !bulb.isOn };
    //     }
    //     return bulb;
    //   })
    // ));
    setselectedValve(null)
    setShutOffValveVal('')
    // setValvevalue(null)
  };

  const handleSecondaryMenu = (name) => {
    setSecondaryMenu(name)
  }

  const handleMfcvalue = () => {
    const config = gasBoxConfiguration.current.find((obj) => {
      return obj.name === selectedMfc
    })
    debugger
    WriteToMFC(config, mfcvalue);
    if(selectedMfc=='RPSPowerSupply') {
      const config = gasBoxConfiguration.current.find((obj) => {
        return obj.name === 'RPSPowerSupplyOn'
      }); 
      if(parseFloat(mfcvalue)==0)
      WriteToMFC(config, false)
      else if(parseFloat(mfcvalue)>0)
      WriteToMFC(config, true)
    }
    if(selectedMfc=='ISOBias_Power') {
      const config = gasBoxConfiguration.current.find((obj) => {
        return obj.name === 'ISOBias_PowerOn'
      }); 
      if(parseFloat(mfcvalue)==0)
      WriteToMFC(config, false)
      else if(parseFloat(mfcvalue)>0)
      WriteToMFC(config, true)
    }
    setselectedMfc(null);
    setmfcvalue(null)
  }
  useEffect(() => {
    const interval = setTimeout(() => {
      window.location.reload();
    }, 5 * 60 * 1000); // 10 minutes in milliseconds

    return () => clearInterval(interval); // Cleanup function to clear the timeout when component unmounts
  }, []); // Empty dependency array to run the effect only once when the component mounts
  return (
    <div className="flex flex-col items-center w-full h-full overflow-x-hidden">
      <div className="flex gap-5 w-full mt-5">
        
      <div className="flex-1 mx-2 relative">
        
        <div className="relative overflow-hidden ">
{/* viewBox="0 0 2000 857" viewBox="0 0 2000 2000"*/}
<svg xmlns="http://www.w3.org/2000/svg" className="svgmani relative svg-main" viewBox="360 60 1000 1090" style={{
  width: "100%",
  height: "calc(100vh - 100px)"
}}>
 <defs>
  <linearGradient id="svg_2" spreadMethod="pad">
   <stop id="jq_stop_5747" offset="0" stopColor="#7c7c7c"/>
   <stop id="jq_stop_3575" offset="0.54" stopColor="#ffffff" stopOpacity="0.99"/>
   <stop id="jq_stop_4128" offset="1" stopColor="#7c7c7c"/>
  </linearGradient>
  <linearGradient id="svg_13" x1="0" x2="0" y1="0" y2="1">
   <stop id="jq_stop_8641" offset="0" stopColor="#8e8e8e"/>
   <stop id="jq_stop_9692" offset="0.46" stopColor="#ffffff"/>
   <stop id="jq_stop_2555" offset="1" stopColor="#717171"/>
  </linearGradient>
  <linearGradient id="svg_16" x1="0" x2="1" y1="0" y2="0">
   <stop id="jq_stop_7894" offset="0" stopColor="#d36600"/>
   <stop id="jq_stop_7890" offset="0.44" stopColor="#ffdab7" stopOpacity="0.99"/>
   <stop id="jq_stop_1746" offset="1" stopColor="#d36600"/>
  </linearGradient>
  <linearGradient id="svg_23" spreadMethod="pad" x1="0" x2="1" y1="0" y2="0">
   <stop id="jq_stop_2241" offset="0" stopColor="#c4c4c4"/>
   <stop id="jq_stop_7442" offset="0.27" stopColor="#ffffff" stopOpacity="0.99"/>
   <stop id="jq_stop_7101" offset="0.6" stopColor="#ffffff" stopOpacity="0.99"/>
   <stop id="jq_stop_2183" offset="1" stopColor="#c4c4c4"/>
  </linearGradient>
  <filter height="200%" id="svg_24_blur" width="200%" x="-50%" y="-50%">
   <feGaussianBlur in="SourceGraphic" stdDeviation="7.5"/>
  </filter>
  <linearGradient id="svg_10">
   <stop id="jq_stop_1536" offset="0" stopColor="#4c4c4c"/>
   <stop id="jq_stop_5476" offset="1" stopColor="#4c4c4c"/>
  </linearGradient>
  <linearGradient id="svg_22" x1="0" x2="1" y1="0" y2="0">
   <stop id="jq_stop_1818" offset="0" stopColor="#00ffff"/>
   <stop id="jq_stop_4397" offset="1" stopColor="#00ffff"/>
  </linearGradient>
  <marker id="mkr_end_svg_109" markerHeight="5" markerUnits="strokeWidth" markerWidth="5" orient="auto" refX="50" refY="50" se_type="rightarrow" viewBox="0 0 100 100">
   <path d="m100,50l-100,40l30,-40l-30,-40l100,40z" fill="#000000" id="svg_157" stroke="#000000" strokeWidth="10"/>
  </marker>
  <linearGradient id="svg_112" x1="0" x2="1" y1="0" y2="0">
   <stop id="svg_111" offset="0" stopColor="#919191" stopOpacity="0.99"/>
   <stop id="svg_150" offset="1" stopColor="#000000" stopOpacity="0.99"/>
  </linearGradient>
  <linearGradient id="svg_120" x1="0" x2="1" y1="0" y2="0">
   <stop id="svg_119" offset="0" stopColor="#aaffaa"/>
   <stop id="svg_153" offset="0.97" stopColor="#aaff56"/>
  </linearGradient>
  <linearGradient id="svg_125" x1="0" x2="1" y1="0" y2="0">
   <stop id="svg_124" offset="0" stopColor="#aaaaff"/>
   <stop id="svg_154" offset="0.97" stopColor="#9696ff"/>
  </linearGradient>
  <linearGradient id="svg_156" x1="0" x2="0" y1="0" y2="1">
   <stop id="svg_98" offset="0.03" stopColor="#007fff" stopOpacity="0.99"/>
   <stop id="svg_99" offset="0.46" stopColor="#ffffff"/>
   <stop id="svg_100" offset="0.91" stopColor="#007fff" stopOpacity="0.99"/>
  </linearGradient>
  <radialGradient cx="0.5" cy="0.5" id="svg_178" r="0.5" spreadMethod="pad">
   <stop id="jq_stop_4607" offset="0" stopColor="#ffc9c9" stopOpacity="0.99"/>
   <stop id="jq_stop_7041" offset="1" stopColor="#ff0000"/>
  </radialGradient>
  <linearGradient id="svg_186" spreadMethod="pad" x1="0" x2="1" y1="0" y2="0">
   <stop id="svg_29" offset="0" stopColor="#91a9bc"/>
   <stop id="svg_31" offset="0.27" stopColor="#ffffff" stopOpacity="0.99"/>
   <stop id="svg_35" offset="0.67" stopColor="#c1ebff" stopOpacity="0.98"/>
   <stop id="svg_39" offset="1" stopColor="#91a9bc"/>
  </linearGradient>
  <filter id="svg_40_blur">
   <feGaussianBlur in="SourceGraphic" stdDeviation="1"/>
  </filter>
  <radialGradient cx="0.5" cy="0.5" id="svg_45" r="0.5" spreadMethod="pad">
   <stop id="jq_stop_5029" offset="0" stopColor="#ffa8a8" stopOpacity="0.99"/>
   <stop id="jq_stop_9292" offset="1" stopColor="#ff0000" stopOpacity="0.99"/>
  </radialGradient>
  <linearGradient id="svg_197" x1="0" x2="1" y1="0" y2="0">
   <stop id="jq_stop_9313" offset="0" stopColor="#969696"/>
   <stop id="jq_stop_8722" offset="1" stopColor="#d1d1d1"/>
  </linearGradient>
  <linearGradient id="svg_215">
   <stop id="jq_stop_3175" offset="0" stopColor="#9e9e9e"/>
   <stop id="jq_stop_3128" offset="1" stopColor="#616161"/>
  </linearGradient>
  <linearGradient id="svg_219" x1="1" x2="0" y1="1" y2="0">
   <stop id="svg_26" offset="0" stopColor="#9e9e9e"/>
   <stop id="jq_stop_6524" offset="0.52" stopColor="#bfbfbf"/>
   <stop id="svg_27" offset="1" stopColor="#616161"/>
  </linearGradient>
  <radialGradient cx="0.5" cy="0.5" id="svg_239" r="0.5" spreadMethod="pad">
   <stop id="svg_20" offset="0" stopColor="#fff2f2" stopOpacity="0.99"/>
   <stop id="svg_25" offset="1" stopColor="#ff0000" stopOpacity="0.99"/>
  </radialGradient>
  <filter id="svg_242_blur">
   <feGaussianBlur in="SourceGraphic" stdDeviation="3"/>
  </filter>
  <linearGradient id="svg_296" spreadMethod="pad" x1="0" x2="1" y1="0" y2="0">
   <stop id="svg_1" offset="0" stopColor="#ffd4aa"/>
   <stop id="svg_3" offset="0" stopColor="#fcb674"/>
   <stop id="svg_4" offset="0.46" stopColor="#fcb674"/>
   <stop id="svg_5" offset="1" stopColor="#ffd4aa"/>
  </linearGradient>
  <marker id="mkr_end_svg_298" markerHeight="5" markerUnits="strokeWidth" markerWidth="5" orient="auto" refX="50" refY="50" se_type="rightarrow" viewBox="0 0 100 100">
   <path d="m100,50l-100,40l30,-40l-30,-40l100,40z" fill="#000000" id="svg_30" stroke="#000000" strokeWidth="10"/>
  </marker>
  <radialGradient cx="0.5" cy="0.5" id="svg_312" r="0.5" spreadMethod="pad">
   <stop id="svg_18" offset="0" stopColor="#189100" stopOpacity="0.98"/>
   <stop id="svg_19" offset="1" stopColor="#2aff00"/>
  </radialGradient>
  <filter height="200%" id="svg_96_blur" width="200%" x="-50%" y="-50%">
   <feGaussianBlur in="SourceGraphic" stdDeviation="7.5"/>
  </filter>
  <filter height="200%" id="svg_147_blur" width="200%" x="-50%" y="-50%">
   <feGaussianBlur in="SourceGraphic" stdDeviation="8.5"/>
  </filter>
  <linearGradient id="svg_177" x1="0" x2="0" y1="0" y2="1">
   <stop id="svg_32" offset="0" stopColor="#545454"/>
   <stop id="svg_34" offset="0.46" stopColor="#ffffff"/>
   <stop id="svg_43" offset="1" stopColor="#545454"/>
  </linearGradient>
  <linearGradient id="svg_179" x1="0" x2="0" y1="0" y2="1">
   <stop id="svg_33" offset="0" stopColor="#666666"/>
   <stop id="svg_41" offset="0.46" stopColor="#ffffff"/>
   <stop id="svg_44" offset="1" stopColor="#666666"/>
  </linearGradient>
  <radialGradient cx="0.5" cy="0.5" id="svg_58" r="0.5" spreadMethod="pad">
   <stop id="svg_15" offset="0" stopColor="#c9ffc9" stopOpacity="0.99"/>
   <stop id="svg_51" offset="1" stopColor="#00b200"/>
  </radialGradient>
  <marker id="mkr_end_svg_71" markerHeight="5" markerUnits="strokeWidth" markerWidth="5" orient="auto" refX="50" refY="50" se_type="rightarrow" viewBox="0 0 100 100">
   <path d="m100,50l-100,40l30,-40l-30,-40z" strokeWidth="10"/>
  </marker>
  <radialGradient cx="0.5" cy="0.5" id="svg_87" r="0.5" spreadMethod="pad">
   <stop id="jq_stop_8137" offset="0" stopColor="#aaffaa" stopOpacity="1"/>
   <stop id="jq_stop_4612" offset="1" stopColor="#007f00" stopOpacity="1"/>
  </radialGradient>
  <radialGradient cx="0.5" cy="0.5" id="tubegreen" r="0.5" spreadMethod="pad">
   <stop id="jq_stop_8137" offset="0" stopColor="#00ff00" stopOpacity="1"/>
   <stop id="jq_stop_4612" offset="1" stopColor="#4fc700" stopOpacity="1"/>
  </radialGradient>
 </defs>
 <g className="layer">
  <title>Layer 1</title>
  <g id="svg_79">
   <rect fill="#9b9b9b" height="198.7" id="svg_80" rx="3" ry="3" stroke="#8ec8ff" strokeLinejoin="round" width="23" x="881.35" y="531.11"/>
  </g>
  <rect className="pipe" fill="url(#tubegreen)" height="8" id="svg_61" rx="5" ry="5" stroke="#a0a0a0" transform="matrix(1 0 0 1 0 0) matrix(-0.00519301 1.03832 -2.39918 0.0370794 -626.033 -708.144)" width="150" x="1216.98" y="-662.02"/>
  <g id="svg_17" transform="matrix(1 0 0 1 0 0)">
   <rect fill="url(#svg_16)" height="346.02" id="svg_11" stroke="#93c7ff" strokeLinejoin="round" strokeWidth="0" width="42.5" x="674.84" y="532.1"/>
   {/* <rect className="pipe" fill={iecState?.find(o => o.name === "ISORoughLineValve").currentValue ?"url(#tubegreen)":'url(#svg_13)'}
   height="8" id="svg_7" stroke="#93c7ff" strokeWidth="0" transform="matrix(-0.00578855 2.30919 -2.67434 0.0824634 -382.288 -840.199)" width="150" x="608.78" y="-408.66"/> */}
  <rect className="pipe"  fill={iecState?.find(o => o.name === "ISOThrottleValve").currentValue>0 ?"url(#tubegreen)":'url(#svg_13)'}
  height="8" id="svg_7" stroke="#93c7ff" strokeWidth="0" transform="matrix(-0.00578855 1.87583 -2.67434 0.0669878 -382.288 -435.463)" width="150" x="580.47" y="-408.5"/>
  <rect className="pipe" fill="url(#tubegreen)" height="8" id="pipefromchamber" stroke="#93c7ff" 
  strokeWidth="0" transform="matrix(-0.00578855 0.312952 -2.67434 0.0111758 -382.288 474.79)" width="150" x="221.9" y="-408.5"/>
  </g>
  <g id="svg_36">
   <rect fill="#ffaaff" height="28" id="svg_37" rx="7" ry="7" stroke="#ff00ff" width="35" x="910.1" y="289.64"/>
   <text fill="#000000" fontFamily="Sans-serif" fontSize="20" id="svg_38" stroke="#ff00ff" strokeWidth="0" textAnchor="middle" 
    x="926.56"   y="310.68">TC</text>
  </g>
  <g id="svg_187">
   <rect fill="#ffaaff" height="31.23" id="svg_188" rx="7" ry="7" stroke="#ff00ff" width="39.84" x="722.47" y="528.89"/>
   <text fill="#000000" fontFamily="Sans-serif" fontSize="20" id="svg_189" stroke="#ff00ff" strokeWidth="0" textAnchor="middle" 
   transform="matrix(0.782536 0 0 0.831034 451.204 -2.88151)" x="742.86"   y="557.3">TC</text>
  </g>
  <image height="94.57" id="svg_220" width="26.91" x="620.46" href="components/slitvalve.png" y="384.03"/>
  <g id="svg_190">
   <rect fill="#ffaaff" height="32.84" id="svg_191" rx="7" ry="7" stroke="#ff00ff" width="35" x="611.95" y="477.55"/>
   <text fill="#000000" fontFamily="Sans-serif" fontSize="24" id="svg_192" stroke="#ff00ff" strokeWidth="0" textAnchor="middle" 
   transform="matrix(0.687491 0 0 0.735521 426.731 38.2772)" x="291.25"   y="628.75">TC</text>
  </g>
  <g id="svg_193">
   <rect fill="#ffaaff" height="32.84" id="svg_194" rx="7" ry="7" stroke="#ff00ff" width="35" x="1015.12" y="478.05"/>
   <text fill="#000000" fontFamily="Sans-serif" fontSize="24" id="svg_195" stroke="#ff00ff" strokeWidth="0" textAnchor="middle" 
   transform="matrix(0.754589 0 0 0.732306 467.178 -129.44)" x="750.58"   y="859.94">TC</text>
  </g>
  <rect fill="#000000" filter="url(#svg_96_blur)" height="233.17" id="svg_97" opacity="0.7" rx="27" ry="27" stroke="url(#svg_197)" strokeLinejoin="round" strokeWidth="0" width="383.9" x="647.3" y="311.17"/>
  <rect className="pipe" fill={iecState?.find(o => o.name === "ISOChmbrPrValve").currentValue ?"url(#tubegreen)":'url(#svg_13)'}
  height="8" id="svg_28" rx="4" ry="4" stroke="#93c7ff" strokeWidth="0" transform="matrix(-3.3478 -0.00542623 -0.119554 -2.50694 2151.89 148.492)" width="150" x="452.1" y="-93.4"/>
  <g id="svg_12">
   <rect fill="url(#svg_10)" height="215.36" id="svg_21" rx="27" ry="27" stroke="url(#svg_197)" strokeLinejoin="round" strokeWidth="8" width="370.04" x="644.87" y="319.12"/>
   <rect fill="url(#svg_186)" filter="url(#svg_24_blur)" height="198.32" id="svg_24" rx="20" ry="20" stroke="url(#svg_197)" strokeLinejoin="round" strokeWidth="0" width="340.73" x="660.04" y="332.29"/>
  </g>
  <g id="svg_288" transform="translate(0, 00)"> 
   <g id="svg_9">
   <text fill="#000000" fontFamily="Sans-serif" fontSize="27" fontWeight="normal" id="svg_94" stroke="#b5b5b5" strokeLinejoin="round" strokeWidth="0" 
   textAnchor="middle" x="817.47"   y="415.32">Wafer</text>
   <rect className="pipe" fill="white" height="9" id="svg_14" rx="4" ry="4" stroke="#8ec8ff" strokeLinejoin="round" width="140" x="752.69" y="425.23"/>
    <rect fill="url(#svg_2)" height="27" id="svg_6" stroke="#b2b2b2" strokeLinejoin="round" strokeWidth="0" width="198" x="722.25" y="440.41"/>
    <rect fill="url(#svg_2)" height="219.95" id="svg_8" stroke="#b5b5b5" strokeLinejoin="round" strokeWidth="0" width="37.21" x="800.94" y="467.95"/>
   </g>
   <text fill="#000000" fontFamily="Sans-serif" fontSize="24" fontWeight="bold" id="svg_85" stroke="#b5b5b5" strokeLinejoin="round" strokeWidth="0" 
   textAnchor="middle" x="764.06"   y="461.93">Chuck</text>
  </g>
  <rect fill="#ffffff" height="196.39" id="svg_287" stroke="#8ec8ff" strokeLinejoin="round" strokeWidth="0" transform="matrix(1 0 0 1 0 0)" width="142.17" x="752.82" y="732.41"/>

  <rect className="pipe" fill="url(#svg_13)" height="8" id="svg_48" stroke="#93c7ff" strokeWidth="0" transform="matrix(-0.00552071 0.385958 -2.55059 0.0137829 -509.401 330.083)" width="150" x="-173.73" y="-523.64"/>
  <rect className="pipe" fill="url(#svg_13)" height="8" id="svg_60" stroke="#93c7ff" strokeWidth="0" transform="rotate(90 1.12631e+6 -3.10793e+6) matrix(-0.00216315 1.50589 -0.999383 0.0537771 306.742 -151.17)" width="150" x="378.31" y="-829.98"/>
  <rect className="pipe" 
    fill={iecState?.find(o => o.name === "ISON2VentValve").currentValue ?"url(#tubegreen)":'url(#svg_13)'}
  height="8" id="svg_62" stroke="#93c7ff" strokeWidth="0" transform="matrix(-2.20966 -0.00528709 -0.0789091 -2.44265 2426.94 -1126.5)" width="150" x="513.18" y="-650.49"/>
  <rect className="pipe" fill="url(#tubegreen)" height="8" id="svg_63" rx="5" ry="5" stroke="#93c7ff" strokeWidth="0" transform="matrix(1 0 0 1 0 0) matrix(-1.17995 -0.0055786 -0.0421376 -2.57734 1867.44 -841.719)" width="150" x="662.3" y="-600.9"/>
  <rect className="pipe"  fill={iecState?.find(o => o.name === "ISORoughLineValve").currentValue ?"url(#tubegreen)":'url(#svg_13)'} height="8" id="svg_64" rx="5" ry="5" stroke="#93c7ff" strokeWidth="0" transform="matrix(-2.91505 -0.00524706 -0.1041 -2.42416 3206.99 -631.981)" width="124" x="642.69" y="-510.45"/>
  <rect className="pipe" fill="url(#tubegreen)" height="8" id="svg_64b" rx="5" ry="5" stroke="#93c7ff" strokeWidth="0" width="24" x="769.69" y="-510.45" transform="matrix(-2.91505 -0.00524706 -0.1041 -2.42416 3206.99 -631.981)"></rect>
  <g id="svg_82">
   <rect fill="#9b9b9b" height="52.14" id="svg_83" rx="3" ry="3" stroke="#8ec8ff" strokeLinejoin="round" width="85.32" x="771.47" y="681.26"/>
   <rect fill="url(#svg_23)" filter="url(#svg_24_blur)" height="48.02" id="svg_84" rx="20" ry="20" stroke="#8ec8ff" strokeLinejoin="round" width="62.5" x="783.4" y="683.02"/>
  </g>
  <rect className="pipe" fill="url(#svg_156)" height="16.82" id="svg_276" rx="8" ry="8" stroke="#0067d6" strokeWidth="0" transform="matrix(1 0 0 1 0 0)" width="522.67" x="806.12" y="991.58"/>
  <rect className="pipe" fill="url(#svg_156)" height="12" id="svg_277" rx="5" ry="5" stroke="#93c7ff" strokeWidth="0" transform="matrix(1 0 0 1 0 0) matrix(0 1.28728 -1.25084 0 1990.9 -284.144)" width="251.37" x="749.38" y="935.06"/>



  <text fill="#000000" fontFamily="Sans-serif" fontSize="27" fontWeight="normal" id="svg_130" stroke="#b5b5b5" strokeLinejoin="round" strokeWidth="0" textAnchor="middle" 
   x="565.27"   y="440.27">Slit Valve</text>
  <g id="svg_138">
   <rect fill="url(#svg_120)" height="93" id="svg_118" stroke="#93c7ff" width="65" x="722.47" y="681.23"/>
   <rect fill="blue" height="33" id="svg_118" stroke="black" width="65" x="722.47" y="735"/>
   <text fill="#000000" fontFamily="Sans-serif" fontSize="22" id="svg_121" stroke="#93c7ff" strokeWidth="0" textAnchor="middle" x="752.47"   y="704.23">RF</text>
   <text fill="#000000" fontFamily="Sans-serif" fontSize="22" id="svg_122" stroke="#93c7ff" strokeWidth="0" textAnchor="middle" x="753.47"   y="727.23">Match</text>
   <text fill="#fff" fontFamily="Sans-serif" fontSize="22" fontWeight="900" id="svg_122" stroke="#93c7ff" strokeWidth="0" textAnchor="middle" x="753.47"   y="757.23">{iecState?.find(o => o.name === "RFMatch").currentValue}</text>
  </g>
  <rect className="pipe" fill="url(#svg_156)" height="19.49" id="svg_161" stroke="#93c7ff" strokeWidth="0" transform="matrix(1 0 0 1 0 0)" width="366.41" x="1017.16" y="337.92"/>
  <rect className="pipe" fill="url(#svg_156)" height="19.25" id="svg_162" rx="9" ry="9" stroke="#2d92ff" width="86.67" x="1009.63" y="368.89"/>
  <rect className="pipe" fill="url(#svg_156)" height="8" id="svg_163" rx="5" ry="5" stroke="#93c7ff" strokeWidth="0" transform="matrix(0 1.06415 -2.35526 0 2493.41 -466.449)" width="26.75" x="765.52" y="592.6"/>
  <rect className="pipe" fill="url(#svg_156)" height="18.74" id="coolingwater" stroke="#2d92ff" transform="matrix(1 0 0 1 0 0)" width="232.19" x="521.85" y="198.45"/>
  <rect className="pipe" fill="#ff7f00" height="8" id="svg_174" stroke="#a0a0a0" transform="matrix(1 0 0 1 0 0) matrix(-0.00102316 1.95929 -0.472702 0.0699687 547.521 -2119.38)" width="135" x="1492.47" y="-443.14"/>
  <rect className="pipe" fill="#ff7f00" height="8" id="svg_175" stroke="#a0a0a0" transform="matrix(-0.00102316 0.225456 -0.472702 0.0080513 547.521 644.5)" width="150" x="187.97" y="-569.71"/>
  <g id="valve1" onClick={() => { setselectedValve("ISOChmbrPrValve"); setValvetitle('Manometer Valve') }}>
   <title id="svg_181">valve1</title>
   {iecState?.find(o => o.name === "ISOChmbrPrValve").currentValue ?  
   <>
    <path d="m427.34,347.19l36.94,22.98l-37.01,22.86l0.07,-45.86l0,0.02z" fill="url(#svg_312)" id="svg_182" stroke="#0baa00" strokeWidth="3"/>
    <path d="m501.07,392.89l-36.97,-22.92l36.97,-22.92l0,45.86l0,-0.02z" fill="url(#svg_312)" id="svg_183" stroke="#0baa00" strokeWidth="3"/>
    <ellipse cx="463.55" cy="369.93" fill="url(#svg_312)" id="svg_184" rx="12.68" ry="13.76" stroke="#0baa00" strokeWidth="3"/>
   </>:<>
    <path d="m427.34,347.19l36.94,22.98l-37.01,22.86l0.07,-45.86l0,0.02z" fill="url(#svg_112)" id="svg_182" stroke="#7697b4" strokeWidth="3"/>
    <path d="m501.07,392.89l-36.97,-22.92l36.97,-22.92l0,45.86l0,-0.02z" fill="url(#svg_112)" id="svg_183" stroke="#7697b4" strokeWidth="3"/>
    <ellipse cx="463.55" cy="369.93" fill="url(#svg_112)" id="svg_184" rx="12.68" ry="13.76" stroke="#7697b4" strokeWidth="3"/>
   </>
   }
  </g>
  <g id="valve2" transform="matrix(1 0 0 1 0 0)"  onClick={() => { setselectedValve("ISON2VentValve"); setValvetitle('Chamber vent N2') }}>
   <title>valve2</title>
   {iecState?.find(o => o.name === "ISON2VentValve").currentValue ? 
   <>
    <path d="m1169.43,471.54l-36.47,-22.23l36.47,-22.23l0,44.48l0,-0.01l0,-0.01z" fill="url(#svg_312)" id="svg_116" stroke="#0baa00" strokeWidth="3"/>
    <path d="m1137.97,471.3l-36.47,-22.23l36.47,-22.23l0,44.48l0,-0.01l0,-0.01z" fill="url(#svg_312)" id="svg_173" stroke="#0baa00" strokeWidth="3" transform="rotate(180 1119.9 449.08)"/>
    <ellipse cx="1136.41" cy="450.45" fill="url(#svg_312)" id="svg_117" rx="12.51" ry="13.35" stroke="#0baa00" strokeWidth="3"/>
  </>
   :
   <>
    <path d="m1169.43,471.54l-36.47,-22.23l36.47,-22.23l0,44.48l0,-0.01l0,-0.01z" fill="url(#svg_112)" id="svg_116" stroke="#7697b4" strokeWidth="3"/>
    <path d="m1137.97,471.3l-36.47,-22.23l36.47,-22.23l0,44.48l0,-0.01l0,-0.01z" fill="url(#svg_112)" id="svg_173" stroke="#7697b4" strokeWidth="3" transform="rotate(180 1119.9 449.08)"/>
    <ellipse cx="1136.41" cy="450.45" fill="url(#svg_112)" id="svg_117" rx="12.51" ry="13.35" stroke="#7697b4" strokeWidth="3"/>
   </>
   }
  </g>
  <g id="svg_199" transform="matrix(1 0 0 1 0 0)">
   <rect fill="url(#svg_16)" height="226.64" id="svg_200" stroke="#93c7ff" strokeLinejoin="round" strokeWidth="0" transform="matrix(0 1.35722 -0.337579 0 814.378 540.88)" width="25" x="275.09" y="1022.89"/>
   <rect className="pipe" 
   fill={iecState?.find(o => o.name === "ISORoughPump").currentValue ?"url(#tubegreen)":'url(#svg_13)'}
   height="8" id="svg_201" stroke="#93c7ff" strokeWidth="0" transform="matrix(-0.508357 -0.00437469 -0.018154 -2.02113 865.482 943.379)" width="150" x="779.68" y="-0.11"/>
  </g>
  <image height="150.75" id="svg_202" width="168" x="735.32" href="components/rps.png" y="143.19" 
  onClick={() => { setselectedMfc("RPSPowerSupply"); setmfcvalue(iecState?.find(o => o.name === "RPSPowerSupply").currentValue) }}/>
  {/* onClick={() => { setselectedValve("RPSPowerSupply"); setValvetitle('RPS Power') }}/> */}

  <text fill="#000000" fontFamily="Sans-serif" fontSize="24" id="svg_203" stroke="#93c7ff" strokeWidth="0" textAnchor="middle" 
   x="780.16"   y="160.62">RPS</text>

    <text fill={iecState?.find(o => o.name === "RPSPowerSupply").currentValue ? '#0005ff':'red'} 
    font-family="Sans-serif" font-weight="900" font-size="24" id="svg_203" stroke="#93c7ff" stroke-width="0" text-anchor="middle" 
   x="775.16"   y="190.62">{iecState?.find(o => o.name === "RPSPowerSupply").currentValue>0 ? 'On'  :'Off' }</text>
  <g id="svg_92" onClick={() => { setselectedValve("ISORoughPump"); setValvetitle('ISORoughPump') }}>
   <image height="143.25" id="svg_176" width="257.64" x="462.14" href="components/pump.png" y="860.84"/>
   <g className="roughPump" id="roughPump1">
    <rect fill="#20206b" height="55.46" id="svg_198" stroke="#8ec8ff" strokeLinejoin="round" width="64.7" x="550.27" y="904.2"/>
    <text className="pumpText" fill="#ffffff" fontFamily="Sans-serif" fontSize="27" id="pumpText" stroke="#000000" strokeWidth="0" textAnchor="middle" 
     x="572.66"   y="953">
    {iecState?.find(o => o.name === "ISORoughPump").currentValue ? 'On'  :'Off' }
      </text>
    <g className="pumpBulb" id="pumpBulb">
    {iecState?.find(o => o.name === "ISORoughPump").currentValue ?  <>
    <ellipse cx="597.91" cy="918.67" fill="#46ff3d" filter="url(#svg_40_blur)" id="svg_40" opacity="0.55" rx="11.54" ry="11.54"  stroke="#000000" strokeWidth="0"/>
     <ellipse cx="597.85" cy="919.39" fill="url(#svg_58)" id="svg_42"  rx="7.54" ry="7.54" stroke="#ff3838" strokeWidth="0"/>
     </>:<>

     <ellipse cx="597.91" cy="918.67" fill="#ff3d3d" filter="url(#svg_40_blur)" id="svg_40" opacity="0.55" rx="11.54" ry="11.54" stroke="#000000" strokeWidth="0"/>
     <ellipse cx="597.85" cy="919.39" fill="url(#svg_45)" id="svg_42" rx="7.54" ry="7.54" stroke="#ff3838" strokeWidth="0"/>
     </>}
    </g>
   </g>
  </g>
  <rect className="pipe" fill="#ff7f00" height="8" id="svg_221" stroke="#a0a0a0" transform="matrix(-0.174185 -0.000381589 -0.00722311 -0.446393 1121.61 748.147)" width="150" x="1755.94" y="68.2"/>
  <g id="svg_86">
   <g id="svg_88">
    <rect fill="#d3d3d3" height="13.83" id="svg_89" rx="3" ry="3" stroke="#7c7c7c" strokeDasharray="2" strokeLinejoin="round" strokeWidth="24" width="236.33" x="701.82" y="343.57"/>
   </g>
   <rect fill="url(#svg_215)" height="30.01" id="svg_214" opacity="NaN" stroke="#7c7c7c" strokeDasharray="2,2" strokeLinejoin="round" width="264.07" x="687.25" y="331.35"/>
   <text fill="#000000" fontFamily="Sans-serif" fontSize="27" id="svg_91" stroke="#939393" strokeLinejoin="round" strokeWidth="0" textAnchor="middle" 
    x="806.66"   y="354">Shower Head</text>
   <rect fill="url(#svg_219)" height="24.55" id="svg_216" opacity="NaN" rx="8" ry="8" stroke="#7c7c7c" strokeLinejoin="round" width="83.05" x="939.04" y="334.92"/>
  </g>
  <g id="svg_222" onClick={() => { setselectedValve("ISOChmbrBrValve"); setValvetitle('Manometer Valve') }}
  // onClick={() => { setselectedValve("DV1"); setValvetitle('Manometer') }}
  >
   <title id="svg_223">valve1</title>
   {iecState?.find(o => o.name === "ISOChmbrBrValve").currentValue ? <>
    <path d="m995.04,569.46l35.13,22.59l-35.2,22.47l0.07,-45.08l0,0.01l0,0.01z" fill="url(#svg_312)" id="svg_224" stroke="#0baa00" strokeWidth="3"/>
    <path d="m1065.16,614.38l-35.16,-22.53l35.16,-22.53l0,45.08l0,-0.01l0,-0.01z" fill="url(#svg_312)" id="svg_225" stroke="#0baa00" strokeWidth="3"/>
    <ellipse cx="1029.48" cy="591.82" fill="url(#svg_312)" id="svg_226" rx="12.06" ry="13.53" stroke="#0baa00" strokeWidth="3"/>
   </>:<> 
    <path d="m995.04,569.46l35.13,22.59l-35.2,22.47l0.07,-45.08l0,0.01l0,0.01z" fill="url(#svg_112)" id="svg_224" stroke="#7697b4" strokeWidth="3"/>
    <path d="m1065.16,614.38l-35.16,-22.53l35.16,-22.53l0,45.08l0,-0.01l0,-0.01z" fill="url(#svg_112)" id="svg_225" stroke="#7697b4" strokeWidth="3"/>
    <ellipse cx="1029.48" cy="591.82" fill="url(#svg_112)" id="svg_226" rx="12.06" ry="13.53" stroke="#7697b4" strokeWidth="3"/>
   </>}
   
  </g>
  <rect className="pipe" fill="url(#svg_13)" height="8" id="svg_227" stroke="#00ff83" strokeDasharray="2" strokeWidth="4" transform="matrix(-0.00123535 0.385614 -0.570737 0.0137707 510.118 -112.911)" width="150" x="567.56" y="-553.34"/>
  <text fill="#000000" fontFamily="Sans-serif" fontSize="27" id="svg_228" stroke="#93c7ff" strokeWidth="0" textAnchor="middle" 
   x="770"   y="96">Power </text>
  <text fill="#000000" fontFamily="Sans-serif" fontSize="16" fontWeight="bold" id="svg_241" stroke="#93c7ff" strokeWidth="0" textAnchor="middle" transform="matrix(1 0 0 1 0 0) matrix(1.3364 0 0 1.3364 -288.521 -359.521)" x="918.55"   y="1080.13">
    RF Power Supply
  </text>
  <g id="svg_245" transform="matrix(1 0 0 1 0 0)" 
  // onClick={() => { setselectedValve("RFPowerSupply"); setValvetitle('RF Power Supply') }}
  onClick={() => { setselectedMfc("ISOBias_Power"); setmfcvalue(iecState?.find(o => o.name === "ISOBias_Power").currentValue) }}
  >
   <g id="svg_244">
    <rect fill="#000000" filter="url(#svg_242_blur)" height="66.55" id="svg_242" opacity="0.5" rx="3" ry="3" stroke="#8ec8ff" strokeLinejoin="round" strokeWidth="0" width="171.59" x="671.1" y="1033.45"/>
    <image height="67.76" id="svg_229" width="168.56" x="669.55" href="components/rfpower.png" y="1029.57"/>
   </g>
   <g id="svg_243">
    <g id="svg_230">
     <text fill="#ffffff" fontFamily="Sans-serif" fontSize="24" id="svg_231" stroke="#000000" strokeWidth="0" textAnchor="middle" 
     transform="matrix(0.691301 0 0 0.691301 189.605 377.293)" x="762.53"   y="995.02">{iecState?.find(o => o.name === "ISOBias_Power").currentValue ? 'On':'Off'}</text>
    </g>
    {iecState?.find(o => o.name === "ISOBias_Power").currentValue > 0 ? 
      <g id="svg_235">
        <ellipse cx="819.21" cy="1054" fill="url(#svg_58)" filter="url(#svg_40_blur)" id="svg_236"  rx="9" ry="9.81" stroke="#000000" strokeWidth="0"/>
        <ellipse cx="819.17" cy="1054.37" fill="url(#svg_58)" id="svg_237" rx="9.8" ry="9.8" stroke="#ff3838" strokeWidth="0"/>
      </g>  
    :
      <g id="svg_235">
        <ellipse cx="769.21" cy="1054" fill="url(#svg_239)" filter="url(#svg_40_blur)" id="svg_236" opacity="9" rx="9" ry="5.81" stroke="#000000" strokeWidth="0"/>
        <ellipse cx="769.17" cy="1054.37" fill="url(#svg_239)" id="svg_237" rx="9" ry="9" stroke="#ff3838" strokeWidth="0"/>
      </g>
    }
   
   </g>
  </g>
  <text fill="#000000" fontFamily="Sans-serif" fontSize="27" fontWeight="normal" id="svg_251" stroke="#b5b5b5" strokeLinejoin="round" strokeWidth="0" textAnchor="middle" 
  x="1128.62"   y="164.31">Final</text>
  <text fill="#000000" fontFamily="Sans-serif" fontSize="27" fontWeight="normal" id="svg_252" stroke="#b5b5b5" strokeLinejoin="round" strokeWidth="0" textAnchor="middle" 
   x="985.54"   y="181.54">Process Gas</text>
  <line fill="none" id="svg_254" stroke="#ffbaf3" strokeLinejoin="round" strokeWidth="7" x1="1257.5" x2="1050.04" y1="493.44" y2="492.54"/>
  <text fill="#000000" fontFamily="Sans-serif" fontSize="27" fontWeight="normal" id="svg_255" stroke="#b5b5b5" strokeLinejoin="round" strokeWidth="0" textAnchor="middle" 
  x="1414.13"   y="500.48">Wall Temperature Monitor</text>
  {/* <text fill="#000000" fontFamily="Sans-serif" fontSize="24" fontWeight="normal" id="svg_256" stroke="#b5b5b5" strokeLinejoin="round" strokeWidth="0" textAnchor="middle" 
   x="1144.26"   y="527.68">Thermo Couples (TC)</text> */}
  <text fill="#000000" fontFamily="Sans-serif" fontSize="27" fontWeight="normal" id="svg_265" stroke="#b5b5b5" strokeLinejoin="round" strokeWidth="0" textAnchor="middle" 
   x="1459.18"   y="459.07">Chamber vent N2</text>
  <text fill="#000000" fontFamily="Sans-serif" fontSize="27" fontWeight="normal" id="svg_266" stroke="#b5b5b5" strokeLinejoin="round" strokeWidth="0" textAnchor="middle" 
   x="1556.03"   y="168.35">Chiller for Chamber wall</text>
  <line fill="none" id="svg_269" stroke="#ffd4aa" strokeLinejoin="round" strokeWidth="3" transform="matrix(1 0 0 1 0 0)" x1="862.46" x2="862.46" y1="739.63" y2="921.37"/>
  <rect fill="url(#svg_125)" height="73" id="svg_123" stroke="#5656ff" width="32.77" x="849.37" y="669.25"/>
  <text fill="#000000" fontFamily="Sans-serif" fontSize="27" fontWeight="normal" id="svg_270" stroke="#b5b5b5" strokeLinejoin="round" strokeWidth="0" textAnchor="middle" 
   x="567.26"   y="610.27">Shut off valve</text>
  <text fill="#000000" fontFamily="Sans-serif" fontSize="27" id="svg_271" stroke="#93c7ff" strokeWidth="0" textAnchor="middle" 
   x="500.63"   y="181.54">Cooling water (RT,2GPM)</text>
  <text fill="#000000" fontFamily="Sans-serif" fontSize="27" fontWeight="normal" id="svg_272" stroke="#b5b5b5" strokeLinejoin="round" strokeWidth="0" textAnchor="middle" 
   x="920.77"   y="914.94">Elevator</text>
  <line fill="none" id="svg_273" stroke="#ffd4aa" strokeLinejoin="round" strokeWidth="3" transform="matrix(1 0 0 1 0 0)" x1="1039.48" x2="862.11" y1="920.95" y2="920.95"/>
  <text fill="#000000" fontFamily="Sans-serif" fontSize="24" fontWeight="normal" id="svg_275" stroke="#b5b5b5" strokeLinejoin="round" strokeWidth="0" textAnchor="middle" 
  x="1129.9"   y="930.39">Elevator Control</text>
  <line fill="none" id="svg_279" stroke="#ffc23f" strokeLinejoin="round" strokeWidth="3" transform="matrix(1 0 0 1 0 0)" x1="835.96" x2="835.96" y1="680.6" y2="942.7"/>
  <text fill="#000000" fontFamily="Sans-serif" fontSize="24" fontWeight="normal" id="svg_281" stroke="#b5b5b5" strokeLinejoin="round" strokeWidth="0" textAnchor="middle" 
   x="1058.9"   y="979.27">He</text>
  <line fill="none" id="svg_283" stroke="#ffc23f" strokeLinejoin="round" strokeWidth="3" transform="matrix(1 0 0 1 0 0)" x1="1040.43" x2="834.5" y1="942.91" y2="942.91"/>
  <line fill="none" id="svg_284" stroke="#ff7ad9" strokeLinejoin="round" strokeWidth="3" transform="matrix(1 0 0 1 0 0)" x1="827.52" x2="827.52" y1="680.6" y2="970.84"/>
  <line fill="none" id="svg_285" stroke="#ff7ad9" strokeLinejoin="round" strokeWidth="3" transform="matrix(1 0 0 1 0 0)" x1="1041.65" x2="827.19" y1="969.14" y2="969.14"/>
  <text fill="#000000" fontFamily="Sans-serif" fontSize="24" fontWeight="normal" id="svg_286" stroke="#b5b5b5" strokeLinejoin="round" strokeWidth="0" textAnchor="middle" 
  x="1065.02"   y="956.94">TC</text>
  <g id="svg_185" transform="matrix(1 0 0 1 0 0)">
   <rect className="pipe" fill={iecState?.find(o => o.name === "ISORoughPump").currentValue ?"url(#tubegreen)":'url(#svg_13)'}
   height="8" id="svg_297" stroke="#93c7ff" strokeWidth="0" transform="matrix(-0.898044 -0.00751162 -0.0320702 -3.47041 1693.07 -1473.44)" width="150" x="1642.86" y="-701.14"/>
   <line fill="none" id="svg_298" markerEnd="url(#mkr_end_svg_298)" stroke="#000000" strokeLinejoin="round" strokeWidth="4" x1="205.5" x2="124.98" y1="934.06" y2="932.86"/>
  </g>
  <g id="abatment" transform="matrix(1 0 0 1 0 0)">
   <g id="svg_290">
    <g id="svg_291">
     <rect fill="#ff9838" height="68.65" id="svg_292" rx="3" ry="3" stroke="#8ec8ff" strokeLinejoin="round" width="153.99" x="237.17" y="900.63"/>
     <rect fill="url(#svg_296)" filter="url(#svg_24_blur)" height="46.85" id="svg_293" rx="2" ry="2" stroke="#8ec8ff" strokeLinejoin="round" width="130.29" x="248.83" y="911.06"/>
    </g>
   </g>
   <text fill="#000000" fontFamily="Sans-serif" fontSize="27" id="svg_294" stroke="#8ec8ff" strokeLinejoin="round" strokeWidth="0" textAnchor="middle" 
    x="310.7"   y="947">Abatement</text>
  </g>
  <rect className="pipe"  fill={iecState?.find(o => o.name === "FinalValve").currentValue ? "url(#tubegreen)":'url(#svg_13)'} height="8" id="processgas" stroke="#93c7ff" strokeWidth="0" 
  transform="matrix(1 0 0 1 0 0) matrix(-2.06909 -0.00536944 -0.0738897 -2.4807 2368.31 -833.354)" width="150" x="580.26" y="-425.02"/>
  
  <g id="svg_305" transform="matrix(1 0 0 1 0 0)" onClick={() => { setselectedValve("FinalValve"); setValvetitle('Final Valve') }}>
   <title id="svg_306">valve1</title>

   {iecState?.find(o => o.name === "FinalValve").currentValue ? 
   <>
    <path d="m1086.25,183.81l40.71,25.11l-40.78,24.98l0.08,-50.11l0,0.02l-0.01,0z" fill="url(#svg_312)" id="svg_307" stroke="#0baa00" strokeWidth="3"/>
    <path d="m1167.48,233.74l-40.74,-25.05l40.74,-25.05l0,50.11l0,-0.02l0,0.01z" fill="url(#svg_312)" id="svg_308" stroke="#0baa00" strokeWidth="3"/>
    <ellipse cx="1126.15" cy="208.66" fill="url(#svg_312)" id="svg_309" rx="13.97" ry="15.03" stroke="#0baa00" strokeWidth="3"/>
  </>
   :
   <>
    <path d="m1086.25,183.81l40.71,25.11l-40.78,24.98l0.08,-50.11l0,0.02l-0.01,0z" fill="url(#svg_112)" id="svg_307" stroke="#7697b4" strokeWidth="3"/>
    <path d="m1167.48,233.74l-40.74,-25.05l40.74,-25.05l0,50.11l0,-0.02l0,0.01z" fill="url(#svg_112)" id="svg_308" stroke="#7697b4" strokeWidth="3"/>
    <ellipse cx="1126.15" cy="208.66" fill="url(#svg_112)" id="svg_309" rx="13.97" ry="15.03" stroke="#7697b4" strokeWidth="3"/>
   </>
   }
   
  </g>
  <rect className="pipe" fill={iecState?.find(o => o.name === "ISOThrottleValve").currentValue>0 ?"url(#tubegreen)":'url(#svg_13)'}
    height="8" id="svg_131" rx="5" ry="5" stroke="#93c7ff" 
    strokeWidth="0" transform="matrix(1 0 0 1 0 0) matrix(-2.46997 -0.00582888 -0.0882055 -2.69295 2808.55 -1772.26)" width="150" x="887.8" y="-911.74"/>
  <g id="svg_142" transform="matrix(1 0 0 1 0 0)">
   <text fill="#000000" fontFamily="Sans-serif" fontSize="27" fontWeight="normal" id="svg_143" stroke="#b5b5b5" strokeLinejoin="round" strokeWidth="0" textAnchor="middle" 
    x="1329.51"   y="790.71">Pirani</text>
  </g>
  <g id="mano1" transform="matrix(1 0 0 1 0 0)">
   <g id="svg_155">
    <rect fill="#000000" filter="url(#svg_147_blur)" height="86.4" id="svg_158" opacity="0.7" rx="3" ry="3" stroke="#8ec8ff" strokeLinejoin="round" strokeWidth="0" width="149.57" x="175.98" y="405.55"/>
    <rect fill="#000000" filter="url(#svg_147_blur)" height="86.4" id="svg_164" opacity="0.7" rx="3" ry="3" stroke="#8ec8ff" strokeLinejoin="round" strokeWidth="0" width="49.67" x="128.7" y="422.49"/>
   </g>
   <image height="151.15" id="svg_140" width="197.56" x="121.8" href="components/monometer.png" y="362.49"/>
   <text className="manodisplay" fill="#ffffff" fontFamily="Sans-serif" fontSize="24" fontWeight="bold" id="manodisplay1" letterSpacing="2" stroke="#b5b5b5" strokeLinejoin="round" strokeWidth="0" textAnchor="middle" textLength="0" 
    lettwordSpacing="0" x="220"   y="460.18">{iecState?.find(o => o.name === "ISOChmbrPrGauge").currentValue}</text>
  </g>
  <g id="mano2" transform="matrix(1 0 0 1 0 0)">
   <g id="svg_152">
    <rect fill="#000000" filter="url(#svg_147_blur)" height="83.2" id="svg_147" opacity="0.7" rx="3" ry="3" stroke="#8ec8ff" strokeLinejoin="round" strokeWidth="0" width="144.87" x="1399.03" y="630.89"/>
    <rect fill="#000000" filter="url(#svg_147_blur)" height="83.2" id="svg_149" opacity="0.7" rx="3" ry="3" stroke="#8ec8ff" strokeLinejoin="round" strokeWidth="0" width="47.83" x="1353.52" y="647.21"/>
   </g>
   <image height="148.27" id="svg_141" width="197.07" x="1346.87" href="components/monometer.png" y="585.14"/>
   <text className="manodisplay" fill="#ffffff" fontFamily="Sans-serif" fontSize="24" fontWeight="bold" id="manodisplay2" letterSpacing="2" stroke="#b5b5b5" strokeLinejoin="round" strokeWidth="0" textAnchor="middle" textLength="0" 
   lettwordSpacing="0" x="1446.45"   y="680.61">
    {/* {(iecState?.find(o => o.name === "ISOChmbrBr10TorrGauge").currentValue/133.32).toFixed(3)} */}
    {(iecState?.find(o => o.name === "ISOChmbrBr10TorrGauge").currentValue)}
    </text>
  </g>
  <rect className="pipe" fill={iecState?.find(o => o.name === "ISOThrottleValve").currentValue>0 ?"url(#tubegreen)":'url(#svg_13)'}
  height="8" id="svg_207" rx="18" ry="18" stroke="#93c7ff" strokeWidth="0" transform="matrix(1 0 0 1 0 0) matrix(-0.00499352 0.337528 -2.30702 0.0120534 -429.199 404.274)" 
  width="150" x="772.83" y="-336.26"/>
  <g id="pirani2" transform="matrix(1 0 0 1 0 0)">
   <g id="svg_65">
    <g id="svg_68">
     <rect fill="#000000" filter="url(#svg_147_blur)" height="115.79" id="svg_66" opacity="0.7" rx="3" ry="3" stroke="#8ec8ff" strokeLinejoin="round" strokeWidth="0" width="143.4" x="356.67" y="701.55"/>
     <rect fill="#000000" filter="url(#svg_147_blur)" height="88.87" id="svg_67" opacity="0.7" rx="3" ry="3" stroke="#8ec8ff" strokeLinejoin="round" strokeWidth="0" width="22.59" x="321.99" y="725.51"/>
    </g>
   </g>
   {/* PIRANI THROTTIL VALVE */}
   <g id="svg_59">
    <image height="142.81" id="svg_208" transform="matrix(1 0 0 1 0 0)" width="192.28" x="304.74" href="components/pirani.png" y="678.96"/>
    <text class="piranidisplay" fill="#ffffff" font-family="Sans-serif" font-size="17" font-weight="bold" id="svg_46" letter-spacing="2" stroke="#b5b5b5" stroke-linejoin="round" stroke-width="0" text-anchor="middle" textLength="0" 
    transform="matrix(1.35664 0 0 1.35664 -321.106 -384.007)" word-spacing="0" x="550.56"   y="833.94">
      {iecState?.find(o => o.name === "ISOPiraniGuage").currentValue}
      </text>
    <g className="pumpBulb" id="svg_47">
     <ellipse cx="459.52" cy="778.03" fill="#46ff3d" filter="url(#svg_40_blur)" id="svg_49" opacity="0.3" rx="15.04" ry="15.04" stroke="#000000" strokeWidth="0"/>
     <ellipse cx="459.44" cy="778.97" fill="url(#svg_58)" id="svg_50" rx="9.83" ry="9.83" stroke="#38ff3b" strokeWidth="0"/>
    </g>
   </g>
  </g>
  <g id="svg_95" transform="matrix(1 0 0 1 0 0)">
   <g id="svg_90">
    <g id="svg_78">
     <rect fill="#000000" 
     filter="url(#svg_147_blur)" height="191.94" id="svg_81" opacity="0.7" rx="3" ry="3" stroke="#8ec8ff" strokeLinejoin="round" strokeWidth="0" width="113.46" x="1388.04" y="194.36"/>
    </g>
   </g>
   <image height="209.19" id="svg_52" width="136.64" x="1368.64" href="components/chiller.png" y="187.36"/>
   <text fill="#000000" fontFamily="Sans-serif" fontSize="27" fontWeight="" id="svg_93" stroke="#8ec8ff" strokeLinejoin="round" strokeWidth="0" textAnchor="middle" 
   x="1582.46"   y="313.97">Chiller 1</text>
  </g>
  <text fill="#000000" fontFamily="Sans-serif" fontSize="27" fontWeight="normal" id="svg_278" stroke="#b5b5b5" strokeLinejoin="round" strokeWidth="0" textAnchor="middle" 
   x="1560.52"   y="1074.36">Chiller for Chuck</text>
  <rect className="pipe" fill="url(#tubegreen)" height="8" id="svg_148" rx="18" ry="18" stroke="#93c7ff" strokeWidth="0" transform="matrix(1 0 0 1 0 0) matrix(-0.00499352 0.31143 -2.30702 0.0111214 -429.199 448.863)" width="150" x="780.6" y="-671.57"/>
  <g id="pirani1" transform="matrix(1 0 0 1 0 0)">
   <g id="svg_129">
    <g id="svg_132">
     <rect fill="#000000" filter="url(#svg_147_blur)" height="118.5" id="svg_133" opacity="0.7" rx="3" ry="3" stroke="#8ec8ff" strokeLinejoin="round" strokeWidth="0" width="146.77" x="1130.01" y="724.82"/>
     <rect fill="#000000" filter="url(#svg_147_blur)" height="90.96" id="svg_134" opacity="0.7" rx="3" ry="3" stroke="#8ec8ff" strokeLinejoin="round" strokeWidth="0" width="23.12" x="1094.52" y="749.34"/>
    </g>
   </g>
   <g id="svg_135">
    <image height="146.15" id="svg_136" width="196.79" x="1076.88" href="components/pirani.png" y="701.7"/>
    <text className="piranidisplay" fill="#ffffff" fontFamily="Sans-serif" fontSize="17" fontWeight="bold" id="svg_137" letterSpacing="2" stroke="#b5b5b5" strokeLinejoin="round" strokeWidth="0" textAnchor="middle" textLength="0" 
    transform="matrix(1.51618 0 0 1.51618 -863.949 -529.297)" lettwordSpacing="0" x="1359.09"   y="859.52">
      {iecState?.find(o => o.name === "ISOChmbrBrPiraniGauge").currentValue}
    </text>
    <g className="pumpBulb" id="svg_139">
     <ellipse cx="1234.56" cy="803.81" fill="#46ff3d" filter="url(#svg_40_blur)" id="svg_145" opacity="0.3" rx="12.99" ry="12.99" stroke="#000000" strokeWidth="0"/>
     <ellipse cx="1234.48" cy="804.63" fill="url(#svg_58)" id="svg_146" rx="8.49" ry="8.49" stroke="#38ff3b" strokeWidth="0"/>
    </g>
   </g>
  </g>
  <g id="svg_53" transform="matrix(1 0 0 1 0 0)">
   <g id="svg_54">
    <g id="svg_55">
     <rect fill="#000000" filter="url(#svg_147_blur)" height="191.94" id="svg_56" opacity="0.7" rx="3" ry="3" stroke="#8ec8ff" strokeLinejoin="round" strokeWidth="0" width="113.46" x="1322.19" y="923.63"/>
    </g>
   </g>
   <image height="209.19" id="svg_57" width="136.64" x="1302.79" href="components/chiller.png" y="916.63"/>
   <text fill="#000000" fontFamily="Sans-serif" fontSize="27" fontWeight="" id="svg_70" stroke="#8ec8ff" strokeLinejoin="round" strokeWidth="0" textAnchor="middle" 
   x="1510.52"   y="1012.55">Chiller 2</text>
  </g>

  <g id="svg_71" markerEnd="url(#mkr_end_svg_71)" onClick={() => { 
      setselectedValve("ISOThrottleValve"); 
      setShutOffValveModal(true)
      setValvetitle('Shut off valve') 
  }}>
   <g id="svg_72">
   {iecState?.find(o => o.name === "ISOThrottleValve").currentValue>0 ? <>
    <path d="m718.38,564.11l-21.74,37.92l-21.62,-37.99l43.38,0.07l-0.01,0l-0.01,0z" fill="url(#svg_87)" id="svg_73" stroke="#93c7ff"/>
    <path d="m675.15,639.78l21.68,-37.96l21.68,37.96l-43.38,0l0.01,0l0.01,0z" fill="url(#svg_87)" id="svg_74" stroke="#93c7ff"/>
    <ellipse cx="140.21" cy="589.49" fill="url(#svg_87)" id="svg_75" rx="9.16" ry="9.16" stroke="#93c7ff" strokeWidth="0" transform="matrix(0 1.42089 -1.42089 0 1534.47 402.05)"/>
   </>:<>
      <path d="m717.74,564.9l-21.74,37.92l-21.62,-37.99l43.38,0.07l-0.01,0l-0.01,0z" fill="url(#svg_112)" id="svg_105" stroke="#93c7ff"/>
      <path d="m674.51,640.57l21.68,-37.96l21.68,37.96l-43.38,0l0.01,0l0.01,0z" fill="url(#svg_112)" id="svg_106" stroke="#93c7ff"/>
      <ellipse cx="140.76" cy="589.94" fill="url(#svg_112)" id="svg_107" rx="9.16" ry="9.16" stroke="#93c7ff" strokeWidth="0" transform="matrix(0 1.42089 -1.42089 0 1534.47 402.05)"/>
    </>
  }
   
   </g>
   <line fill="url(#svg_87)" id="svg_76" markerEnd="url(#mkr_end_svg_109)" stroke="#000000" strokeWidth="2" x1="656.27" x2="731.59" y1="627.49" y2="579.18"/>
  </g>
  {/* <g id="svg_113">
   <g id="svg_104">
    <path d="m717.74,564.9l-21.74,37.92l-21.62,-37.99l43.38,0.07l-0.01,0l-0.01,0z" fill="url(#svg_112)" id="svg_105" stroke="#93c7ff"/>
    <path d="m674.51,640.57l21.68,-37.96l21.68,37.96l-43.38,0l0.01,0l0.01,0z" fill="url(#svg_112)" id="svg_106" stroke="#93c7ff"/>
    <ellipse cx="140.76" cy="589.94" fill="url(#svg_112)" id="svg_107" rx="9.16" ry="9.16" stroke="#93c7ff" strokeWidth="0" transform="matrix(0 1.42089 -1.42089 0 1534.47 402.05)"/>
   </g>
   <line fill="none" id="svg_109" markerEnd="url(#mkr_end_svg_109)" stroke="#000000" strokeWidth="2" x1="655.63" x2="730.95" y1="628.28" y2="579.97"/>
  </g> */}
 </g>
</svg>



        </div>

        {selectedMfc != null ?
        <div className="fixed w-full h-full bg-black bg-opacity-25 top-0 left-0">
          <div className='mx-auto w-1/3 bg-white p-4 mt-36 relative shadow-2xl'>
            <button className='bg-red-500 px-2 text-white absolute right-3 rounded' onClick={() => { setselectedMfc(null); setmfcvalue(null) }}>close</button>
            <div className='text-base font-bold'>Change Value of {selectedMfc}</div>

            <input type='number' value={mfcvalue ? mfcvalue : ''} onChange={(e) => setmfcvalue(e.target.value)} className='p-2 text-lg border bg-gray-100 w-full mt-5' placeholder='change value' />
            <button className='bg-gray-600 rounded hover:bg-gray-400 text-white px-3 py-2 mt-3' onClick={() => handleMfcvalue()}>Update</button>
          </div>
        </div> : ''
      }

      {selectedValve && selectedValve == 'ISOThrottleValve'  ?
        <div className="fixed w-full h-full bg-black bg-opacity-25 top-0 left-0">
          <div className='mx-auto w-1/3 bg-white p-4 mt-36 relative shadow-2xl'>
            <button className='bg-red-500 px-2 text-white absolute right-3 rounded' onClick={() => setselectedValve(null)}>close</button>
            <div className='text-base font-bold'>Change Value of {Valvetitle}</div>

            <input type='number' value={shutOffValveVal ? shutOffValveVal : ''} onChange={(e) => setShutOffValveVal(e.target.value)} className='p-2 text-lg border bg-gray-100 w-full mt-5' placeholder='change value' />
            <button className='bg-gray-600 rounded hover:bg-gray-400 text-white px-3 py-2 mt-3' onClick={() => handleClick(shutOffValveVal)}>Update</button>
          </div>
        </div> : ''
      }
      {selectedValve != null && selectedValve != 'ISOThrottleValve' ?
        <div className="fixed w-full h-full bg-black bg-opacity-25 top-0 left-0">
          <div className='mx-auto w-1/4 bg-white p-4 mt-36 relative shadow-2xl'>
            <div className='text-base font-bold'>
              {iecState?.find(o => o.name === selectedValve).currentValue === true|| iecState?.find(o => o.name === selectedValve).currentValue >0? 'Do you want to close ' : 'Do you want to open '}  {Valvetitle} ?</div>
            <div className='flex justify-between mt-5'>
              {iecState?.find(o => o.name === selectedValve).currentValue === true ?
                
                <button className='bg-white-500 shadow-md hover:bg-gray-300 border border-b text-black px-3 py-2 mt-3 rounded font-bold' onClick={() => { handleClick(false); }}>Yes</button>
                :
                iecState?.find(o => o.name === selectedValve).currentValue == 0?
                  <button className='bg-white-500 shadow-md hover:bg-gray-300 border border-b text-black px-3 py-2 mt-3 rounded  font-bold' onClick={() => handleClick(90)}>Yes</button>
                :
                  iecState?.find(o => o.name === selectedValve).currentValue > 0?
                    <button className='bg-white-500 shadow-md hover:bg-gray-300 border border-b text-black px-3 py-2 mt-3 rounded  font-bold' onClick={() => handleClick(0)}>Yes</button>
                :
                  <button className='bg-white-500 shadow-md hover:bg-gray-300 border border-b text-black px-3 py-2 mt-3 rounded  font-bold' onClick={() => handleClick(true)}>Yes</button>
              }
              <button className='bg-gray-600 text-white px-3 py-2 mt-3 rounded' onClick={() => setselectedValve(null)}>No</button>
            </div>


            
          </div>
        </div> : ''
      }

      {/* {selectedValve != null ?
        <div className="fixed w-full h-full bg-black bg-opacity-25 top-0 left-0">
          <div className='mx-auto w-1/4 bg-white p-4 mt-36 relative shadow-2xl'>
            <div className='text-base font-bold'>{iecState?.find(o => o.name === selectedValve).currentValue === true || iecState?.find(o => o.name === selectedValve).currentValue >0? 'Do you want to close ' : 'Do you want to open '} {Valvetitle} ?</div>
            <div className='flex justify-between mt-5'>
              
              {iecState?.find(o => o.name === selectedValve).currentValue === true ?
                  <button className='bg-white-500 shadow-md hover:bg-gray-300 border border-b text-black px-3 py-2 mt-3 rounded font-bold' onClick={() => { handleClick(false); }}>Yes</button>
                :iecState?.find(o => o.name === selectedValve).currentValue === false ?
                    <button className='bg-white-500 shadow-md hover:bg-gray-300 border border-b text-black px-3 py-2 mt-3 rounded  font-bold' onClick={() => handleClick(true)}>Yes</button>
                :iecState?.find(o => o.name === selectedValve).currentValue == 0 ?
                  <button className='bg-white-500 shadow-md hover:bg-gray-300 border border-b text-black px-3 py-2 mt-3 rounded  font-bold' onClick={() => handleClick(90)}>Yes</button>
                :iecState?.find(o => o.name === selectedValve).currentValue > 0 ?
                <button className='bg-white-500 shadow-md hover:bg-gray-300 border border-b text-black px-3 py-2 mt-3 rounded  font-bold' onClick={() => handleClick(0)}>Yes</button>:''
               }
              <button className='bg-gray-600 text-white px-3 py-2 mt-3 rounded' onClick={() => setselectedValve(null)}>No</button>
            </div>
            
          </div>
        </div> : ''
      } */}

      </div>
      {/* <div style={{position: 'unset', width: '170px'}} className={``}> */}
      
      <div style={{position: "unset", maxWidth: "170px"}}>
      {iecState?.find((o) => o.name === "MFC_SEQ_Name").currentValue != '' ?
      <div className={`border text-sm mb-2 m-2 px-4 py-1 flex shadow-md bg-blue-200 bg-opacity-80 rounded-md p-2 fixed top-0 left-0 w-60'}`}>
        <div>
          <strong className="mr-4">
          {iecState?.find((o) => o.name === "MFC_SEQ_Name").currentValue} 
        </strong>
        </div>
         {iecState?.find((o) => o.name === "MFC_SEQ_Cmd").currentValue} <br />
         {iecState?.find((o) => o.name === "MFC_SEQ_Next_Cmd").currentValue} 
      </div> : ''}
      
      <div id="pump_up_down" className="pump_up_down" style={{position: "unset", maxWidth: "280px"}}>
        <Pumpdown isOn={alarm.value} channels={alarm.channels} iecState={iecState}></Pumpdown>
        </div>
      </div>
      <div className="flex flex-col items-center" style={{width: "300px"}}>
        {secondaryMenu === 'Motor' ? <div id="motor_wrapper" className="moto-wrapper mb-12 w-full" style={{maxWidth: "180px"}}>
          <MotorControls
            allChannels={channels.current}
            channelService={channelService}
            channelSubscriptionService={channelSubscriptionService}
            subscriptionForMotor={subscriptionForMotor}
          />
        </div> : ''}
      </div>
      <div className="h-full flex flex-col gap-5 bg-btnSecondary p-3 mr-1 -mt-4" style={{maxWidth: "100px"}}>
        <EmbossedBtn btnText="Motor" active={secondaryMenu === 'Motor'} action={handleSecondaryMenu}  />
        <EmbossedBtn btnText="Gasbox" active={secondaryMenu === 'Gasbox'} action={handleSecondaryMenu}  />
        <EmbossedBtn btnText="RPS" active={secondaryMenu === 'RPS'} action={handleSecondaryMenu}  />
        <EmbossedBtn btnText="RF" active={secondaryMenu === 'RF'} action={handleSecondaryMenu}  />
        <EmbossedBtn btnText="VAT" active={secondaryMenu === 'VAT'} action={handleSecondaryMenu}  />
      </div>
      </div>
    </div>
  );
}
