import React, { useEffect, useRef, useState, useReducer } from "react";
import { ChannelService, ChannelSubscriptionService } from "@getvia/sdk";
import Alarm from '../alarm/Alarm';
import Motor from "./Motor2";
import MotorControls from "./MotorControls";
import EmbossedBtn from "./EmbossedBtn";
import Pumpdown from "./dicing/pumpdown";

const viaConfig = {
  channelServiceOptions: {
    apiToken: "test",
    baseUrl: process.env.REACT_APP_VIA_CORE_API,
    wsUrl: process.env.REACT_APP_VIA_NOTIFICATION_HUB
  },
};


const defaultGasBoxConfiguration = [
  
  {
    object: "ISOChmbrPrValve",
    name: "ISOChmbrPrValve",
    readChannelName: "MAIN.ISOChmbrPrValve.In.Open_fbk",
    writeChannelName: "MAIN.ISOChmbrPrValve.Out.Open_cmd",
    feedBackChannel: "MAIN.ISOChmbrPrValve.In.Open_fbk",
    currentValue: false,
  },
  {
    object: "ISON2VentValve",
    name: "ISON2VentValve",
    readChannelName: "MAIN.ISON2VentValve.In.Open_fbk",
    writeChannelName: "MAIN.ISON2VentValve.Out.Open_cmd",
    feedBackChannel: "MAIN.ISON2VentValve.In.Open_fbk",
    currentValue: false,
  },
  {
    object: "ISOChmbrBrValve",
    name: "ISOChmbrBrValve",
    readChannelName: "MAIN.ISOChmbrBrValve.In.Open_fbk",
    writeChannelName: "MAIN.ISOChmbrBrValve.Out.Open_cmd",
    feedBackChannel: "MAIN.ISOChmbrBrValve.In.Open_fbk",
    currentValue: false,
  },
  {
    object: "ISORoughLineValve",
    name: "ISORoughLineValve",
    readChannelName: "MAIN.ISORoughLineValve.In.Open_fbk",
    writeChannelName: "MAIN.ISORoughLineValve.Out.Open_cmd",
    feedBackChannel: "MAIN.ISORoughLineValve.In.Open_fbk",
    currentValue: false,
  },
  {
    object: "ISOThrottleValve",
    name: "ISOThrottleValve",
    readChannelName: "MAIN.ISOThrottleValve.In.Actual_Position",
    writeChannelName: "MAIN.ISOThrottleValve.Out.Target_Position",
    feedBackChannel: "MAIN.ISOThrottleValve.In.Actual_Position",
    currentValue: 0,
  },
  {
    object: "ISOThrottleValve",
    name: "ISOThrottleValveMode",
    readChannelName: "MAIN.ISOThrottleValve.In.Control_Mode",
    writeChannelName: "MAIN.ISOThrottleValve.Out.Control_Mode",
    feedBackChannel: "MAIN.ISOThrottleValve.In.Control_Mode",
    currentValue: 0,
  },
  {
    object: "ISORoughPump",
    name: "ISORoughPump",
    readChannelName: "MAIN.ISORoughPump.In.Running_fbk",
    writeChannelName: "MAIN.ISORoughPump.Out.On_cmd",
    feedBackChannel: "MAIN.ISORoughPump.In.Running_fbk",
    currentValue: false,
  },
  {
    object: "FinalValve",
    name: "FinalValve",
    readChannelName: "MAIN.FinalValve.In.Open_fbk",
    writeChannelName: "MAIN.FinalValve.Out.Open_cmd",
    feedBackChannel: "MAIN.FinalValve.In.Open_fbk",
    currentValue: false,
  },
  {
    object: "RFPowerSupply",
    name: "RFPowerSupply", 
    readChannelName: "MAIN.RFPowerSupply",
    writeChannelName: "MAIN.RFPowerSupply",
    feedBackChannel: "MAIN.RFPowerSupply",
    currentValue: false,
  },
  {
    object: "ISOChmbrPrGauge",
    name: "ISOChmbrPrGauge",
    feedBackChannel: "MAIN.ISOChmbrPrGauge.In.Sensor_Value_Module_1",
    currentValue: false,
  },
  {
    object: "RFMatch",
    name: "RFMatch",
    feedBackChannel: "MAIN.RF",
    currentValue: false,
  },
  {
    object: "RPSPowerSupply",
    name: "RPSPowerSupply",
    feedBackChannel: "MAIN.RPSPowerSupply.In.Actual_Setpoint",
    writeChannelName: "MAIN.RPSPowerSupply.Out.Setpoint",
    currentValue: 1,
  },
  {
    object: "RPSPowerSupply",
    name: "RPSPowerSupplyOn",
    feedBackChannel: "MAIN.RPSPowerSupply.In.ON_Requested_Indication",
    writeChannelName: "MAIN.RPSPowerSupply.Out.RF_ON_Request",
    currentValue: false,
  },
  {
    object: "ISOChmbrBr10TorrGauge",
    name: "ISOChmbrBr10TorrGauge",
    feedBackChannel: "MAIN.ISOChmbrBr10TorrGauge.In.Sensor_Value_Module_1",
    currentValue: false,
  },
  {
    object: "ISOChmbrBrPiraniGauge",
    name: "ISOChmbrBrPiraniGauge",
    feedBackChannel: "MAIN.ISOChmbrBrPiraniGauge.In.Sensor_Value_Module_1",
    currentValue: false,
  },
  {
    object: "ISOPiraniGuage",
    name: "ISOPiraniGuage",
    feedBackChannel: "MAIN.ISOPiraniGuage.In.Sensor_Value",
    currentValue: false,
  },  
  {
    object: "Alarm",
    name: "Alarm1",
    feedBackChannel: "Chamber_Failed_To_Reach_Pressure",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm2",
    feedBackChannel: "Failed_Roughing_Pressure",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm3",
    feedBackChannel: "Pump_Down_Error",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm4",
    feedBackChannel: "RoughLine_Pressure_Error",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm5",
    feedBackChannel: "RoughPump_Failure",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm6",
    feedBackChannel: "RoughValve_Close_Error",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm6",
    feedBackChannel: "RoughValve_Open_Error",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm6",
    feedBackChannel: "ThrottleValve_Open_Error",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm6",
    feedBackChannel: "Turbo_Online_Error",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm6",
    feedBackChannel: "No_Water_Flow_To_Pump",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm6",
    feedBackChannel: "Chamber_Failed_To_Reach_Pressure",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm6",
    feedBackChannel: "VentValve_Close_Error",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm6",
    feedBackChannel: "VentValve_Open_Error",
    currentValue: false,
  },
  {
    object: "Sequence",
    name: "SequenceLoopCounter",
    feedBackChannel: "LoopCounter",
    currentValue: 0,
  },
  {
    object: "Sequence",
    name: "SequenceLoopRemaining",
    feedBackChannel: "RemainingCount",
    currentValue: 0,
  },


  
 
  
  // {
  //   object: "DownValve",
  //   name: "DV1",
  //   readChannelName: "MAIN.DownStreamValve1.In.Open_fbk",
  //   writeChannelName: "MAIN.DownStreamValve1.Out.Open_cmd",
  //   feedBackChannel: "MAIN.DownStreamValve1.In.Open_fbk",
  //   currentValue: false,
  // },
  


  
  {
    object: "MFC_SEQ_Cmd",
    name: "MFC_SEQ_Cmd",
    feedBackChannel: "MFC_SEQ_Cmd",
    currentValue: null,
  },
  {
    object: "MFC_SEQ_Next_Cmd",
    name: "MFC_SEQ_Next_Cmd",
    feedBackChannel: "MFC_SEQ_Next_Cmd",
    currentValue: null,
  },
  {
    object: "MFC_SEQ_Name",
    name: "MFC_SEQ_Name",
    feedBackChannel: "MFC_SEQ_Name",
    currentValue: null,
  }
];

const channelService = new ChannelService(viaConfig.channelServiceOptions);
const channelSubscriptionService = new ChannelSubscriptionService(viaConfig.channelServiceOptions);

const PumpdownReducer = (state, action) => {
  switch (action.type) {
    case "ON":
      return {
        ...state,
        value: true,
        channels: action.channels
      };
    case "OFF":
      return {
        ...state,
        value: false
      };
    default:
      return state;
  }
};


export default function DicingRIEChamberold() {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const gasBoxConfiguration = useRef(defaultGasBoxConfiguration);
  const channels = useRef();
  const subscriptionRef = useRef();

  const [allChannels, setAllChannels] = useState([]);
  const [iecState, setIECState] = useState();
  const [notification, setNotification] = useState();
  const [alarm, dispatch] = useReducer(PumpdownReducer, { value: false, channels: [] });
  const [secondaryMenu, setSecondaryMenu] = useState('Motor');

  const [subscriptionForMotor, setSubscriptionForMotor] = useState([])
  const [shutOffValveModal, setShutOffValveModal] = useState(true)
  const [shutOffValveVal, setShutOffValveVal] = useState(null)

  useEffect(() => {
    // Function to update window height state when the window is resized
    const handleResize = () => {
      setWindowHeight(window.innerHeight - 500);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  

  const fetchChannelsStates = async () => {
    const response = await channelService.ChannelStates();
    channels.current = response;
    updateGasBoxState(channels.current);
  };
  const updateGasBoxState = (updatedChannels) => {

    let index = -1
    let gasBoxConfigurationState = gasBoxConfiguration.current;
    updatedChannels.forEach((item) => {
      const config = gasBoxConfigurationState.find((obj, objIndex) => {
        index = objIndex;
        return obj.feedBackChannel === item.channelName
      })
      if (config) {
        config.currentValue = item.data.value;
        gasBoxConfigurationState[index] = config;
      }
    })
    setAlarm();
    setIECState(() => gasBoxConfigurationState); //enable to get state on top box above diagram
  }

  const setAlarm = () => {
    let gasBoxConfigurationState = gasBoxConfiguration.current;
    const configs = gasBoxConfigurationState.filter((obj) => {
      return obj.object === "Alarm" && obj.currentValue === true
    })
    if (configs && configs.length > 0) {
      const alarmChannels = configs.flatMap(a => a.feedBackChannel);
      dispatch({ type: 'ON', channels: alarmChannels })
    } else {
      dispatch({ type: 'OFF' })
    }

  }
  const subscribeToNotification = () => {

    const onNewMessage = (message) => {
      if(message) {
        const channel = JSON.parse(message);
        console.log("channel-------->>>>>", channel)
        setNotification(() => channel)
        setSubscriptionForMotor(channel);
        updateGasBoxState([channel])
      }
    };

    let channels = [];

    gasBoxConfiguration.current.forEach(config => {
      channels.push(config.feedBackChannel);
    })
    const subscription = {
      channels: channels,
      onNewMessage: onNewMessage,
    };

    subscriptionRef.current = subscription;
    
    channelSubscriptionService.subscribe(subscription);
  };
  const unSubscribe = () => {
    channelSubscriptionService.unSubscribe(subscriptionRef.current);
  };
  
  useEffect(() => {
    const fetChannelData = async () => {
      await fetchChannelsStates();
      subscribeToNotification();
    };
    fetChannelData();
    return () => {
      unSubscribe();
    };
  }, []);


  const IOWrite = (channelName, value) => {
    if (channelName) {
      const channel = channels.current.find(obj => {
        return obj.channelName === channelName
      })
      
      if (channel) {
        channel.data.value = value;
        channelService.Write(channel).then(() => {
        });
      }
      
    }
  }

  const WriteToMFC = (config, value) => {
    const writeChannelName = config.writeChannelName;
    IOWrite(writeChannelName, value);
  }

  const WriteToValve = (config, value) => {
    const writeChannelName = config.writeChannelName;
    
    IOWrite(writeChannelName, value);
  }

  const [selectedValve, setselectedValve] = useState(null);
  const [labelval, setLabel] = useState(null);
  const [Valvetitle, setValvetitle] = useState(null);
  const [selectedMfc, setselectedMfc] = useState(null);
  const [mfcvalue, setmfcvalue] = useState(null);

  const handleClick = (value) => {
    const config = gasBoxConfiguration.current.find((obj) => {
      return obj.name === selectedValve
    });  
    

    WriteToValve(config, Number(value))
    if(selectedValve=='ISOThrottleValve') {
      const config = gasBoxConfiguration.current.find((obj) => {
        return obj.name === 'ISOThrottleValveMode'
      }); 
      WriteToValve(config, 1)
    }
    WriteToValve(config, value)
    // setBulbs(prevBulbs => (
    //   prevBulbs.map(bulb => {
    //     if (bulb.id === selectedValve) {
    //       return { ...bulb, isOn: !bulb.isOn };
    //     }
    //     return bulb;
    //   })
    // ));
    setselectedValve(null)
    setShutOffValveVal('')
    // setValvevalue(null)
  };

  const handleSecondaryMenu = (name) => {
    setSecondaryMenu(name)
  }

  const handleMfcvalue = () => {
    const config = gasBoxConfiguration.current.find((obj) => {
      return obj.name === selectedMfc
    })
    debugger
    WriteToMFC(config, mfcvalue);
    if(selectedMfc=='RPSPowerSupply') {
      const config = gasBoxConfiguration.current.find((obj) => {
        return obj.name === 'RPSPowerSupplyOn'
      }); 
      if(parseFloat(mfcvalue)==0)
      WriteToMFC(config, false)
      else if(parseFloat(mfcvalue)>0)
      WriteToMFC(config, true)
    }
    setselectedMfc(null);
    setmfcvalue(null)
  }
  return (
    <div className="flex flex-col items-center w-full h-full overflow-x-hidden">
      <div className="flex gap-5 w-full mt-5">
        
      <div className="flex-1 mx-2 relative">
        
        <div className="relative overflow-hidden ">
{/* <svg xmlns="http://www.w3.org/2000/svg" className="svgmani relative svg-main" viewBox="360 60 1000 1090" style={{
  width: "100%",
  height: "calc(100vh - 100px)"
}}>
 
</svg> */}

<img id="image0_270_509" width="100%"  src="components/dices.png" />

        </div>

        {selectedMfc != null ?
        <div className="fixed w-full h-full bg-black bg-opacity-25 top-0 left-0">
          <div className='mx-auto w-1/3 bg-white p-4 mt-36 relative shadow-2xl'>
            <button className='bg-red-500 px-2 text-white absolute right-3 rounded' onClick={() => { setselectedMfc(null); setmfcvalue(null) }}>close</button>
            <div className='text-base font-bold'>Change Value of {selectedMfc}</div>

            <input type='number' value={mfcvalue ? mfcvalue : ''} onChange={(e) => setmfcvalue(e.target.value)} className='p-2 text-lg border bg-gray-100 w-full mt-5' placeholder='change value' />
            <button className='bg-gray-600 rounded hover:bg-gray-400 text-white px-3 py-2 mt-3' onClick={() => handleMfcvalue()}>Update</button>
          </div>
        </div> : ''
      }

      {selectedValve && selectedValve == 'ISOThrottleValve'  ?
        <div className="fixed w-full h-full bg-black bg-opacity-25 top-0 left-0">
          <div className='mx-auto w-1/3 bg-white p-4 mt-36 relative shadow-2xl'>
            <button className='bg-red-500 px-2 text-white absolute right-3 rounded' onClick={() => setselectedValve(null)}>close</button>
            <div className='text-base font-bold'>Change Value of {Valvetitle}</div>

            <input type='number' value={shutOffValveVal ? shutOffValveVal : ''} onChange={(e) => setShutOffValveVal(e.target.value)} className='p-2 text-lg border bg-gray-100 w-full mt-5' placeholder='change value' />
            <button className='bg-gray-600 rounded hover:bg-gray-400 text-white px-3 py-2 mt-3' onClick={() => handleClick(shutOffValveVal)}>Update</button>
          </div>
        </div> : ''
      }
      {selectedValve != null && selectedValve != 'ISOThrottleValve' ?
        <div className="fixed w-full h-full bg-black bg-opacity-25 top-0 left-0">
          <div className='mx-auto w-1/4 bg-white p-4 mt-36 relative shadow-2xl'>
            <div className='text-base font-bold'>
              {iecState?.find(o => o.name === selectedValve).currentValue === true|| iecState?.find(o => o.name === selectedValve).currentValue >0? 'Do you want to close ' : 'Do you want to open '}  {Valvetitle} ?</div>
            <div className='flex justify-between mt-5'>
              {iecState?.find(o => o.name === selectedValve).currentValue === true ?
                
                <button className='bg-white-500 shadow-md hover:bg-gray-300 border border-b text-black px-3 py-2 mt-3 rounded font-bold' onClick={() => { handleClick(false); }}>Yes</button>
                :
                iecState?.find(o => o.name === selectedValve).currentValue == 0?
                  <button className='bg-white-500 shadow-md hover:bg-gray-300 border border-b text-black px-3 py-2 mt-3 rounded  font-bold' onClick={() => handleClick(90)}>Yes</button>
                :
                  iecState?.find(o => o.name === selectedValve).currentValue > 0?
                    <button className='bg-white-500 shadow-md hover:bg-gray-300 border border-b text-black px-3 py-2 mt-3 rounded  font-bold' onClick={() => handleClick(0)}>Yes</button>
                :
                  <button className='bg-white-500 shadow-md hover:bg-gray-300 border border-b text-black px-3 py-2 mt-3 rounded  font-bold' onClick={() => handleClick(true)}>Yes</button>
              }
              <button className='bg-gray-600 text-white px-3 py-2 mt-3 rounded' onClick={() => setselectedValve(null)}>No</button>
            </div>


            
          </div>
        </div> : ''
      }

      {/* {selectedValve != null ?
        <div className="fixed w-full h-full bg-black bg-opacity-25 top-0 left-0">
          <div className='mx-auto w-1/4 bg-white p-4 mt-36 relative shadow-2xl'>
            <div className='text-base font-bold'>{iecState?.find(o => o.name === selectedValve).currentValue === true || iecState?.find(o => o.name === selectedValve).currentValue >0? 'Do you want to close ' : 'Do you want to open '} {Valvetitle} ?</div>
            <div className='flex justify-between mt-5'>
              
              {iecState?.find(o => o.name === selectedValve).currentValue === true ?
                  <button className='bg-white-500 shadow-md hover:bg-gray-300 border border-b text-black px-3 py-2 mt-3 rounded font-bold' onClick={() => { handleClick(false); }}>Yes</button>
                :iecState?.find(o => o.name === selectedValve).currentValue === false ?
                    <button className='bg-white-500 shadow-md hover:bg-gray-300 border border-b text-black px-3 py-2 mt-3 rounded  font-bold' onClick={() => handleClick(true)}>Yes</button>
                :iecState?.find(o => o.name === selectedValve).currentValue == 0 ?
                  <button className='bg-white-500 shadow-md hover:bg-gray-300 border border-b text-black px-3 py-2 mt-3 rounded  font-bold' onClick={() => handleClick(90)}>Yes</button>
                :iecState?.find(o => o.name === selectedValve).currentValue > 0 ?
                <button className='bg-white-500 shadow-md hover:bg-gray-300 border border-b text-black px-3 py-2 mt-3 rounded  font-bold' onClick={() => handleClick(0)}>Yes</button>:''
               }
              <button className='bg-gray-600 text-white px-3 py-2 mt-3 rounded' onClick={() => setselectedValve(null)}>No</button>
            </div>
            
          </div>
        </div> : ''
      } */}

      </div>
      <div style={{position: 'unset', width: '170px'}} className={``}>
      
      {iecState?.find((o) => o.name === "MFC_SEQ_Name").currentValue != '' ?
      <div className={`border text-sm mb-2 m-2 px-4 py-1 flex shadow-md bg-blue-200 bg-opacity-80 rounded-md p-2 fixed top-0 left-0 w-60'}`}>
        <div>
          <strong className="mr-4">
          {iecState?.find((o) => o.name === "MFC_SEQ_Name").currentValue} 
        </strong>
        </div>
         {iecState?.find((o) => o.name === "MFC_SEQ_Cmd").currentValue} <br />
         {iecState?.find((o) => o.name === "MFC_SEQ_Next_Cmd").currentValue} 
      </div> : ''}
      
      <div id="pump_up_down" className="pump_up_down" style={{position: "unset", maxWidth: "280px"}}>
        <Pumpdown isOn={alarm.value} channels={alarm.channels} iecState={iecState}></Pumpdown>
        </div>
      </div>
      <div className="flex flex-col items-center" style={{width: "300px"}}>
        {secondaryMenu === 'Motor' ? <div id="motor_wrapper" className="moto-wrapper mb-12 w-full" style={{maxWidth: "180px"}}>
          <MotorControls
            allChannels={channels.current}
            channelService={channelService}
            channelSubscriptionService={channelSubscriptionService}
            subscriptionForMotor={subscriptionForMotor}
          />
        </div> : ''}
      </div>
      <div className="h-full flex flex-col gap-5 bg-btnSecondary p-3 mr-1 -mt-4" style={{maxWidth: "100px"}}>
        <EmbossedBtn btnText="Motor" active={secondaryMenu === 'Motor'} action={handleSecondaryMenu}  />
        <EmbossedBtn btnText="Gasbox" active={secondaryMenu === 'Gasbox'} action={handleSecondaryMenu}  />
        <EmbossedBtn btnText="RPS" active={secondaryMenu === 'RPS'} action={handleSecondaryMenu}  />
        <EmbossedBtn btnText="RF" active={secondaryMenu === 'RF'} action={handleSecondaryMenu}  />
        <EmbossedBtn btnText="VAT" active={secondaryMenu === 'VAT'} action={handleSecondaryMenu}  />
      </div>
      </div>
    </div>
  );
}