import React, { useEffect, useRef, useState, useReducer } from 'react';
import { ObjectService, ObjectSubscriptionService } from "@getvia/sdk";
import Alarm from '../alarm/Alarm';

const objectId = "dtmi:com:via:Subsystem:GasBox;1"; //TODO: make it configurable


const viaConfig = {
  objectServiceOptions: {
    apiToken: 'test',
    baseUrl: process.env.REACT_APP_VIA_OBJECT_API,
    wsUrl: process.env.REACT_APP_VIA_OBJECT_NOTIFICATION_HUB
  }
};
const objectService = new ObjectService(viaConfig.objectServiceOptions);
const objectSubscriptionService = new ObjectSubscriptionService(viaConfig.objectServiceOptions);

const AlarmReducer = (state, action) => {
  switch (action.type) {
    case "ON":
      return {
        ...state,
        value: true,
        channels: action.channels
      };
    case "OFF":
      return {
        ...state,
        value: false
      };
    default:
      return state;
  }
};

const defaultGasBoxState = {
  "PhneumaticValve": {
    "PneumaticValveOPState": 0
  },
  "MFC1": {
    "State": 0,
    "FlowReading": 0
  },
  "MFC2": {
    "State": 0,
    "FlowReading": 0
  },
  "MFC3": {
    "State": 0,
    "FlowReading": 0
  },
  "MFC4": {
    "State": 0,
    "FlowReading": 0
  },
  "MFC5": {
    "State": 0,
    "FlowReading": 0
  },
  "MFC6": {
    "State": 0,
    "FlowReading": 0
  },
  "DownStreamValve1": {
    "OnOff": false
  },
  "DownStreamValve2": {
    "OnOff": false
  },
  "DownStreamValve3": {
    "OnOff": false
  },
  "DownStreamValve4": {
    "OnOff": false
  },
  "DownStreamValve5": {
    "OnOff": false
  },
  "DownStreamValve6": {
    "OnOff": false
  },
  "UpStreamValve1": {
    "OnOff": false
  },
  "UpStreamValve2": {
    "OnOff": false
  },
  "UpStreamValve3": {
    "OnOff": false
  },
  "UpStreamValve4": {
    "OnOff": false
  },
  "UpStreamValve5": {
    "OnOff": false
  },
  "UpStreamValve6": {
    "OnOff": false
  }
}
export default function GasBoxV2() {
  const gasBoxConfiguration = useRef();
  const subscriptionRef = useRef();
  const [gasBoxState, setGasBoxState] = useState(defaultGasBoxState);
  const [selectedValve, setselectedValve] = useState(null);
  const [Valvetitle, setValvetitle] = useState(null);
  const [selectedMfc, setselectedMfc] = useState(null);
  const [mfcvalue, setmfcvalue] = useState(0);

  const [alarm, dispatch] = useReducer(AlarmReducer, { value: false, AlarmChannels: [] });

  function flattenDigitalTwinsByName(data) {
    const flattened = {};
    data.digitalTwins.forEach(twin => {
      flattened[twin.Name] = twin;
    });
    return flattened;
  }

  const fetchObjectState = async () => {
    const response = await objectService.GetObjectGraphById(objectId)

    if (response) {
      gasBoxConfiguration.current = response;
      var flattenObject = flattenDigitalTwinsByName(response);
      setGasBoxState(() => flattenObject);
    }
  };

  const setAlarm = () => {
    let gasBoxConfigurationState = gasBoxConfiguration.current;
    const configs = gasBoxConfigurationState.filter((obj) => {
      return obj.object === "Alarm" && obj.currentValue === true
    })
    if (configs && configs.length > 0) {
      const alarmChannels = configs.flatMap(a => a.feedBackChannel);
      console.log(alarmChannels);
      dispatch({ type: 'ON', channels: alarmChannels })
    } else {
      dispatch({ type: 'OFF' })
    }
  }

  const updateGasBoxState = (object) => {

    gasBoxConfiguration.current.digitalTwins = gasBoxConfiguration.current.digitalTwins.map(dt => {
      if (dt.$dtId === object.$dtId) {
        const updatedObject = { ...dt, ...object};
        return updatedObject;
      }
      return dt;
    });
    var flattenObject = flattenDigitalTwinsByName(gasBoxConfiguration.current);
    setGasBoxState(() => flattenObject);

  }
  const subscribeToNotification = () => {

    const onNewMessage = (message) => {
      const object = JSON.parse(message);
      updateGasBoxState(object)
    };
    const objectSubscriptions = gasBoxConfiguration.current.digitalTwins.map(fdt => fdt.$dtId);
    const subscription = {
      channels: objectSubscriptions,
      onNewMessage: onNewMessage,
    };
    subscriptionRef.current = subscription;
    objectSubscriptionService.subscribeObjects(subscription);
  };
  const unSubscribe = () => {
    objectSubscriptionService.unSubscribeObjects(subscriptionRef.current);
  };
  useEffect(() => {
    const fetchState = async () => {
      await fetchObjectState();
      subscribeToNotification();
    };
    fetchState();
    return () => {
      unSubscribe();
    };
  }, []);


  const executeCommand = async (objectId, commandName, params) => {
    const request = {
      instanceId: objectId,
      commandName: commandName,
      parameters: params
    }
    const response = await objectService.ExecuteCommand(request);

    console.log(response);
  }
  const handleValveClick = (value) => {
    var dtObject = gasBoxState[selectedValve];
    executeCommand(dtObject.$dtId, "CloseOpenCmd", {
      OnOff: value
    });
    dtObject["OnOff"] = value;

    setselectedValve(null)
  };

  const handleMfcvalue = () => {
    debugger;
    var dtObject = gasBoxState[selectedMfc];
    if (dtObject["State"] !== 8) {
      window.confirm(`${selectedMfc} is not operational, current state :${dtObject["State"]}`)
      return;
    }

    executeCommand(dtObject.$dtId, "SetFlowSP", {
      FlowSP: mfcvalue
    });

    setselectedMfc(null);
    setmfcvalue(null)

  }
  return (
    <div className="flex flex-col items-center justify-center w-full">
      {/* <div className="md:w-1/2 text-base rounded-lg mb-2 m-1 px-2 py-1 mt-2">
        <strong>{gasBoxState?.find(o => o.name === "MFC_SEQ_Name").currentValue}</strong>  <br /> {gasBoxState?.find(o => o.name === "MFC_SEQ_Cmd").currentValue}
      </div> */}
      <div>
        <div className="absolute top-0 right-0">
          <Alarm isOn={alarm.value} channels={alarm.channels}></Alarm>
        </div>
        <svg className="svg-main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 540" style={{
          width: "80vw",
          height: "calc(100vh - 60px)"
        }}>
          <defs>
            <marker id="mkr_end_svg_284" markerHeight="5" markerUnits="strokeWidth" markerWidth="5" orient="auto" refX="50" refY="50" se_type="rightarrow" viewBox="0 0 100 100">
              <path d="m100,50l-100,40l30,-40l-30,-40l100,40z" fill="#000000" id="svg_8" stroke="#000000" stroke-width="10" />
            </marker>
            <marker id="mkr_end_svg_179" markerHeight="5" markerUnits="strokeWidth" markerWidth="5" orient="auto" refX="50" refY="50" se_type="rightarrow" viewBox="0 0 100 100">
              <path d="m100,50l-100,40l30,-40l-30,-40l100,40z" fill="#000000" id="svg_54" stroke="#000000" stroke-width="10" />
            </marker>
            <marker id="mkr_start_svg_158" markerHeight="5" markerUnits="strokeWidth" markerWidth="5" orient="auto" refX="50" refY="50" se_type="leftarrow" viewBox="0 0 100 100">
              <path d="m0,50l100,40l-30,-40l30,-40l-100,40z" fill="#000000" id="svg_66" stroke="#000000" stroke-width="10" />
            </marker>
            <marker id="mkr_end_svg_7" markerHeight="5" markerUnits="strokeWidth" markerWidth="5" orient="auto" refX="50" refY="50" se_type="rightarrow" viewBox="0 0 100 100">
              <path d="m100,50l-100,40l30,-40l-30,-40l100,40z" fill="#000000" id="svg_67" stroke="#000000" stroke-width="10" />
            </marker>
            <marker id="mkr_start_svg_118" markerHeight="5" markerUnits="strokeWidth" markerWidth="5" orient="auto" refX="50" refY="50" se_type="leftarrow" viewBox="0 0 100 100">
              <path d="m0,50l100,40l-30,-40l30,-40z" fill="#000000" stroke="#000000" stroke-width="10" />
            </marker>
            <marker id="mkr_start_svg_114" markerHeight="5" markerUnits="strokeWidth" markerWidth="5" orient="auto" refX="50" refY="50" se_type="leftarrow" viewBox="0 0 100 100">
              <path d="m0,50l100,40l-30,-40l30,-40z" fill="#000000" stroke="#000000" stroke-width="10" />
            </marker>
            <marker id="mkr_start_svg_18" markerHeight="5" markerUnits="strokeWidth" markerWidth="5" orient="auto" refX="50" refY="50" se_type="leftarrow" viewBox="0 0 100 100">
              <path d="m0,50l100,40l-30,-40l30,-40z" fill="#000000" stroke="#000000" stroke-width="10" />
            </marker>
            <marker id="mkr_start_svg_126" markerHeight="5" markerUnits="strokeWidth" markerWidth="5" orient="auto" refX="50" refY="50" se_type="leftarrow" viewBox="0 0 100 100">
              <path d="m0,50l100,40l-30,-40l30,-40z" fill="#000000" stroke="#000000" stroke-width="10" />
            </marker>
            <marker id="mkr_start_svg_126b" markerHeight="5" markerUnits="strokeWidth" markerWidth="5" orient="auto" refX="50" refY="50" se_type="leftarrow" viewBox="0 0 100 100">
              <path d="m0,50l100,40l-30,-40l30,-40z" fill="#000000" stroke="#000000" stroke-width="10" />
            </marker>
            <marker id="mkr_end_svg_158" markerHeight="5" markerUnits="strokeWidth" markerWidth="5" orient="auto" refX="50" refY="50" se_type="rightarrow" viewBox="0 0 100 100">
              <path d="m100,50l-100,40l30,-40l-30,-40z" fill="#000000" stroke="#000000" stroke-width="10" />
            </marker>
            <marker id="mkr_end_svg_155" markerHeight="5" markerUnits="strokeWidth" markerWidth="5" orient="auto" refX="50" refY="50" se_type="rightarrow" viewBox="0 0 100 100">
              <path d="m100,50l-100,40l30,-40l-30,-40z" fill="#000000" stroke="#000000" stroke-width="10" />
            </marker>
          </defs>
          <g className="layer">
            <title></title>
            <line fill="none" id="svg_18" marker-start="url(#mkr_start_svg_18)" points="null" stroke="#000000" stroke-width="2" x1="554.43" x2="329.32" y1="254.82" y2="255.13" />
            <rect fill="none" height="454.48" id="svg_9" stroke="#ff9d00" stroke-width="1.5" width="444.15" x="65.46" y="17.2">Value</rect>
            <line fill="none" id="svg_7" marker-end="url(#mkr_end_svg_7)" stroke="#000000" stroke-width="2" x1="456.15" x2="36.93" y1="70.35" y2="69.35" />
            <rect fill="#e5e5e5" height="17.31" id="svg_10" stroke="#a8a8a8" width="46" x="142.93" y="60.36" />
            <path d="m214.05,59.04l0,0l0,0z" fill="#000000" id="svg_11" stroke="#000000" />
            <g id="svg_6">
              <path d="m208.37,38.89l10.58,-17.08l10.58,17.08l-21.16,0z" fill="#000000" id="svg_1" stroke="#000000" transform="matrix(0 1 -1 0 257 -149)" />
              <path d="m225.07,23.65l10.56,17.41l10.56,-17.41l-21.12,0z" fill="#000000" id="svg_2" stroke="#000000" transform="matrix(-0.00137526 0.999999 -0.999999 -0.00137526 276.036 -165.83)" />
            </g>
            <g id="svg_12">
              <path d="m208.85,-115.88l10.58,-17.08l10.58,17.08l-21.16,0z" fill="#000000" id="svg_13" stroke="#000000" transform="matrix(0 1 -1 0 257 -149)" />
              <path d="m225.33,-131.12l10.56,17.41l10.56,-17.41l-21.12,0z" fill="#000000" id="svg_14" stroke="#000000" transform="matrix(-0.00137526 0.999999 -0.999999 -0.00137526 276.036 -165.83)" />
            </g>
            <line fill="#adadad" id="svg_20" stroke="#5b5b5b" stroke-width="2" transform="matrix(1 0 0 1 0 0)" x1="164.03" x2="164.38" y1="144.69" y2="182.85" />
            <line fill="#adadad" id="svg_24" stroke="#5b5b5b" stroke-width="2" transform="matrix(1 0 0 1 0 0)" x1="164.03" x2="164.38" y1="205.71" y2="243.87" />
            <line fill="#adadad" id="svg_28" stroke="#5b5b5b" stroke-width="2" transform="matrix(1 0 0 1 0 0)" x1="164.03" x2="164.38" y1="267.37" y2="305.85" />
            <line fill="none" id="svg_32" points="null" stroke="#000000" stroke-width="2" transform="matrix(1 0 0 1 0 0)" x1="307.08" x2="174.89" y1="193.93" y2="194.31" />
            <line fill="none" id="svg_37" points="null" stroke="#000000" stroke-width="2" transform="matrix(1 0 0 1 0 0)" x1="308.11" x2="175.29" y1="255.01" y2="255.4" />
            <text fill="#000000" font-family="Serif" font-size="24" id="svg_40" stroke="#00ff00" stroke-width="0" text-anchor="middle" transform="matrix(0.492644 0 0 0.415771 160.811 108.427)" x="10.42" y="-128.31">Venturi</text>
            <g id="svg_45">
              <g id="svg_15">
                <rect fill="#e5e5e5" height="14" id="svg_16" stroke="#000000" width="21.44" x="222.65" y="33.29" />
                <rect fill="#000000" height="8.97" id="svg_17" stroke="#000000" width="10.72" x="228.19" y="37.96" />
              </g>
              <line fill="none" id="svg_41" stroke="#000000" stroke-width="2" x1="234.06" x2="234.06" y1="48.36" y2="69.47" />
            </g>
            <g id="svg_46">
              <line fill="none" id="svg_42" stroke="#000000" stroke-width="2" x1="389.58" x2="389.58" y1="49.04" y2="70.15" />
              <rect fill="#000000" height="5" id="svg_44" stroke="#000000" width="26.11" x="376.8" y="45.7" />
            </g>
            <line fill="none" id="svg_48" points="null" stroke="#939393" stroke-width="2" transform="matrix(1 0 0 1 0 0)" x1="164" x2="163.71" y1="121.99" y2="77.92" />
            <g id="svg_53">
              <line fill="none" id="svg_50" stroke="#5b5b5b" stroke-width="2" x1="165.37" x2="180.89" y1="91.22" y2="91.22" />
              <ellipse cx="189.55" cy="91.67" fill="none" id="svg_51" rx="8.67" ry="8.67" stroke="#5b5b5b" />
              <text fill="#000000" font-family="Monospace" font-size="24" id="svg_52" stroke="#5b5b5b" stroke-width="0" text-anchor="middle" transform="matrix(0.517334 0 0 0.399075 87.5488 57.7777)" x="197.66" y="92.92">P</text>
            </g>
            <g id="svg_3">
              <ellipse cx="298.07" cy="70.41" fill="#000000" id="svg_4" rx="8" ry="8" stroke="#000000" />
              <g id="svg_5">
                <line fill="none" id="svg_43" stroke="#000000" stroke-width="2" x1="295.49" x2="309.74" y1="56.03" y2="70.28" />
                <line fill="none" id="svg_55" stroke="#000000" stroke-width="2" x1="309.21" x2="294.96" y1="70.28" y2="84.53" />
              </g>
            </g>
            <g id="svg_56">
              <line fill="none" id="svg_57" stroke="#000000" stroke-width="2" x1="342.25" x2="342.25" y1="59.94" y2="79.38" />
              <line fill="none" id="svg_58" stroke="#000000" stroke-width="2" x1="346.14" x2="346.14" y1="60.22" y2="79.66" />
            </g>
            <line fill="none" id="svg_61" points="null" stroke="#000000" stroke-width="2" transform="matrix(1 0 0 1 0 0)" x1="307.12" x2="175.24" y1="132.94" y2="133.32" />
            <g id="svg_70" class="mfcbox" onClick={() => { setselectedMfc("MFC1"); setmfcvalue(gasBoxState["MFC1"].FlowReading) }}>
              <rect fill="#e5e5e5" height="17.31" id="svg_64" stroke="#a8a8a8" width="46" x="221.19" y="124.96">MFC</rect>
              <text className="mfc" fill="#000000" font-family="Serif" font-size="24" id="mfc1" stroke="#a8a8a8" stroke-width="0" text-anchor="middle" transform="matrix(0.469332 0 0 0.469332 125.623 64.4722)" x="250.6" y="155.3">MFC1</text>
            </g>
            <g id="svg_71" class="mfcbox" onClick={() => { setselectedMfc("MFC2"); setmfcvalue(gasBoxState["MFC2"].FlowReading) }}>
              <rect fill="#e5e5e5" height="17.31" id="svg_72" stroke="#a8a8a8" width="46" x="221.61" y="185.01">MFC</rect>
              <text className="mfc" fill="#000000" font-family="Serif" font-size="24" id="mfc2" stroke="#a8a8a8" stroke-width="0" text-anchor="middle" transform="matrix(0.469332 0 0 0.469332 189.849 103.103)" x="114.64" y="200.95">MFC2</text>
            </g>
            <g id="svg_74" class="mfcbox" onClick={() => { setselectedMfc("MFC3"); setmfcvalue(gasBoxState["MFC3"].FlowReading) }}>
              <rect fill="#e5e5e5" height="17.31" id="svg_75" stroke="#a8a8a8" width="46" x="224.47" y="246.92">MFC</rect>
              <text className="mfc" fill="#000000" font-family="Serif" font-size="24" id="mfc3" stroke="#a8a8a8" stroke-width="0" text-anchor="middle" transform="matrix(0.469332 0 0 0.469332 189.849 103.103)" x="120.73" y="332.85">MFC3</text>
            </g>
            <line fill="none" id="svg_81" points="null" stroke="#000000" stroke-width="2" transform="matrix(1 0 0 1 0 0)" x1="308.32" x2="175.18" y1="316.44" y2="316.83" />
            <g id="svg_77" class="mfcbox" onClick={() => { setselectedMfc("MFC4"); setmfcvalue(gasBoxState["MFC4"].FlowReading) }}>
              <rect fill="#e5e5e5" height="17.31" id="svg_78" stroke="#a8a8a8" width="46" x="225.29" y="308.35">MFC</rect>
              <text className="mfc" fill="#000000" font-family="Serif" font-size="24" id="mfc4" stroke="#a8a8a8" stroke-width="0" text-anchor="middle" transform="matrix(0.469332 0 0 0.469332 258.295 19.7697)" x="-23.35" y="641.3">MFC4</text>
            </g>
            <line fill="none" id="svg_142c" points="null" stroke="#000000" stroke-width="2" transform="matrix(1 0 0 1 0 0)" x1="157.88" x2="318" y1="376" y2="376"></line>
              <g id="svg_77b" class="mfcbox" onClick={() => { setselectedMfc("MFC5"); setmfcvalue(gasBoxState["MFC5"].FlowReading) }}>
                <rect fill="#e5e5e5" height="17.31" id="svg_78" stroke="#a8a8a8" width="46" x="225.29" y="368.35">MFC</rect>
                <text className="mfc" fill="#000000" font-family="Serif" font-size="24" id="mfc4" stroke="#a8a8a8" stroke-width="0" text-anchor="middle" transform="matrix(0.469332 0 0 0.469332 258.295 19.7697)" 
                x="-23.35" y="771.3">MFC5</text>
              </g>
            <line fill="none" id="svg_114" marker-start="url(#mkr_start_svg_114)" points="null" stroke="#000000" stroke-width="2" transform="matrix(1 0 0 1 0 0)" x1="558.93" x2="329.58" y1="133.03" y2="133.35" />
            <line fill="none" id="svg_118" marker-start="url(#mkr_start_svg_118)" points="null" stroke="#000000" stroke-width="2" transform="matrix(1 0 0 1 0 0)" x1="555.17" x2="329.75" y1="193.27" y2="193.58" />
            <line fill="none" id="svg_126" marker-start="url(#mkr_start_svg_126)" points="null" stroke="#000000" stroke-width="2" x1="556.12" x2="330.45" y1="316.22" y2="316.52" />
            <line fill="none" id="svg_126" marker-start="url(#mkr_start_svg_126b)" points="null" stroke="#000000" stroke-width="2" x1="556.12" x2="330.45" y1="376.22" y2="376.52" />
            <line fill="#adadad" id="svg_130" stroke="#5b5b5b" stroke-width="2" transform="matrix(1 0 0 1 0 0)" x1="317.77" x2="318.12" y1="144.33" y2="182.49" />
            <line fill="#adadad" id="svg_134" stroke="#5b5b5b" stroke-width="2" transform="matrix(1 0 0 1 0 0)" x1="317.92" x2="318.26" y1="204.81" y2="242.97" />
            <line fill="#adadad" id="svg_138" stroke="#5b5b5b" stroke-width="2" transform="matrix(1 0 0 1 0 0)" x1="318.39" x2="318.75" y1="266.56" y2="304.73" />
            <line fill="none" id="svg_142" points="null" stroke="#000000" stroke-width="2" transform="matrix(1 0 0 1 0 0)" x1="117.88" x2="153.95" y1="317.2" y2="316.84" />
            <line fill="none" id="svg_145" points="null" stroke="#000000" stroke-width="2" transform="matrix(1 0 0 1 0 0)" x1="118.84" x2="154.28" y1="255.29" y2="254.92" />
            <line fill="none" id="svg_148" points="null" stroke="#000000" stroke-width="2" transform="matrix(1 0 0 1 0 0)" x1="117.73" x2="153.48" y1="133.21" y2="132.85" />
            <line fill="none" id="svg_151" points="null" stroke="#000000" stroke-width="2" transform="matrix(1 0 0 1 0 0)" x1="118.36" x2="155.06" y1="194.65" y2="194.29" />
              <line fill="none" id="svg_153" points="null" stroke="#000000" stroke-width="2" transform="matrix(1 0 0 1 0 0)" x1="117.59" x2="118.59" y1="378.54" y2="133.72" />
            <line fill="none" id="svg_155" marker-end="url(#mkr_end_svg_155)" points="null" stroke="#000000" stroke-width="2" transform="matrix(1 0 0 1 0 0)" x1="118.1" x2="85.98" y1="292.04" y2="292.41" />
            <line fill="none" id="svg_158" marker-end="url(#mkr_end_svg_158)" marker-start="url(#mkr_start_svg_158)" points="null" stroke="#000000" stroke-width="2" transform="matrix(1 0 0 1 0 0)" x1="35.99" x2="78" y1="292.73" y2="292.37" />
            <g id="svg_160">
              <line fill="none" id="svg_161" stroke="#000000" stroke-width="2" x1="528.38" x2="528.38" y1="124.37" y2="143.82" />
              <line fill="none" id="svg_162" stroke="#000000" stroke-width="2" x1="532.27" x2="532.27" y1="124.65" y2="144.1" />
            </g>
            <g id="svg_163">
              <line fill="none" id="svg_164" stroke="#000000" stroke-width="2" x1="527.9" x2="527.9" y1="181.52" y2="200.96" />
              <line fill="none" id="svg_165" stroke="#000000" stroke-width="2" x1="531.79" x2="531.79" y1="181.79" y2="201.24" />
            </g>
            <g id="svg_166">
              <line fill="none" id="svg_167" stroke="#000000" stroke-width="2" x1="527.9" x2="527.9" y1="243.9" y2="263.34" />
              <line fill="none" id="svg_168" stroke="#000000" stroke-width="2" x1="531.79" x2="531.79" y1="244.18" y2="263.62" />
            </g>
            <g id="svg_169">
              <line fill="none" id="svg_170" stroke="#000000" stroke-width="2" x1="529.81" x2="529.81" y1="306.28" y2="325.72" />
              <line fill="none" id="svg_171" stroke="#000000" stroke-width="2" x1="533.7" x2="533.7" y1="306.56" y2="326" />
            </g>
            <g id="svg_172">
              <g id="svg_173">
                <rect fill="#e5e5e5" height="14" id="svg_174" stroke="#000000" width="21.44" x="71.03" y="257.57" />
                <rect fill="#000000" height="8.97" id="svg_175" stroke="#000000" width="10.72" x="76.57" y="262.24" />
              </g>
              <line fill="none" id="svg_176" stroke="#000000" stroke-width="2" x1="82.44" x2="82.44" y1="272.64" y2="293.75" />
            </g>
            <line fill="none" id="svg_179" marker-end="url(#mkr_end_svg_179)" stroke="#000000" stroke-width="2" x1="617.08" x2="39.76" y1="444.26" y2="443.26" />
            <g id="svg_185">
              <path d="m582.84,-312.53l10.58,-17.08l10.58,17.08l-21.16,0z" fill="#000000" id="svg_186" stroke="#000000" transform="matrix(0 1 -1 0 257 -149)" />
              <path d="m599.05,-328.28l10.56,17.41l10.56,-17.41l-21.12,0z" fill="#000000" id="svg_187" stroke="#000000" transform="matrix(-0.00137526 0.999999 -0.999999 -0.00137526 276.036 -165.83)" />
            </g>
            <g id="svg_188">
              <line fill="none" id="svg_189" stroke="#000000" stroke-width="2" x1="587.19" x2="587.19" y1="423.45" y2="444.56" />
              <rect fill="#000000" height="5" id="svg_190" stroke="#000000" width="26.11" x="574.41" y="420.11" />
            </g>
            <g id="svg_191">
              <line fill="none" id="svg_192" stroke="#000000" stroke-width="2" x1="529.81" x2="529.81" y1="306.28" y2="325.72" />
              <line fill="none" id="svg_193" stroke="#000000" stroke-width="2" x1="533.7" x2="533.7" y1="306.56" y2="326" />
            </g>
            <g id="svg_191b">
              <line fill="none" id="svg_192" stroke="#000000" stroke-width="2" x1="529.81" x2="529.81" y1="366.28" y2="385.72" />
              <line fill="none" id="svg_193" stroke="#000000" stroke-width="2" x1="533.7" x2="533.7" y1="366.56" y2="386" />
            </g>
            <g id="svg_194">
              <line fill="none" id="svg_195" stroke="#000000" stroke-width="2" x1="534.57" x2="534.57" y1="431.99" y2="451.44" />
              <line fill="none" id="svg_196" stroke="#000000" stroke-width="2" x1="538.46" x2="538.46" y1="432.27" y2="451.72" />
            </g>
            <g id="svg_204" transform="matrix(0.344494 0 0 0.344494 107.086 10.1162)">
              <line fill="none" id="svg_205" stroke="#000000" stroke-width="4" x1="753.19" x2="752.8" y1="365.11" y2="374.63" />
              <rect fill="#ffffff" height="43.89" id="svg_206" stroke="#000000" stroke-width="4" transform="matrix(0.707107 0.707107 -0.707107 0.707107 112.82 -155.192)" width="43.89" x="794.3" y="-111.39" />
              <polyline fill="none" id="svg_207" points="753.4948406219482,329.3870429992676 753.6908855438232,336.0543403625488 753.8869304656982,342.7216987609863 " stroke="#000000" stroke-width="4" />
              <polyline fill="none" id="svg_208" points="752.1916790008545,372.7295112609863 753.3305950164795,380.7504463195801 754.4694499969482,388.7714424133301 " stroke="#000000" stroke-width="4" transform="rotate(4.14346 753.331 380.751)" />
              <polyline fill="none" id="svg_209" points="753.4948406219482,329.3870429992676 753.6908855438232,336.0543403625488 753.8869304656982,342.7216987609863 " stroke="#000000" stroke-width="4" />
              <polyline fill="none" id="svg_210" points="753.4948406219482,351.0537300109863 753.6908855438232,357.7210273742676 753.8869304656982,364.3883247375488 " stroke="#000000" stroke-width="4" />
            </g>
            <g id="svg_211" transform="matrix(0.344494 0 0 0.344494 107.086 10.1162)">
              <line fill="none" id="svg_212" stroke="#000000" stroke-width="4" x1="758.72" x2="758.33" y1="537.9" y2="547.41" />
              <rect fill="#ffffff" height="43.89" id="svg_213" stroke="#000000" stroke-width="4" transform="matrix(0.707107 0.707107 -0.707107 0.707107 112.82 -155.192)" width="43.89" x="920.39" y="6.88" />
              <polyline fill="none" id="svg_214" points="759.0240154266357,502.17326736450195 759.2200603485107,508.84050369262695 759.4161052703857,515.5079231262207 " stroke="#000000" stroke-width="4" />
              <polyline fill="none" id="svg_215" points="757.720853805542,545.5156745910645 758.859769821167,553.5366706848145 759.9986248016357,561.5576057434082 " stroke="#000000" stroke-width="4" transform="rotate(4.14346 758.86 553.537)" />
              <polyline fill="none" id="svg_216" points="759.0240154266357,502.17326736450195 759.2200603485107,508.84050369262695 759.4161052703857,515.5079231262207 " stroke="#000000" stroke-width="4" />
              <polyline fill="none" id="svg_217" points="759.0240154266357,523.8398933410645 759.2200603485107,530.507251739502 759.4161052703857,537.174488067627 " stroke="#000000" stroke-width="4" />
            </g>
            <g id="svg_218" transform="matrix(1 0 0 1 0 0) matrix(0.344494 0 0 0.344494 107.086 10.1162)">
              <line fill="none" id="svg_219" stroke="#000000" stroke-width="4" x1="767.01" x2="766.62" y1="717.59" y2="727.11" />
              <rect fill="#ffffff" height="43.89" id="svg_220" stroke="#000000" stroke-width="4" transform="matrix(0.707107 0.707107 -0.707107 0.707107 112.82 -155.192)" width="43.89" x="1053.32" y="128.08" />
              <polyline fill="none" id="svg_221" points="767.317777633667,681.8708992004395 767.513822555542,688.5381355285645 767.709867477417,695.2055549621582 " stroke="#000000" stroke-width="4" />
              <polyline fill="none" id="svg_222" points="766.0146160125732,725.213306427002 767.1535320281982,733.234302520752 768.292387008667,741.2552375793457 " stroke="#000000" stroke-width="4" transform="rotate(4.14346 767.154 733.235)" />
              <polyline fill="none" id="svg_223" points="767.317777633667,681.8708992004395 767.513822555542,688.5381355285645 767.709867477417,695.2055549621582 " stroke="#000000" stroke-width="4" />
              <polyline fill="none" id="svg_224" points="767.317777633667,703.537525177002 767.513822555542,710.2048835754395 767.709867477417,716.8721199035645 " stroke="#000000" stroke-width="4" />
            </g>
            <g id="svg_225" transform="matrix(0.344494 0 0 0.344494 107.086 10.1162)">
              <line fill="none" id="svg_226" stroke="#000000" stroke-width="4" x1="765.63" x2="765.24" y1="893.14" y2="902.66" />
              <rect fill="#ffffff" height="43.89" id="svg_227" stroke="#000000" stroke-width="4" transform="matrix(0.707107 0.707107 -0.707107 0.707107 112.82 -155.192)" width="43.89" x="1176.47" y="253.19" />
              <polyline fill="none" id="svg_228" points="765.9355144500732,857.4216804504395 766.1315593719482,864.0889167785645 766.3276042938232,870.7563362121582 " stroke="#000000" stroke-width="4" />
              <polyline fill="none" id="svg_229" points="764.6323528289795,900.764087677002 765.7712688446045,908.785083770752 766.9101238250732,916.8060188293457 " stroke="#000000" stroke-width="4" transform="rotate(4.14346 765.772 908.786)" />
              <polyline fill="none" id="svg_230" points="765.9355144500732,857.4216804504395 766.1315593719482,864.0889167785645 766.3276042938232,870.7563362121582 " stroke="#000000" stroke-width="4" />
              <polyline fill="none" id="svg_231" points="765.9355144500732,879.088306427002 766.1315593719482,885.7556648254395 766.3276042938232,892.4229011535645 " stroke="#000000" stroke-width="4" />
            </g>

            <g id="svg_225b" transform="matrix(0.344494 0 0 0.344494 107.086 70.1162)">
                <line fill="none" id="svg_226" stroke="#000000" stroke-width="4" x1="765.63" x2="765.24" y1="893.14" y2="902.66"></line>
                <rect fill="#ffffff" height="43.89" id="svg_227" stroke="#000000" stroke-width="4" transform="matrix(0.707107 0.707107 -0.707107 0.707107 112.82 -155.192)" width="43.89" x="1176.47" y="253.19"></rect>
                <polyline fill="none" id="svg_228" points="765.9355144500732,857.4216804504395 766.1315593719482,864.0889167785645 766.3276042938232,870.7563362121582 " stroke="#000000" stroke-width="4"></polyline>
                <polyline fill="none" id="svg_229" points="764.6323528289795,900.764087677002 765.7712688446045,908.785083770752 766.9101238250732,916.8060188293457 " stroke="#000000" stroke-width="4" transform="rotate(4.14346 765.772 908.786)"></polyline>
                <polyline fill="none" id="svg_230" points="765.9355144500732,857.4216804504395 766.1315593719482,864.0889167785645 766.3276042938232,870.7563362121582 " stroke="#000000" stroke-width="4"></polyline>
                <polyline fill="none" id="svg_231" points="765.9355144500732,879.088306427002 766.1315593719482,885.7556648254395 766.3276042938232,892.4229011535645 " stroke="#000000" stroke-width="4"></polyline>
              </g>

            <g id="svg_232">
              <ellipse cx="414.74" cy="133.26" fill="#000000" id="svg_233" rx="8" ry="8" stroke="#000000" stroke-width="2" />
              <g id="svg_234">
                <line fill="none" id="svg_235" stroke="#000000" stroke-width="2" x1="412.16" x2="426.41" y1="118.88" y2="133.13" />
                <line fill="none" id="svg_236" stroke="#000000" stroke-width="2" x1="425.88" x2="411.63" y1="133.14" y2="147.39" />
              </g>
            </g>
            <g id="svg_237">
              <ellipse cx="416.17" cy="194.22" fill="#000000" id="svg_238" rx="8" ry="8" stroke="#000000" stroke-width="2" />
              <g id="svg_239">
                <line fill="none" id="svg_240" stroke="#000000" stroke-width="2" x1="413.58" x2="427.83" y1="179.83" y2="194.08" />
                <line fill="none" id="svg_241" stroke="#000000" stroke-width="2" x1="427.31" x2="413.06" y1="194.09" y2="208.34" />
              </g>
            </g>
            <g id="svg_242">
              <ellipse cx="416.64" cy="255.17" fill="#000000" id="svg_243" rx="8" ry="8" stroke="#000000" stroke-width="2" />
              <g id="svg_244">
                <line fill="none" id="svg_245" stroke="#000000" stroke-width="2" x1="414.06" x2="428.31" y1="240.79" y2="255.04" />
                <line fill="none" id="svg_246" stroke="#000000" stroke-width="2" x1="427.78" x2="413.53" y1="255.04" y2="269.29" />
              </g>
            </g>
            <g id="svg_247">
              <ellipse cx="415.69" cy="315.65" fill="#000000" id="svg_248" rx="8" ry="8" stroke="#000000" stroke-width="2" />
              <g id="svg_249">
                <line fill="none" id="svg_250" stroke="#000000" stroke-width="2" x1="413.11" x2="427.36" y1="301.26" y2="315.51" />
                <line fill="none" id="svg_251" stroke="#000000" stroke-width="2" x1="426.83" x2="412.58" y1="315.52" y2="329.77" />
              </g>
            </g>
            <g id="svg_247b">
              <ellipse cx="415.69" cy="375.65" fill="#000000" id="svg_248" rx="8" ry="8" stroke="#000000" stroke-width="2" />
              <g id="svg_249">
                <line fill="none" id="svg_250" stroke="#000000" stroke-width="2" x1="413.11" x2="427.36" y1="361.26" y2="375.51" />
                <line fill="none" id="svg_251" stroke="#000000" stroke-width="2" x1="426.83" x2="412.58" y1="375.52" y2="389.77" />
              </g>
            </g>
            <g id="svg_252">
              <line fill="none" id="svg_253" stroke="#5b5b5b" stroke-width="2" transform="rotate(-90 361.358 435.112)" x1="353.6" x2="369.12" y1="435.12" y2="435.12" />
              <ellipse cx="361.81" cy="418.69" fill="none" id="svg_254" rx="8.67" ry="8.67" stroke="#5b5b5b" transform="rotate(-90 361.81 418.687)" />
              <text fill="#000000" font-family="Monospace" font-size="24" id="svg_255" stroke="#5b5b5b" stroke-width="0" text-anchor="middle" transform="rotate(0.22941 362.099 418.434) matrix(0.517334 0 0 0.399075 87.5488 57.7777)" x="530.35" y="911.46">P</text>
            </g>
            <g id="svg_261">
              <path d="m581.64,-128.78l10.58,-17.08l10.58,17.08l-21.16,0z" fill="#000000" id="svg_262" stroke="#000000" transform="matrix(0 1 -1 0 257 -149)" />
              <path d="m598.1,-144.53l10.56,17.41l10.56,-17.41l-21.12,0z" fill="#000000" id="svg_263" stroke="#000000" transform="matrix(-0.00137526 0.999999 -0.999999 -0.00137526 276.036 -165.83)" />
            </g>
            <g id="svg_256">
              <g id="svg_257">
                <rect fill="#e5e5e5" height="14" id="svg_258" stroke="#000000" width="21.44" x="390.32" y="406.56" />
                <rect fill="#000000" height="8.97" id="svg_259" stroke="#000000" width="10.72" x="395.86" y="411.23" />
              </g>
              <line fill="none" id="svg_260" stroke="#000000" stroke-width="2" x1="401.73" x2="401.73" y1="421.63" y2="442.74" />
            </g>
            <g id="svg_264">
              <ellipse cx="443.79" cy="443.26" fill="#000000" id="svg_265" rx="8" ry="8" stroke="#000000" stroke-width="2" />
              <g id="svg_266">
                <line fill="none" id="svg_267" stroke="#000000" stroke-width="2" x1="441.2" x2="455.45" y1="428.88" y2="443.13" />
                <line fill="none" id="svg_268" stroke="#000000" stroke-width="2" x1="454.93" x2="440.68" y1="443.14" y2="457.39" />
              </g>
            </g>
            <g id="svg_269" transform="matrix(0.344494 0 0 0.344494 107.086 10.1162)">
              <line fill="none" id="svg_270" stroke="#000000" stroke-width="4" x1="1096" x2="1095.61" y1="1264.98" y2="1274.5" />
              <rect fill="#ffffff" height="43.89" id="svg_271" stroke="#000000" stroke-width="4" transform="matrix(0.707107 0.707107 -0.707107 0.707107 112.82 -155.192)" width="43.89" x="1673" y="282.51" />
              <polyline fill="none" id="svg_272" points="1096.3027019500732,1229.2576179504395 1096.4987468719482,1235.9248542785645 1096.6947917938232,1242.592212677002 " stroke="#000000" stroke-width="4" />
              <polyline fill="none" id="svg_273" points="1094.9994792938232,1272.600025177002 1096.1383953094482,1280.621021270752 1097.2773113250732,1288.6418952941895 " stroke="#000000" stroke-width="4" transform="rotate(4.14346 1096.14 1280.62)" />
              <polyline fill="none" id="svg_274" points="1096.3027019500732,1229.2576179504395 1096.4987468719482,1235.9248542785645 1096.6947917938232,1242.592212677002 " stroke="#000000" stroke-width="4" />
              <polyline fill="none" id="svg_275" points="1096.3027019500732,1250.924243927002 1096.4987468719482,1257.5916023254395 1096.6947917938232,1264.2588386535645 " stroke="#000000" stroke-width="4" />
            </g>
            <g id="svg_276">
              <path d="m582.11,63.6l10.58,-17.08l10.58,17.08l-21.16,0z" fill="#000000" id="svg_277" stroke="#000000" transform="matrix(0 1 -1 0 257 -149)" />
              <path d="m598.85,47.85l10.56,17.41l10.56,-17.41l-21.12,0z" fill="#000000" id="svg_278" stroke="#000000" transform="matrix(-0.00137526 0.999999 -0.999999 -0.00137526 276.036 -165.83)" />
            </g>
            <g id="svg_279">
              <g id="svg_280">
                <rect fill="#e5e5e5" height="14" id="svg_281" stroke="#000000" width="21.44" x="197.94" y="407.03" />
                <rect fill="#000000" height="8.97" id="svg_282" stroke="#000000" width="10.72" x="203.48" y="411.7" />
              </g>
              <line fill="none" id="svg_283" stroke="#000000" stroke-width="2" x1="209.35" x2="209.35" y1="422.1" y2="443.21" />
            </g>
            <line fill="none" id="svg_284" marker-end="url(#mkr_end_svg_284)" stroke="#000000" stroke-width="2" transform="rotate(6.29067 210.618 443.311)" x1="198.24" x2="223" y1="463.08" y2="423.55" />
            <line fill="none" id="svg_285" stroke="#000000" stroke-width="2" transform="matrix(1 0 0 1 0 0)" x1="319.33" x2="318.87" y1="442.65" y2="327.93" />
            <g id="mfcbox1" class="mfcvalbox">
              <rect fill="none" height="18" id="svg_99" stroke="#ff9d00" strokeWidth="1" width="48.67" x="219.76" y="99.96" />
              <text class="mfcvalue" fill="#000000" fontFamily="Serif" fontSize="24" id="mfcvalue1" stroke="#ff9d00" strokeWidth="0" textAnchor="middle" transform="matrix(0.546972 0 0 0.546972 80.8582 52.5986)" x="299.81" y="110.63">{gasBoxState["MFC1"].FlowReading}</text>
            </g>
            <g id="mfcbox2" class="mfcvalbox">
              <rect fill="none" height="18" id="svg_105" stroke="#ff9d00" strokeWidth="2" width="48.67" x="220.47" y="159.96" />
              <text class="mfcvalue" fill="#000000" fontFamily="Serif" fontSize="24" id="mfcvalue2" stroke="#ff9d00" strokeWidth="0" textAnchor="middle" transform="matrix(0.546972 0 0 0.546972 80.8582 52.5986)" x="301.11" y="220.33" >{gasBoxState["MFC2"].FlowReading}</text>
            </g>
            <g id="mfcbox3" class="mfcvalbox">
              <rect fill="none" height="18" id="svg_90" stroke="#ff9d00" strokeWidth="2" width="48.67" x="221.9" y="222.1" />
              <text class="mfcvalue" fill="#000000" fontFamily="Serif" fontSize="24" id="mfcvalue3" stroke="#ff9d00" strokeWidth="0" textAnchor="middle" transform="matrix(0.546972 0 0 0.546972 80.8582 52.5986)" x="303.72" y="333.94">{gasBoxState["MFC3"].FlowReading}</text>
            </g>
            <g id="mfcbox4" class="mfcvalbox">
              <rect fill="none" height="18" id="svg_85" stroke="#ff9d00" strokeWidth="2" width="48.67" x="224.04" y="282.81" />
              <text class="mfcvalue" fill="#000000" fontFamily="Serif" fontSize="24" id="mfcvalue4" stroke="#ff9d00" strokeWidth="0" textAnchor="middle" transform="matrix(0.546972 0 0 0.546972 80.8582 52.5986)" x="307.64" y="444.94">{gasBoxState["MFC4"].FlowReading}</text>
            </g>
            <g id="mfcbox5" class="mfcvalbox">
                <rect fill="none" height="18" id="svg_85" stroke="#ff9d00" strokeWidth="2" width="48.67" x="224.04" y="342.81" />
                <text class="mfcvalue" fill="#000000" fontFamily="Serif" fontSize="24" id="mfcvalue4" stroke="#ff9d00" strokeWidth="0" textAnchor="middle" transform="matrix(0.546972 0 0 0.546972 80.8582 52.5986)" 
                x="307.64" y="554.94">{gasBoxState["MFC5"].FlowReading}</text>
              </g>
              <text fill="#000000" font-family="Serif" font-size="24" id="svg_107" stroke="#000000" stroke-width="0" text-anchor="middle" transform="matrix(1 0 0 1 0 0) matrix(0.470224 0 0 0.470224 14.3575 29.675)" x="42.73" y="66.95">Exhaust</text>
              <text fill="#000000" font-family="Serif" font-size="24" id="svg_108" stroke="#000000" stroke-width="0" text-anchor="middle" transform="matrix(1 0 0 1 0 0) matrix(0.470224 0 0 0.470224 14.3575 29.675)" x="29.06" y="533.29">RPS</text>
              <text fill="#000000" font-family="Serif" font-size="24" id="svg_109" stroke="#000000" stroke-width="0" text-anchor="middle" transform="matrix(0.426575 0 0 0.426575 12.4297 65.5568)" x="45.8" y="864.47">Chamber Vent</text>
              <text fill="#000000" font-family="Serif" font-size="24" id="svg_110" stroke="#ff9d00" stroke-width="0" text-anchor="left" transform="matrix(1 0 0 1 0 0) matrix(0.483628 0 0 0.461901 306.689 70.7861)" x="571.04" y="140">SF6</text>
              <text fill="#000000" font-family="Serif" font-size="24" id="svg_111" stroke="#ff9d00" stroke-width="0" text-anchor="left" transform="matrix(0.483628 0 0 0.461901 306.689 70.7861)" x="563.29" y="269.9">CF4</text>
              <text fill="#000000" font-family="Serif" font-size="24" id="svg_112" stroke="#ff9d00" stroke-width="0" text-anchor="left" transform="matrix(1 0 0 1 0 0) matrix(0.483628 0 0 0.461901 306.689 70.7861)" x="573.29" y="404.44">O2</text>
              <text fill="#000000" font-family="Serif" font-size="24" id="svg_177" stroke="#ff9d00" stroke-width="0" text-anchor="left" transform="matrix(1 0 0 1 0 0) matrix(0.483628 0 0 0.461901 306.689 70.7861)" x="575.1" y="535.88">Ar/H2</text>
              <text fill="#000000" font-family="Serif" font-size="24" id="svg_177" stroke="#ff9d00" stroke-width="0" text-anchor="left" transform="matrix(1 0 0 1 0 0) matrix(0.483628 0 0 0.461901 306.689 70.7861)" x="579.1" y="665.88">Ar</text>
              <text fill="#000000" font-family="Serif" font-size="24" id="svg_178" stroke="#ff9d00" stroke-width="0" text-anchor="left" transform="matrix(0.483628 0 0 0.461901 306.689 70.7861)" x="665.96" y="812.69">He</text>

            <g className="bulbbox" id="bulbbox1" transform="matrix(1 0 0 1 0 0)" onClick={() => { setselectedValve("DownStreamValve1"); setValvetitle('SF6 Down Valve 1') }}>
              <title>SF6 Down V1</title>
              <ellipse className="bulb" cx="181.13" cy="116.34" fill={gasBoxState["DownStreamValve1"].OnOff ? '#00FF00' : '#FF0000'} id="bulb1" rx="4.17" ry="4.17" stroke="#000000" stroke-width="0" />
              <text className="bulbtext" fill="#000000" font-family="Serif" font-size="24" id="bulbtext1" stroke="#000000" stroke-width="0" text-anchor="middle" transform="matrix(1 0 0 1 0 0) matrix(0.417261 0 0 0.417261 51.7214 51.1718)" x="348.14" y="163.17"> {gasBoxState["DownStreamValve1"]?.OnOff ? 'Open' : 'Close'}</text>
              <g className={`valveset ${gasBoxState["DownStreamValve1"]?.OnOff ? 'activevalve' : 'inactive'}`} id="valveset1">
                <path d="m156.71,144.68l7.25,-11.85l7.25,11.85l-14.49,0l-0.01,0z" id="svg_60" stroke="#5b5b5b" stroke-width="0" />
                <path d="m153.61,125.92l10.72,6.89l-10.72,6.89l0,-13.78z" id="svg_83" stroke="#000000" stroke-width="0" />
                <path d="m171.22,121.29l-7.25,11.85l-7.25,-11.85l14.49,0l0.01,0z" id="svg_84" stroke="#5b5b5b" stroke-width="0" />
                <path d="m175.2,139.42l-11.2,-6.57l11.2,-6.57l0,13.15z" id="svg_86" stroke="#000000" stroke-width="0" />
              </g>
            </g>


            <g className="bulbbox" id="bulbbox2" onClick={() => { setselectedValve("UpStreamValve1"); setValvetitle('SF6 Up V1') }}>
              <title>SF6 Up V1</title>
              <ellipse className="bulb" cx="312.69" cy="114.97" fill={gasBoxState["UpStreamValve1"].OnOff ? '#00FF00' : '#FF0000'} id="svg_88" rx="4.17" ry="4.17" stroke="#000000" stroke-width="0" />
              <text className="bulbtext" fill="#000000" font-family="Serif" font-size="24" id="svg_89" stroke="#000000" stroke-width="0" text-anchor="middle" transform="matrix(0.417261 0 0 0.417261 51.7214 51.1718)" x="664.44" y="159.9">{gasBoxState["UpStreamValve1"].OnOff ? 'Open' : 'Close'}</text>
              <g className={`valveset ${gasBoxState["UpStreamValve1"]?.OnOff ? 'activevalve' : 'inactive'}`} id="valveset2">
                <path d="m307.08,126.71l11.34,6.58l-11.34,6.58l0,-13.15z" id="svg_62" stroke="#000000" stroke-width="0" />
                <path d="m328.51,139.84l-11.03,-6.64l11.03,-6.64l0,13.28z" id="svg_116" stroke="#000000" stroke-width="0" />
                <path d="m310.7,144.78l7.25,-11.85l7.25,11.85l-14.49,0l-0.01,0z" id="svg_131" stroke="#848484" stroke-width="0" />
                <path d="m325.22,121.29l-7.25,11.85l-7.25,-11.85l14.49,0l0.01,0z" id="svg_84" stroke="#5b5b5b" stroke-width="0" />
              </g>
            </g>
            <g className="bulbbox" id="bulbbox3" onClick={() => { setselectedValve("DownStreamValve2"); setValvetitle('CF4 Down V2') }} >
              <title>CF4 Down V2</title>
              <ellipse className="bulb" cx="182.22" cy="171.64" fill={gasBoxState["DownStreamValve2"].OnOff ? '#00FF00' : '#FF0000'} id="svg_91" rx="4.17" ry="4.17" stroke="#000000" stroke-width="0" />
              <text className="bulbtext" fill="#000000" font-family="Serif" font-size="24" id="svg_92" stroke="#000000" stroke-width="0" text-anchor="middle" transform="matrix(0.417261 0 0 0.417261 51.7214 51.1718)" x="354.74" y="295.71">{gasBoxState["DownStreamValve2"].OnOff ? 'Open' : 'Close'}</text>
              <g className={`valveset ${gasBoxState["DownStreamValve2"]?.OnOff ? 'activevalve' : 'inactive'}`} id="valveset3">
                <path d="m175.2,200.63l-11.2,-6.57l11.2,-6.57l0,13.15z" id="svg_36" stroke="#000000" stroke-width="0" />
                <path d="m156.71,205.93l7.25,-11.85l7.25,11.85l-14.49,0l-0.01,0z" id="svg_59" stroke="#5b5b5b" stroke-width="0" />
                <path d="m171.53,182.51l-7.25,11.85l-7.25,-11.85l14.49,0l0.01,0z" id="svg_79" stroke="#5b5b5b" stroke-width="0" />
                <path d="m153.92,187.17l10.72,6.89l-10.72,6.89l0,-13.78z" id="svg_80" stroke="#000000" stroke-width="0" />
              </g>
            </g>
            <g className="bulbbox" id="bulbbox4" onClick={() => { setselectedValve("UpStreamValve2"); setValvetitle('CF4 Up Valve 2') }}>
              <title>CF4 Up V2</title>
              <ellipse className="bulb" cx="335.07" cy="172.12" fill={gasBoxState["UpStreamValve2"].OnOff ? '#00FF00' : '#FF0000'} id="svg_94" rx="4.17" ry="4.17" stroke="#000000" stroke-width="0" />
              <text className="bulbtext" fill="#000000" font-family="Serif" font-size="24" id="svg_95" stroke="#000000" stroke-width="0" text-anchor="middle" transform="matrix(0.417261 0 0 0.417261 51.7214 51.1718)" x="717.08" y="296.85">{gasBoxState["UpStreamValve2"].OnOff ? 'Open' : 'Close'}</text>
              <g className={`valveset ${gasBoxState["UpStreamValve2"]?.OnOff ? 'activevalve' : 'inactive'}`} id="valveset4">
                <path d="m306.74,186.45l11.03,6.99l-11.03,6.99l0,-13.99z" id="svg_33" stroke="#000000" stroke-width="0" />
                <path d="m329.94,200.35l-11.79,-6.82l11.79,-6.82l0,13.64z" id="svg_120" stroke="#000000" stroke-width="0" />
                <path d="m325.19,182.11l-7.25,11.85l-7.25,-11.85l14.49,0l0.01,0z" id="svg_132" stroke="#848484" stroke-width="0" />
                <path d="m310.85,204.94l7.25,-11.85l7.25,11.85l-14.49,0l-0.01,0z" id="svg_135" stroke="#5b5b5b" stroke-width="0" />
              </g>
            </g>
            <g className="bulbbox" id="bulbbox5" onClick={() => { setselectedValve("DownStreamValve3"); setValvetitle('O2 Down Valve 3') }}>
              <title>O2 Down V3</title>
              <ellipse className="bulb" cx="179.83" cy="233.07" fill={gasBoxState["DownStreamValve3"].OnOff ? '#00FF00' : '#FF0000'} id="svg_97" rx="4.17" ry="4.17" stroke="#000000" stroke-width="0" />
              <text className="bulbtext" fill="#000000" font-family="Serif" font-size="24" id="svg_98" stroke="#000000" stroke-width="0" text-anchor="middle" transform="matrix(0.417261 0 0 0.417261 51.7214 51.1718)" x="344.04" y="442.93">{gasBoxState["DownStreamValve3"].OnOff ? 'Open' : 'Close'}</text>
              <g className={`valveset ${gasBoxState["DownStreamValve3"].OnOff ? 'activevalve' : 'inactive'}`} id="valveset5">
                <path d="m153.61,248.74l10.72,6.89l-10.72,6.89l0,-13.78z" id="svg_31" stroke="#000000" stroke-width="0" />
                <path d="m175.2,262.19l-11.2,-6.57l11.2,-6.57l0,13.15z" id="svg_35" stroke="#000000" stroke-width="0" />
                <path className="valveset" d="m156.71,267.49l7.25,-11.85l7.25,11.85l-14.49,0l-0.01,0z" id="svg_65" stroke="#5b5b5b" stroke-width="0" />
                <path d="m171.22,243.76l-7.25,11.85l-7.25,-11.85l14.49,0l0.01,0z" id="svg_76" stroke="#5b5b5b" stroke-width="0" />
              </g>
            </g>
            <g className="bulbbox" id="bulbbox6" transform="matrix(1 0 0 1 0 0)" onClick={() => { setselectedValve("UpStreamValve3"); setValvetitle('O2 Up Valve 3') }}>
              <title>O2 Up V3</title>
              <ellipse className="bulb" cx="334.12" cy="230.69" fill={gasBoxState["UpStreamValve3"].OnOff ? '#00FF00' : '#FF0000'} id="svg_100" rx="4.17" ry="4.17" stroke="#000000" stroke-width="0" />
              <text className="bulbtext" fill="#000000" font-family="Serif" font-size="24" id="svg_101" stroke="#000000" stroke-width="0" text-anchor="middle" transform="matrix(0.417261 0 0 0.417261 51.7214 51.1718)" x="715.79" y="437.22">{gasBoxState["UpStreamValve3"].OnOff ? 'Open' : 'Close'}</text>
              <g className={`valveset ${gasBoxState["UpStreamValve3"].OnOff ? 'activevalve' : 'inactive'}`} id="valveset6">
                <path d="m308.08,247.84l10.72,6.89l-10.72,6.89l0,-13.78z" id="svg_38" stroke="#000000" stroke-width="0" />
                <path d="m329.33,261.37l-11.2,-6.57l11.2,-6.57l0,13.15z" id="svg_124" stroke="#000000" stroke-width="0" />
                <path d="m325.65,242.59l-7.25,11.85l-7.25,-11.85l14.49,0l0.01,0z" id="svg_136" stroke="#5b5b5b" stroke-width="0" />
                <path d="m311.01,266.7l7.25,-11.85l7.25,11.85l-14.49,0l-0.01,0z" id="svg_139" stroke="#5b5b5b" stroke-width="0" />
              </g>
            </g>
            <g class="bulbbox" id="bulbbox7" transform="matrix(1 0 0 1 0 0)" onClick={() => { setselectedValve("DownStreamValve4"); setValvetitle('Ar/H2 Down Valve 4') }}>
              <title>Ar/H2 Down V4</title>
              <ellipse class="bulb" cx="181.03" cy="294.55" fill={gasBoxState["DownStreamValve4"].OnOff ? '#00FF00' : '#FF0000'} id="svg_103" rx="4.17" ry="4.17" stroke="#000000" stroke-width="0" />
              <text class="bulbtext" fill="#000000" font-family="Serif" font-size="24" id="svg_104" stroke="#000000" stroke-width="0" text-anchor="middle" transform="matrix(0.417261 0 0 0.417261 51.7214 51.1718)" x="348.89" y="590.27">{gasBoxState["DownStreamValve4"].OnOff ? 'Open' : 'Close'}</text>
              <g className={`valveset ${gasBoxState["DownStreamValve4"].OnOff ? 'activevalve' : 'inactive'}`} id="valveset7">
                <path d="m175.19,323.76l-11.2,-6.57l11.2,-6.57l0,13.15z" id="svg_39" stroke="#000000" stroke-width="0" />
                <path d="m171.53,305.92l-7.25,11.85l-7.25,-11.85l14.49,0l0.01,0z" id="svg_121" stroke="#5b5b5b" stroke-width="0" />
                <path d="m153.92,310.26l10.72,6.89l-10.72,6.89l0,-13.78z" id="svg_122" stroke="#000000" stroke-width="0" />
                <path d="m157,327.74l7.25,-11.85l7.25,11.85l-14.49,0l-0.01,0z" id="svg_129" stroke="#5b5b5b" stroke-width="0" />
              </g>
            </g>
            <g className="bulbbox" id="bulbbox8" transform="matrix(1 0 0 1 0 0)" onClick={() => { setselectedValve("UpStreamValve4"); setValvetitle('Ar/H2 Up Valve 4') }}>
              <title>Ar/H2 Up V4</title>
              <ellipse className="bulb" cx="336.03" cy="293.55" fill={gasBoxState["UpStreamValve4"].OnOff ? '#00FF00' : '#FF0000'} id="svg_103" rx="4.17" ry="4.17" stroke="#000000" stroke-width="0" />
              <text className="bulbtext" fill="#000000" font-family="Serif" font-size="24" id="svg_104" stroke="#000000" stroke-width="0" text-anchor="middle" transform="matrix(0.417261 0 0 0.417261 51.7214 51.1718)" x="720.36" y="587.87">{gasBoxState["UpStreamValve4"].OnOff ? 'Open' : 'Close'}</text>
              <g className={`valveset ${gasBoxState["UpStreamValve4"].OnOff ? 'activevalve' : 'inactive'}`} id="valveset8">
                <path d="m326.13,304.35l-7.25,11.85l-7.25,-11.85l14.49,0l0.01,0z" id="svg_140" stroke="#5b5b5b" stroke-width="0" />
                <path d="m330.02,322.48l-11.27,-6.41l11.27,-6.41l0,12.82z" id="svg_128" stroke="#000000" stroke-width="0" />
                <path d="m308.6,309.27l10.41,6.89l-10.41,6.89l0,-13.78z" id="svg_82" stroke="#000000" stroke-width="0" />
                <path d="m311.72,327.74l7.25,-11.85l7.25,11.85l-14.49,0l-0.01,0z" id="svg_129" stroke="#5b5b5b" stroke-width="0" />
              </g>
            </g>


              {/* new gas line */}
              <line fill="#adadad" id="svg_28" stroke="#5b5b5b" stroke-width="2" transform="matrix(1 0 0 1 0 0)" x1="164.03" x2="164.38" y1="328" y2="366"></line>
              <line fill="none" id="svg_142b" points="null" stroke="#000000" stroke-width="2" transform="matrix(1 0 0 1 0 0)" x1="117.88" x2="153.95" y1="377" y2="377"></line>
              <g class="bulbbox" id="bulbbox7" transform="matrix(1 0 0 1 0 0)" onClick={() => { setselectedValve("DownStreamValve5"); setValvetitle('Ar Down Valve 5 ') }}>
                <title>Ar Down V5</title>
                <ellipse class="bulb" cx="181.03" cy="353.55" fill={gasBoxState["DownStreamValve5"].OnOff ? '#00FF00' : '#FF0000'} id="svg_103" rx="4.17" ry="4.17" stroke="#000000" stroke-width="0" />
                <text class="bulbtext" fill="#000000" font-family="Serif" font-size="24" id="svg_104" stroke="#000000" stroke-width="0" text-anchor="middle" transform="matrix(0.417261 0 0 0.417261 51.7214 110.1718)" x="348.89" y="590.27">{gasBoxState["DownStreamValve5"].OnOff ? 'Open' : 'Close'}</text>
                <g className={`valveset ${gasBoxState["DownStreamValve5"].OnOff ? 'activevalve' : 'inactive'}`} id="valveset7">
                  <path d="m175.19,382.76l-11.2,-6.57l11.2,-6.57l0,13.15z" id="svg_39" stroke="#000000" stroke-width="0" />
                  <path d="m171.53,365.92l-7.25,11.85l-7.25,-11.85l14.49,0l0.01,0z" id="svg_121" stroke="#5b5b5b" stroke-width="0" />
                  <path d="m153.92,369.26l10.72,6.89l-10.72,6.89l0,-13.78z" id="svg_122" stroke="#000000" stroke-width="0" />
                  <path d="m157,385.74l7.25,-11.85l7.25,11.85l-14.49,0l-0.01,0z" id="svg_129" stroke="#5b5b5b" stroke-width="0" />
                </g>
              </g>

              <g className="bulbbox" id="bulbbox8" transform="matrix(1 0 0 1 0 0)" onClick={() => { setselectedValve("UpStreamValve5"); setValvetitle('Ar Up Valve 5') }}>
                <title>Ar Up V5</title>
                <ellipse className="bulb" cx="336.03" cy="352.55" fill={gasBoxState["UpStreamValve5"].OnOff ? '#00FF00' : '#FF0000'} id="svg_103" rx="4.17" ry="4.17" stroke="#000000" stroke-width="0" />
                <text className="bulbtext" fill="#000000" font-family="Serif" font-size="24" id="svg_104" stroke="#000000" stroke-width="0" text-anchor="middle" transform="matrix(0.417261 0 0 0.417261 51.7214 51.1718)" x="720.36" y="730.87">{gasBoxState["UpStreamValve5"].OnOff ? 'Open' : 'Close'}</text>
                <g className={`valveset ${gasBoxState["UpStreamValve5"].OnOff ? 'activevalve' : 'inactive'}`} id="valveset7">
                  <path d="m326.13,365.35l-7.25,11.85l-7.25,-11.85l14.49,0l0.01,0z" id="svg_140" stroke="#5b5b5b" stroke-width="0" />
                  <path d="m330.02,382.48l-11.27,-6.41l11.27,-6.41l0,12.82z" id="svg_128" stroke="#000000" stroke-width="0" />
                  <path d="m308.6,369.27l10.41,6.89l-10.41,6.89l0,-13.78z" id="svg_82" stroke="#000000" stroke-width="0" />
                  <path d="m311.72,386.74l7.25,-11.85l7.25,11.85l-14.49,0l-0.01,0z" id="svg_129" stroke="#5b5b5b" stroke-width="0" />
                </g>
              </g>
              {/* new gas line end */}

          </g>
        </svg>
      </div>
      {selectedMfc != null &&
        <div class="fixed w-full h-full bg-black bg-opacity-25 top-0 left-0">
          <div className='mx-auto w-1/3 bg-white p-4 mt-36 relative shadow-2xl'>
            <button className='bg-red-500 px-2 text-white absolute right-3 rounded' onClick={() => { setselectedMfc(null); setmfcvalue(null) }}>close</button>
            <div className='text-base font-bold'>Change Value of {selectedMfc}</div>

            <input type='number' value={mfcvalue ? mfcvalue : ''} onChange={(e) => setmfcvalue(e.target.value)} className='p-2 text-lg border bg-gray-100 w-full mt-5' placeholder='change value' />
            <button className='bg-gray-600 rounded hover:bg-gray-400 text-white px-3 py-2 mt-3' onClick={() => handleMfcvalue()}>Update</button>
          </div>
        </div>
      }
      {selectedValve != null &&
        <div class="fixed w-full h-full bg-black bg-opacity-25 top-0 left-0">
          <div className='mx-auto w-1/4 bg-white p-4 mt-36 relative shadow-2xl'>
            <div className='text-base font-bold'>{gasBoxState[selectedValve].Open_fbk === true ? 'Do you want to close ' : 'Do you want to open '} {Valvetitle} ?</div>
            <div className='flex justify-between mt-5'>
              {gasBoxState[selectedValve].Open_fbk === true ?
                <button className='bg-white-500 shadow-md hover:bg-gray-300 border border-b text-black px-3 py-2 mt-3 rounded font-bold' onClick={() => { handleValveClick(false); }}>Yes</button>
                :
                <button className='bg-white-500 shadow-md hover:bg-gray-300 border border-b text-black px-3 py-2 mt-3 rounded  font-bold' onClick={() => handleValveClick(true)}>Yes</button>
              }
              <button className='bg-gray-600 text-white px-3 py-2 mt-3 rounded' onClick={() => setselectedValve(null)}>No</button>
            </div>


            {/* <input type='number' value={mfcvalue? mfcvalue:''} onChange={(e)=>setmfcvalue(e.target.value)} className='p-2 text-lg border bg-gray-100 w-full mt-5' placeholder='change value' /> */}
            {/* <button className='bg-orange-500 text-white px-3 py-2 mt-3 rounded'  onClick={()=> handleMfcvalue()}>Update</button> */}
          </div>
        </div>
      }

      <div>
      </div></div>
  );
}