import React, { useEffect, useRef, useState, useReducer } from "react";
import { ObjectService, ObjectSubscriptionService } from "@getvia/sdk";

const objectId = "dtmi:com:via:Subsystem:DicingRIEGasBox;1"; //TODO: make it configurable


const viaConfig = {
  objectServiceOptions: {
    apiToken: 'test',
    baseUrl: process.env.REACT_APP_VIA_OBJECT_API,
    wsUrl: process.env.REACT_APP_VIA_OBJECT_NOTIFICATION_HUB
  }
};
const objectService = new ObjectService(viaConfig.objectServiceOptions);
const objectSubscriptionService = new ObjectSubscriptionService(viaConfig.objectServiceOptions);
const defaultGasBoxState = {
  "MFC7": {
    "State": 0,
    "FlowReading": 0
  },
  "MFC8": {
    "State": 0,
    "FlowReading": 0
  },
  "MFC9": {
    "State": 0,
    "FlowReading": 0
  },
  "MFC10": {
    "State": 0,
    "FlowReading": 0
  },
  "MFC11": {
    "State": 0,
    "FlowReading": 0
  },
  "MFC12": {
    "State": 0,
    "FlowReading": 0
  },
  "DownStreamValve7": {
    "CloseOpenCmd": false
  },
  "DownStreamValve8": {
    "OnOff": false,
  },
  "DownStreamValve9": {
    "OnOff": false
  },
  "DownStreamValve10": {
    "OnOff": false
  },
  "DownStreamValve11": {
    "OnOff": false
  },
  "DownStreamValve12": {
    "OnOff": false
  },
  "UpStreamValve7": {
    "OnOff": false
  },
  "UpStreamValve8": {
    "OnOff": false
  },
  "UpStreamValve9": {
    "OnOff": false
  },
  "UpStreamValve10": {
    "OnOff": false
  },
  "UpStreamValve11": {
    "OnOff": false
  },
  "UpStreamValve12": {
    "OnOff": false
  },
}

  
export default function DicingGasboxDTDL({gasBoxState}) {
    const gasBoxConfiguration = useRef();
    const subscriptionRef = useRef();
    const [DTDLState, setDTDLState] = useState(defaultGasBoxState);
  const throttleObjectId = "dtmi:com:via:Throttle:DicingChamberThrottleValve;1"
  const [throttleActionMsg, setThrottleActionMsg] = useState("")

    const [selectedValve, setselectedValve] = useState(null);
    const [Valvetitle, setValvetitle] = useState(null);
    const [selectedMfc, setselectedMfc] = useState(null);


    const [mfcvalue, setmfcvalue] = useState(null);
    function flattenDigitalTwinsByName(data) {
      const flattened = {};
      data.digitalTwins.forEach(twin => {
        console.log('twin',twin)
        flattened[twin?.Name] = twin;
      });
      return flattened;
    }
    const fetchObjectState = async () => {
      const response = await objectService.GetObjectGraphById(objectId)
  
      if (response) {
        gasBoxConfiguration.current = response;
        var flattenObject = flattenDigitalTwinsByName(response);
        setDTDLState(() => flattenObject);
        console.log(response,'flattenObject')
      }
    };


    const updateGasBoxState = (object) => {

      gasBoxConfiguration.current.digitalTwins = gasBoxConfiguration.current.digitalTwins.map(dt => {
        if (dt.$dtId === object.$dtId) {
          const updatedObject = { ...dt, ...object};
          return updatedObject;
        }
        return dt;
      });
      var flattenObject = flattenDigitalTwinsByName(gasBoxConfiguration.current);
      setDTDLState(() => flattenObject);
  
    }
    const subscribeToNotification = () => {

      const onNewMessage = (message) => {
        const object = JSON.parse(message);
        updateGasBoxState(object)
      };
      const objectSubscriptions = gasBoxConfiguration.current.digitalTwins.map(fdt => fdt.$dtId);
      const subscription = {
        channels: objectSubscriptions,
        onNewMessage: onNewMessage,
      };
      subscriptionRef.current = subscription;
      objectSubscriptionService.subscribeObjects(subscription);
    };

 const unSubscribe = () => {
    objectSubscriptionService.unSubscribeObjects(subscriptionRef.current);
  };
  useEffect(() => {
    const fetchState = async () => {
      await fetchObjectState();
      subscribeToNotification();
    };
    fetchState();
    return () => {
      unSubscribe();
    };
  }, []);

  // const executeCommand = async (objectId, commandName, params) => {
  //   const request = {
  //     instanceId: objectId,
  //     commandName: commandName,
  //     parameters: params
  //   }
  //   const response = await objectService.ExecuteCommand(request);

  //   console.log(response);
  // }
  const executeCommand = async (objectId, commandName, params) => {
    const request = {
      instanceId: objectId,
      commandName: commandName,
      parameters: params,
    };
    const response = await objectService.ExecuteCommand(request);
  
    console.log(response);
    if(objectId===throttleObjectId && !response.success){
      setThrottleActionMsg(()=>response.message.replace("Error invoking method:",""))
      setTimeout(()=>setThrottleActionMsg(""),5000)
    }
  };
  const handleValveClick = (value) => {
    var dtObject = DTDLState[selectedValve];
    executeCommand(dtObject.$dtId, "CloseOpenCmd", {
      OnOff: value
    });
    dtObject["OnOff"] = value;

    setselectedValve(null)
  };
  const valveseter = (selectedValve,value) => {
    var dtObject = DTDLState[selectedValve];
    executeCommand(dtObject.$dtId, "CloseOpenCmd", {
      OnOff: value
    });
    dtObject["OnOff"] = value;
  };

    const handleMfcvalue = () => {
      // debugger;
      var dtObject = DTDLState[selectedMfc];
      executeCommand(dtObject.$dtId, "SetFlowSP", {
        FlowSP: mfcvalue
      });
      if(selectedMfc=="MFC7") { 
        if(mfcvalue>0) { valveseter('DownStreamValve7',true);valveseter('UpStreamValve7',true);
        } else { valveseter('DownStreamValve7',false);valveseter('UpStreamValve7',false);  }
      } else
      if(selectedMfc=="MFC8") {
        if(mfcvalue>0) {valveseter('DownStreamValve8',true);valveseter('UpStreamValve8',true);        
        } else {valveseter('DownStreamValve8',false);valveseter('UpStreamValve8',false);  }
      } else
      if(selectedMfc=="MFC9") { 
        if(mfcvalue>0) { valveseter('DownStreamValve9',true);valveseter('UpStreamValve9',true);       
        } else {valveseter('DownStreamValve9',false);valveseter('UpStreamValve9',false); }
      } else
      if(selectedMfc=="MFC10") { 
        if(mfcvalue>0) { valveseter('DownStreamValve10',true);valveseter('UpStreamValve10',true);        
        } else { valveseter('DownStreamValve10',false);valveseter('UpStreamValve10',false);}
      } else
      if(selectedMfc=="MFC11") { 
        if(mfcvalue>0) { valveseter('DownStreamValve11',true);valveseter('UpStreamValve11',true);        
        } else { valveseter('DownStreamValve11',false);valveseter('UpStreamValve11',false);}
      } else
      if(selectedMfc=="MFC12") {
        if(mfcvalue>0) {  valveseter('DownStreamValve12',true);valveseter('UpStreamValve12',true);        
        } else { valveseter('DownStreamValve12',false);valveseter('UpStreamValve12',false); }
      } 
      // if (dtObject["State"] !== 8) {
      //   window.confirm(`${selectedMfc} is not operational, current state :${dtObject["State"]}`)
      //   return;
      // }
  
      
  
      setselectedMfc(null);
      setmfcvalue(null)
  
    }


  return (<>
    
      <div className="relative">
        <div className="relative rounded-t-xl border-b-0 border-2 border-gray-400 mt-1">
          <div className="-mt-3 ml-2 mb-3 bg-gray-100 w-40 rounded-md border-gray-400 border text-center font-bold text-sm text-gray-600 py-1">GAS Controller </div>
          <div className="flex flex-col gap-2 mt-2 -ml-0.5">
            <div className="flex">
                <div className="rounded-r-lg border-2 border-gray-400 w-16 text-center font-semibold bg-gray-100 h-7 mt-0.5 text-sm">He</div>
                <div className="shadow-inner h-2 w-3 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("DownStreamValve7"); setValvetitle('CF4 Down Valve 1') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${DTDLState["DownStreamValve7"].OnOff ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input type="" class="h-6 w-12 text-center py-4 px-1 pr-0 rounded dysing-input-yellow cursor-pointer" value={DTDLState["MFC7"].FlowReading}  onClick={() => { setselectedMfc("MFC7"); setmfcvalue(DTDLState["MFC7"].FlowReading) }}/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input class="h-6 w-12 text-center py-4 dysing-input   rounded" value={DTDLState["MFC7"].FlowReading} readonly="true"/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer"
                    onClick={() => { setselectedValve("UpStreamValve7"); setValvetitle('CF4 Up Valve 1') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${DTDLState["UpStreamValve7"].OnOff  ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
            </div>
            
            <div className="flex">
                <div className="rounded-r-lg border-2 border-gray-400 w-16 text-center font-semibold bg-gray-100 h-7 mt-0.5 text-sm">C4F8</div>
                <div className="shadow-inner h-2 w-3 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("DownStreamValve8"); setValvetitle('C4F8 Down Valve 2') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${DTDLState["DownStreamValve8"].OnOff?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input type="" class="h-6 w-12 text-center py-4 px-1 pr-0  dysing-input-yellow rounded cursor-pointer" value={DTDLState["MFC8"].FlowReading} onClick={() => { setselectedMfc("MFC8"); setmfcvalue(DTDLState["MFC8"].FlowReading) }}/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input class="h-6 w-12 text-center py-4 dysing-input rounded" value={DTDLState["MFC8"].FlowReading} readonly="true"/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("UpStreamValve8"); setValvetitle('C4F8 Up Valve 2') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${DTDLState["UpStreamValve8"].OnOff  ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
            </div>
            
            <div className="flex">
                <div className="rounded-r-lg border-2 border-gray-400 w-16 text-center font-semibold bg-gray-100 h-7 mt-0.5 text-sm">CH4</div>
                <div className="shadow-inner h-2 w-3 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("DownStreamValve9"); setValvetitle('CH4 Down Valve 3') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${DTDLState["DownStreamValve9"].OnOff ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input type="" class="h-6 w-12 text-center py-4 px-1 pr-0 rounded dysing-input-yellow cursor-pointer" value={DTDLState["MFC9"].FlowReading} onClick={() => { setselectedMfc("MFC9"); setmfcvalue(DTDLState["MFC9"].FlowReading) }}/>
                 <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                 <input class="h-6 w-12 text-center py-4  dysing-input rounded" value={DTDLState["MFC9"].FlowReading} readonly="true"/>
                 <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("UpStreamValve9"); setValvetitle('CH4 Up Valve 3') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${DTDLState["UpStreamValve9"].OnOff  ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
            </div>
            <div className="flex">
                <div className="rounded-r-lg border-2 border-gray-400 w-16 text-center font-semibold bg-gray-100 h-7 mt-0.5 text-sm">O2</div>
                <div className="shadow-inner h-2 w-3 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("DownStreamValve10"); setValvetitle('O2 Down Valve 4') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${DTDLState["DownStreamValve10"].OnOff ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input type="" class="h-6 w-12 text-center py-4 px-1 pr-0 rounded dysing-input-yellow cursor-pointer" value={DTDLState["MFC10"].FlowReading} onClick={() => { setselectedMfc("MFC10"); setmfcvalue(DTDLState["MFC10"].FlowReading) }}/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input class="h-6 w-12 text-center py-4  dysing-input rounded" value={DTDLState["MFC10"].FlowReading} readonly="true"/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("UpStreamValve10"); setValvetitle('O2 Up Valve 4') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${DTDLState["UpStreamValve10"].OnOff ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
            </div>
            <div className="flex">
                <div className="rounded-r-lg border-2 border-gray-400 w-16 text-center font-semibold bg-gray-100 h-7 mt-0.5 text-sm">Ar</div>
                <div className="shadow-inner h-2 w-3 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("DownStreamValve11"); setValvetitle('AR Down Valve 5') }} >
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${DTDLState["DownStreamValve11"].OnOff ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input type="" class="h-6 w-12 text-center py-4 px-1 pr-0 rounded dysing-input-yellow cursor-pointer" value={DTDLState["MFC11"].FlowReading} onClick={() => { setselectedMfc("MFC11"); setmfcvalue(DTDLState["MFC11"].FlowReading) }}/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input class="h-6 w-12 text-center py-4  dysing-input rounded" value={DTDLState["MFC11"].FlowReading} readonly="true"/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("UpStreamValve11"); setValvetitle('AR Up Valve 5') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${DTDLState["UpStreamValve11"].OnOff  ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
            </div>
            <div className="flex">
                <div className="rounded-r-lg border-2 border-gray-400 px-0.5 text-center font-semibold bg-gray-100 h-7 mt-0.5 text-xs pt-1">Backside He</div>
                <div className="shadow-inner h-2 w-1 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("DownStreamValve12"); setValvetitle('HE Down Valve 6') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${DTDLState["DownStreamValve12"].OnOff ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input type="" class="h-6 w-12 text-center py-4 px-1 pr-0  dysing-input-yellow rounded cursor-pointer" value={DTDLState["MFC12"].FlowReading} onClick={() => { setselectedMfc("MFC12"); setmfcvalue(DTDLState["MFC12"].FlowReading) }}/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input class="h-6 w-12 text-center py-4 dysing-input rounded" value={DTDLState["MFC12"].FlowReading} readonly="true"/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("UpStreamValve12"); setValvetitle('HE Up Valve 6') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${DTDLState["UpStreamValve12"].OnOff  ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
            </div>
          </div>
        </div>
        {selectedMfc != null &&
          <div class="absolute w-full h-full bg-black bg-opacity-25 top-0 left-0">
            <div className='mx-auto w-2/3 bg-white  mt-36 relative shadow-2xl rounded-xl'>
              <button className='bg-red-500 px-2 text-white absolute -right-3 -top-3 rounded-full' onClick={() => { setselectedMfc(null); setmfcvalue(null) }}>x</button>
               <div className="flex mt-2 rounded-xl overflow-hidden">
                <input type='number' value={mfcvalue ? mfcvalue : ''} onChange={(e) => setmfcvalue(e.target.value)} className='p-2 text-sm border bg-gray-100 w-full font-bold flex-1' placeholder='change value' />
                <button className='bg-gray-600 rounded hover:bg-gray-400 text-white px-3 py-1 text-xs font-bold' onClick={() => handleMfcvalue()}>Update</button>
              </div>
              
              
            </div>
          </div>
        }
            {selectedValve != null &&
          <div class="absolute w-full h-full bg-black bg-opacity-25 top-0 left-0">
            <div className='mx-5 bg-white p-4 mt-16 relative shadow-2xl rounded-lg'>
              <div className='text-base font-medium'>{DTDLState[selectedValve].OnOff === true ? 'Do you want to close ' : 'Do you want to open '} <span className="font-bold">{Valvetitle}</span> ?</div>
              <div className='flex justify-between gap-3'>
              {DTDLState[selectedValve].OnOff=== true ?
                  <button className='bg-white-500 shadow-md hover:bg-gray-300 border border-b text-black px-3 py-1 mt-3 rounded font-bold flex-1' onClick={() => { handleValveClick(false); }}>Yes</button>
                  :
                  <button className='bg-white-500 shadow-md hover:bg-gray-300 border border-b text-black px-3 py-1 mt-3 rounded  font-bold flex-1' onClick={() => handleValveClick(true)}>Yes</button>
                }
                <button className='bg-gray-600 text-white px-3 py-2 mt-3 rounded flex-1' onClick={() => setselectedValve(null)}>No</button>
              </div>

             </div>
          </div>
        }
    </div>


  </>);
}