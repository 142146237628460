import React, { useEffect, useState, useRef } from "react";

// const defaultMotorConfiguration = [
//   {
//     object: "Chuck_Motor",
//     name: "Chuck_Motor",
//     channelName: "MAIN.Chuck_Motor.In.Motor_OPState",
//     readChannelName: "MAIN.Chuck_Motor.In.Motor_OPState",
//     currentValue: '',
//   },
//   {
//     object: "Chuck_Motor",
//     name: "Chuck_Motor",
//     channelName: "MAIN.Chuck_Motor.Out.Control_word",
//     writeChannelName: "MAIN.Chuck_Motor.Out.Control_word",
//     currentValue: '',
//   },
// ]

function MotorControls(props) {
    const {
      allChannels,
      channelService,
      channelSubscriptionService,
      subscriptionForMotor
    } = props

//   const {
//     // checkedVelocity,
//     handleCheckedVelocity,
//     addEditVelocityVal,
//     increaseVelocity,
//     velocity,
//     decreaseVelocity,
//   } = props;

  const [checkedVelocity, setCheckedVelocity] = useState(false)
  const [velocity, setVelocity] = useState(0);
  const [selectedVelocityVal, setSelectedVelocityVal] = useState();
  const [updatedVelocityVal, setUpdatedVelocityVal]  = useState();
  const [velocityModal, setVelocityModal]  = useState(false);

  const [defaultMotorConfiguration, setDefaultMotorConfiguration] = useState([
    {
      object: "Chuck_Motor",
      name: "Chuck_Motor",
      channelName: "MAIN.Chuck_Motor.In.Motor_OPState",
      readChannelName: "MAIN.Chuck_Motor.In.Motor_OPState",
      value: 0,
    },
    {
      object: "Chuck_Motor",
      name: "Chuck_Motor",
      channelName: "MAIN.Chuck_Motor.Out.Control_word",
      writeChannelName: "MAIN.Chuck_Motor.Out.Control_word",
      value: '',
    },
  ])
  const motorConfiguration = useRef(defaultMotorConfiguration);
  const motorChannels = useRef();
  const [motoOPState, setMotorOPState] = useState(0)

  const subscriptionRef = useRef();

  const handleVelocityCheck = () => {
    // handleCheckedVelocity(!checkedVelocity)
    setCheckedVelocity(!checkedVelocity)
  }

  const addEditVelocityVal = (e) => {
    const val = e.target.value;
    setSelectedVelocityVal(val);
    setVelocityModal(true);
  }

  const increaseVelocity = () => {
    const val = Number(velocity) + 1;
    setVelocity(val);
  };

  const decreaseVelocity = () => {
    let val;
    if (velocity > 0) {
      val = Number(velocity) - 1;
      setVelocity(val);
    }
  };

  /* MOTOR */

  const updateMotorState = (updatedChannels) => {
    const channels = defaultMotorConfiguration;
    updatedChannels.forEach(comingItem => {
      channels.forEach((localItem, index) => {
        if(localItem.channelName === comingItem.channelName) {
          channels[index].value = comingItem.value
        }
      })
    })

    setDefaultMotorConfiguration(channels)
  }

  const IOWrite = (channelName, value) => {
    if (channelName) {
      console.log("channel Name ====================>", channelName)
      console.log("allChannels====================>", allChannels)
      const channel = allChannels.find(obj => {
        return obj.channelName === channelName
      })

      console.log("channel====================>", channel)
      
      if (channel) {
        channel.data.value = value;
        channelService.Write(channel).then(() => {
        });
      }
      
    }
  }


  const hanldeMotorResetBtnClick = (e) => {
    const target = e.target;
    const channelName = target.getAttribute("data-channel");
    const value = target.getAttribute("data-value")
    IOWrite(channelName, value)
  }

  const hanldeMotorSwitchOnBtnClick = () => {
    alert("Switch On")
  }


  useEffect(() => {
    console.log("subscription for MOTOR from ISO ====>", subscriptionForMotor)
    if(subscriptionForMotor) {
      updateMotorState([subscriptionForMotor])
    }
    
  }, [subscriptionForMotor])

  useEffect(() => {
    if(allChannels && allChannels.length > 0) {
      updateMotorState(allChannels)
    }
    
  }, [allChannels])

  useEffect(() => {
    if(defaultMotorConfiguration) {
      defaultMotorConfiguration.forEach((item, index) => {
        if(item.channelName === 'MAIN.Chuck_Motor.In.Motor_OPState') {
          setMotorOPState(item.value)
        }
      })
    }
  }, defaultMotorConfiguration)

  /* MOTOR END*/

  return (
    <>
      <div
        id="recipe_select_list_holder"
        className="border border-black p-3 rounded-lg mb-5 w-full"
      >
        <span className="-mt-6 block bg-white px-2 border border-black ml-0 rounded-xl mb-2 w-36 text-center">
          Commands
        </span>
        <div className="grid grid-cols-1 gap-4">
          <button 
            className="bg-btnSecondary cursor-pointer shadow-shadow100 py-1 rounded-lg hover:bg-white  font-bold transition-all disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-default" 
            // disabled={motoOPState != 8}
            data-channel="Chuck_Motor.Out.Control_word"
            data-value="128"
            onClick={hanldeMotorResetBtnClick}
          >
            Reset
          </button>
          <button 
            className="bg-btnSecondary cursor-pointer shadow-shadow100 py-1 rounded-lg hover:bg-white  font-bold transition-all disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-default"
            onClick={hanldeMotorSwitchOnBtnClick}
            // disabled={motoOPState != 8}
          >
            Switch On
          </button>
          <button 
            className="bg-btnSecondary cursor-pointer shadow-shadow100 py-1 rounded-lg hover:bg-white  font-bold transition-all disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-default"
            disabled={motoOPState != 8}
          >
            Home
          </button>
          <button 
            className="bg-btnSecondary cursor-pointer shadow-shadow100 py-1 rounded-lg hover:bg-white  font-bold transition-all disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-default"
            disabled={motoOPState != 8}
          >
            Stop
          </button>
          <button 
            className="bg-btnSecondary cursor-pointer shadow-shadow100 py-1 rounded-lg hover:bg-white  font-bold transition-all disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-default"
            disabled={motoOPState != 8}
          >
            Process Position
          </button>
          <button 
            className="bg-btnSecondary cursor-pointer shadow-shadow100 py-1 rounded-lg hover:bg-white  font-bold transition-all disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-default"
            disabled={motoOPState != 8}
          >
            Transfer Position
          </button>
        </div>
      </div>
      <div className="w-full">
        <div
          id="recipe_select_list_holder"
          className="border border-black p-3 rounded-lg mb-5"
        >
          <div className="flex items-center mb-3">
            <button
              onClick={handleVelocityCheck}
              className={`${
                checkedVelocity ? "text-black" : "text-white"
              } flex justify-center items-center text-sm font-bold rounded-md hover:bg-gray-400 border-2 border-black h-5 w-5`}
            >
              ✓
            </button>
            <div className="text-sm font-bold inline-block pl-1">
              Velocity Mode
            </div>
          </div>


          <button
            disabled={!checkedVelocity}
            onClick={() => increaseVelocity()}
            className={`${
              !checkedVelocity
                ? "bg-inactive text-slate-500"
                : "bg-btnSecondary shadow-shadow100 hover:bg-white"
            } w-24 block mx-auto recipe_select_list_holder mb-2 font-bold text-xl px-2 py-1 rounded-lg transition-all`}
          >
            ▲
          </button>
          <input
            disabled={!checkedVelocity}
            onFocus={addEditVelocityVal}
            type="text"
            value={velocity}
            className={`${
              !checkedVelocity
                ? "bg-inactive border-transparent text-slate-500"
                : "shadow-shadow100 hover:bg-white border-black"
            } mx-auto mb-2 text-center border-2 font-bold text-xl px-2 py-1 rounded-lg w-24 block`}
          />
          <button
            disabled={!checkedVelocity}
            onClick={decreaseVelocity}
            className={`${
              !checkedVelocity
                ? "bg-inactive text-slate-500"
                : "bg-btnSecondary shadow-shadow100 hover:bg-white"
            } w-24 block mx-auto recipe_select_list_holder mb-2 font-bold text-xl px-2 py-1 rounded-lg transition-all`}
          >
            ▼
          </button>
        </div>
      </div>
    </>
  );
}

export default MotorControls;
