import "./Alarm.css";

export default function Alarm({ isOn, channels }) {
  const alarmColor = isOn ? "#FB5046" : "#6B8DA1";
  let alarmChannels = [];
  if (channels) {
    channels.forEach((channelName) => {
      alarmChannels.push(<div>{channelName}</div>);
    });
  }
  const handleAlarm = async (e) => {
    var formdata = {
      token: "",
      ruleType: "Sequence",
      ruleGroups: [],
      ruleSubGroups: [],
      rule: "",
      sequence: "ResetAlarms",
      inputData: {},
    };
    // Convert data to JSON string
    try {
      const response = await fetch(
        process.env.REACT_APP_VIA_RULE_ORCHES_API + "/api/Rules/ExecuteRule",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add any other headers as needed
          },
          body: JSON.stringify(formdata),
        }
      );

      if (response.ok) {
        // Handle success
        console.log("Alarm stopped");
      } else {
        // Handle error
        console.error("Failed to post data.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <div
      className={`absolute z-50 flex right-24 bg-gray-700  rounded-3xl top-4  ${
        isOn ? "flashcolor " : "opacity-10 h-12"
      } `}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1"
        stroke="#ffffff"
        class={`w-16 h-16  absolute -left-8 ${isOn ? " " : ""}`}
      >
        <path
          stroke-linecap="round"
          fill={alarmColor}
          stroke-linejoin="round"
          d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
        />
      </svg>
      <div className=" text-lg font-semibold text-white ">
        <div className="px-3 py-3 pl-8 pr-6">Alarm</div>
        {isOn && (
          <div className="Alarmbox text-lg font-thin p-4 bg-black rounded-b-3xl ">
            {alarmChannels}
          </div>
        )}
      </div>
      {isOn && (
        <button
          onClick={() => handleAlarm()}
          className="absolute right-0 -bottom-5 bg-green-900 hover:bg-green-700 text-white border border-gray-400 px-2 py-0.5 rounded-full shadow-xl text-base"
        >
          Clear
        </button>
      )}
    </div>
  );
}
